import React, { Component } from "react";
import { connect } from "react-redux";

import { dispatchToProps as maDP } from "../store/manage-actions";
import { dispatchToProps as paDP } from "../store/perspectives-actions";

import Resources from "../lib/resources";
import { htmlToText } from "../lib/utils";

import MainContentHeader from "./main_content_header/mainContentHeader";
import PerspectiveTabs from "./perspectiveTabs";
import ModalDialog from "./modalDialog";
import ReplyTemplateForm from "./replyTemplateForm";
import CRUDList from "./library/crudList";
import Card from "./library/card";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { generatePath, Redirect } from "react-router-dom";

const dispatchToProps = dispatch => {
  return {
    ...maDP(dispatch),
    ...paDP(dispatch)
  };
};

class ManageTemplates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showReplyTemplateForm: false,
      selectedTemplate: null,
      perspective: null,
      deleteTemplate: null
    };

    this.editTemplate = this.editTemplate.bind(this);
    this.deselectTemplate = this.deselectTemplate.bind(this);
  }

  componentDidMount() {
    this.props.getCommunicationTemplateSnippets();

    this.tryUpdate();
  }
  componentDidUpdate(prevProps) {
    this.tryUpdate(prevProps);
  }

  tryUpdate(prevProps) {
    if (this.state.perspective === null) {
      const defaultPerspective = this.props.getCompanyPerspectiveById(
        this.props.userStore.userConfig.defaultPerspective,
        this.props.match.params.companyId
      );
      if (defaultPerspective !== null) {
        this.setState({ perspective: defaultPerspective });
      }
    }

    if (this.props.match.params.companyId !== (((prevProps || {}).match || {}).params || {}).companyId) {
      this.props.clearAllReplyTemplates();
    }
  }

  componentWillUnmount() {
    this.props.clearAllReplyTemplates();
  }

  deselectTemplate() {
    this.setState({ showReplyTemplateForm: false, selectedTemplate: null });
  }

  editTemplate(template = null) {
    this.setState({ showReplyTemplateForm: true, selectedTemplate: template });
  }

  deleteTemplate = template => {
    const companyId = this.props.match.params.companyId;
    this.props.deleteCompanyReplyTemplate(
      companyId,
      this.state.perspective.perspectiveId,
      template.perspectiveEmailTemplateId
    );
    this.setState({ deleteTemplate: null });
  };

  render() {
    const { perspective, selectedTemplate, deleteTemplate } = this.state;
    const {
      match: {
        params: { companyId }
      },
      flags
    } = this.props;

    if (!flags.teamInboxTemplates) {
      return <Redirect to={generatePath("/company/:companyId/manage", { companyId })} />;
    }

    const columns = [
      {
        header: Resources.Name.toLocaleUpperCase(),
        content: row => row.emailTemplateID,
        width: "20%"
      },
      {
        header: Resources.Subject.toLocaleUpperCase(),
        content: row => (
          <div>
            <div className="overflow-ellipsis">{row.subject}</div>
            <div className="overflow-ellipsis">{htmlToText(row.emailBody)}</div>
          </div>
        ),
        width: "54%"
      },
      {
        header: Resources.Actions.toLocaleUpperCase(),
        type: "actions",
        actions: [
          { type: "update", onClick: row => this.editTemplate(row) }
          // { type: "delete", onClick: row => this.setState({ deleteTemplate: row }) }
        ]
      }
    ];

    const templates = this.props.getAllCompanyReplyTemplates(companyId, (perspective || {}).perspectiveId) || [];

    return (
      <React.Fragment>
        <MainContentHeader
          title={Resources.Templates}
          customContent={
            <PerspectiveTabs
              handleSelect={perspective => this.setState({ perspective }, this.deselectTemplate())}
              selectedPerspective={perspective}
            />
          }
          hideSearch={true}
        />
        <div className="manage-content">
          {deleteTemplate && (
            <ModalDialog
              isOpen={true}
              toggle={() => this.setState({ deleteTemplate: null })}
              title={Resources.Confirm}
              content={
                <div
                  dangerouslySetInnerHTML={{
                    __html: Resources.ConfirmDeleteTemplate(deleteTemplate.perspectiveEmailTemplateName)
                  }}
                />
              }
              footer={
                <div className="float-right">
                  <button
                    className="cancel-button"
                    onClick={() => {
                      this.setState({ deleteTemplate: null });
                    }}
                  >
                    {Resources.Cancel}
                  </button>
                  <button
                    className="delete-button"
                    onClick={() => {
                      this.deleteTemplate(deleteTemplate);
                    }}
                  >
                    {Resources.Remove}
                  </button>
                </div>
              }
            />
          )}
          {this.state.showReplyTemplateForm ? (
            <ReplyTemplateForm
              templateData={selectedTemplate}
              perspectiveId={perspective.perspectiveId}
              companyId={companyId}
              closeForm={this.deselectTemplate}
            />
          ) : (
            <Card maxWidth={"80em"} className="card-crud-list">
              <CRUDList
                columns={columns}
                data={templates}
                onUpdate={row => this.editTemplate(row)}
                onDelete={row => this.setState({ deleteTemplate: row })}
                title={Resources.TemplateList}
                description={Resources.TemplateListDescription}
                onCreate={() => this.editTemplate()}
                createText={Resources.CreateNewTemplate}
                loading={this.props.manageStore.isGettingAllEmailReplyTemplates}
              />
            </Card>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    manageStore: store.manage,
    userStore: store.user
  };
};

export default withLDConsumer()(connect(storeToProps, dispatchToProps)(ManageTemplates));
