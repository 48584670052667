import React, { Component } from "react";

import Resources from "../../../lib/resources";
import { isEmpty } from "../../../lib/utils";
import { generatePath, withRouter } from "react-router-dom";
import Attachment from "../../attachment";
import Avatar from "../../library/avatar";
import Dropdown from "../../library/dropdown";

class EmailThreadItem extends Component {
  render() {
    const {
      emailBody,
      attachments,
      // isFirstEntry,
      // assignedTo,
      // statusName,
      dateText,
      // to,
      //from,
      // cc,
      // bcc,
      // viewOriginal,
      postingUser = {},
      conversationId = {},
      loggedInUser,
      itemType = "email",
      item = {}
    } = this.props;

    const isExpanded = true;
    const posterName =
      postingUser.fullName === loggedInUser.contactName
        ? Resources.You
        : postingUser.fullName || (((item.data || {}).from || [])[0] || {}).name || Resources.Unknown;
    let forwardedTask = (item.data || {}).forwardedTask;

    let titleNote = Resources.ConvoStartedByName(posterName === "You" ? posterName.toLocaleLowerCase() : posterName);

    return (
      <div className="conversation-thread-item">
        <Avatar height={34} imgURL={postingUser.profilePictureURL} />
        <div className="conversation-thread-item__body">
          <div>
            <div className="conversation-thread-item__body__header">
              <div className="flex-align-center">
                <Dropdown
                  buttonContent={posterName}
                  buttonClassName="conversation-thread-item__body__poster"
                  toggleOnHover
                >
                  <div className="conversation-thread-item__body__poster__menu">
                    <div className="conversation-thread-item__body__poster__menu__name">{posterName}</div>
                    <div className="conversation-thread-item__body__poster__menu__email">
                      {postingUser.emailAddress || (((item.data || {}).from || [])[0] || {}).address}
                    </div>
                  </div>
                </Dropdown>
                {itemType === "note" && (
                  <span className="badge badge-note" style={{ marginLeft: "0.66rem" }}>
                    {Resources.Note.toLocaleUpperCase()}
                  </span>
                )}
              </div>

              <span className="float-right" style={{ textAlign: "right" }}>
                <div className="thread-item-age">{dateText}</div>
                {/* {isFirstEntry && <div>{`${assignedTo}, ${statusName}`}</div>} */}
              </span>
            </div>
            {isExpanded && itemType !== "note" && (
              <div className="conversation-thread-item__body__header__to">
                {Resources.To.toLocaleLowerCase()}{" "}
                {((item.data || {}).to || []).map(recipient => (recipient || {}).address).join(", ")}
              </div>
            )}

            {/* <div>{from}</div> */}
            {/* <div>{to}</div> */}
            {/* <div>{cc}</div> */}
            {/* <div>{bcc}</div> */}
            <div className="conversation-thread-item__body__content">
              <div dangerouslySetInnerHTML={{ __html: emailBody }} />
            </div>
          </div>
          {isEmpty(attachments) === false && (
            <div>
              <br />
              {attachments.map(att => {
                return (
                  <Attachment
                    attachment={att}
                    key={att.attachmentId}
                    showIcon={true}
                    name={att.fileName}
                    size={att.sizeBytes}
                  />
                );
              })}
            </div>
          )}
          {forwardedTask !== conversationId && forwardedTask !== null && itemType !== "note" ? (
            <div className="conversation-thread-forward-badge-container">
              <span className="conversation-thread-item__body__badge conversation-thread-item__body__badge--forward">
                {titleNote}
              </span>
              <span
                className="view-link"
                onClick={() => {
                  this.props.history.push(
                    generatePath("/v2/:companyId/:perspectiveId/activities/assigned/:conversationId", {
                      companyId: this.props.match.params.companyId,
                      perspectiveId: this.props.match.params.perspectiveId,
                      selectedContextGroupId: this.props.match.params.selectedContextGroupId,
                      conversationId: forwardedTask
                    })
                  );
                }}
              >
                {Resources.ShowOriginal}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(EmailThreadItem);
