import React from "react";

import Resources from "../../lib/resources";
import { formatDate, includes, find } from "../../lib/utils";

import TableData from "../library/tableData";
import Avatar from "../library/avatar";
import { generatePath, withRouter } from "react-router-dom";
import IconTrash from "../library/icons/iconTrash";
import IconCheckCircle from "../library/icons/iconCheckCircle";
import ToggleSwitch from "../library/toggleSwitch";
import IconAlertCircle from "../library/icons/iconAlertCircle";

import { dispatchToProps as netDP } from "../../store/network-actions";
import { dispatchToProps as modDP } from "../../store/modal-actions";
import { dispatchToProps as persDP } from "../../store/perspectives-actions";

import { connect } from "react-redux";
import IconAward from "../library/icons/iconAward";
import ContactsHoverButtons from "./contactsHoverButtons";

const dispatchToProps = dispatch => ({
  ...netDP(dispatch),
  ...modDP(dispatch),
  ...persDP(dispatch)
});

function ContactsTable(props) {
  const {
    data,
    contactsCompanyName,
    loading,
    maxRows,
    onLoadMore,
    match,
    onRowSelectToggle,
    rowKey,
    selectedKeys,
    noCompany,
    editMode,
    deleteContact,
    deletedContactKeys,
    loadedPage,
    setPrimary,
    noClick,
    primaryArchive,
    currentPrimary,
    signupView,
    markForInvite,
    name,
    markedContacts
  } = props;

  let contactsColumns = [
    {
      type: "rowSelect",
      name: "rowSelect",
      justify: "center",
      header: "",
      content: row => (
        <Avatar height={"3rem"} imgURL={row.profilePictureURL} type="user" objectName={row.contactName}></Avatar>
      ),
      width: "4.22rem"
    },
    {
      header: Resources.Name.toLocaleUpperCase(),
      name: "name",
      content: row => {
        return (
          <React.Fragment>
            <div className="lockstep-verified-check-container">
              <span>{row.contactName}</span>
              {row.isPrivate ? (
                <div className="badge-private">
                  <span>{Resources.Private.toLocaleUpperCase()}</span>
                </div>
              ) : null}
              {row.isLockstepVerified && !row.isPrivate && !row.isArchived ? (
                <IconCheckCircle height={14} className="lockstep-verified-checkmark-icon" />
              ) : null}
            </div>
            {row.isPrimaryContact ? <span className="contacts-table-subtext">{Resources.Primary}</span> : null}
            {row.isSecondaryContact ? <span className="contacts-table-subtext">{Resources.Secondary}</span> : null}
          </React.Fragment>
        );
      },
      width: "27%"
    },
    {
      header: Resources.EmailAddress.toLocaleUpperCase(),
      name: "email",
      content: row =>
        row.emailAddr === "" || row.emailAddr === null ? (
          <div className="missing-email-address-container">
            <IconAlertCircle height={20} />
            <span>{Resources.MissingEmailAddress}</span>
          </div>
        ) : (
          row.emailAddr
        ),
      width: "25%"
    },
    {
      header: Resources.Company.toLocaleUpperCase(),
      name: "company",
      content: row => row.vendorName || contactsCompanyName || row.custName,
      width: "25%"
    },
    {
      header: Resources.Role.toLocaleUpperCase(),
      name: "role",
      content: row => row.contactRole || (roles[row.contactRoleId - 1] || {}).role,
      width: "25%"
    },
    {
      header: Resources.LastUpdated.toLocaleUpperCase(),
      name: "last-updated",
      content: row => formatDate(row.contactUpdateDate || row.contactInsertDate),
      width: "15%"
    }
  ];

  let contactsHoverColumns = props.noCompany
    ? [
        {
          type: "rowSelect",
          name: "rowSelect",
          justify: "center",
          header: "",
          content: row => (
            <Avatar height={"3rem"} imgURL={row.profilePictureURL} type="user" objectName={row.contactName}></Avatar>
          ),
          width: "5.2rem"
        },
        {
          header: Resources.Name.toLocaleUpperCase(),
          name: "name",
          content: row => {
            return (
              <React.Fragment>
                <div className="lockstep-verified-check-container">
                  <span>{row.contactName}</span>
                  {row.isArchived ? (
                    <div className="badge-archived">
                      <span>{Resources.Archived.toLocaleUpperCase()}</span>
                    </div>
                  ) : null}
                  {row.isPrivate ? (
                    <div className="badge-private">
                      <span>{Resources.Private.toLocaleUpperCase()}</span>
                    </div>
                  ) : null}
                  {row.isLockstepVerified && !row.isPrivate && !row.isArchived ? (
                    <IconCheckCircle height={14} className="lockstep-verified-checkmark-icon" />
                  ) : null}
                </div>
                {row.isPrimaryContact ? <span className="contacts-table-subtext">{Resources.Primary}</span> : null}
                {row.isSecondaryContact ? <span className="contacts-table-subtext">{Resources.Secondary}</span> : null}
              </React.Fragment>
            );
          },
          width: "27%"
        },
        {
          header: Resources.Role.toLocaleUpperCase(),
          name: "role",
          content: row => (roles[row.contactRoleId - 1] || {}).role,
          width: "25%"
        },
        {
          header: Resources.EmailAddress.toLocaleUpperCase(),
          name: "email",
          content: row =>
            row.emailAddr === "" || row.emailAddr === null ? (
              <div className="missing-email-address-container">
                <IconAlertCircle height={20} />
                <span>{Resources.MissingEmailAddress}</span>
              </div>
            ) : (
              row.emailAddr
            ),
          width: "25%"
        },
        {
          header: Resources.Phone.toLocaleUpperCase(),
          name: "phone",
          content: row => row.phone || row.Phone,
          width: "25%"
        },
        {
          header: "",
          name: "hover-buttons",
          content: row => {
            return <ContactsHoverButtons row={row} />;
          },
          width: "40%"
        }
      ]
    : [
        {
          type: "rowSelect",
          name: "rowSelect",
          justify: "center",
          header: "",
          content: row => (
            <Avatar height={"3rem"} imgURL={row.profilePictureURL} type="user" objectName={row.contactName}></Avatar>
          ),
          width: "5.2rem"
        },
        {
          header: Resources.Name.toLocaleUpperCase(),
          name: "name",
          content: row => {
            return (
              <React.Fragment>
                <div className="lockstep-verified-check-container">
                  <span>{row.contactName}</span>
                  {row.isPrivate ? (
                    <div className="badge-private">
                      <span>{Resources.Private.toLocaleUpperCase()}</span>
                    </div>
                  ) : null}
                  {row.isLockstepVerified && !row.isPrivate && !row.isArchived ? (
                    <IconCheckCircle height={14} className="lockstep-verified-checkmark-icon" />
                  ) : null}
                </div>
                {row.isPrimaryContact ? <span className="contacts-table-subtext">{Resources.Primary}</span> : null}
                {row.isSecondaryContact ? <span className="contacts-table-subtext">{Resources.Secondary}</span> : null}
              </React.Fragment>
            );
          },
          width: "27%"
        },
        {
          header: Resources.EmailAddress.toLocaleUpperCase(),
          name: "email",
          content: row =>
            row.emailAddr === "" || row.emailAddr === null ? (
              <div className="missing-email-address-container">
                <IconAlertCircle height={20} />
                <span>{Resources.MissingEmailAddress}</span>
              </div>
            ) : (
              row.emailAddr
            ),
          width: "25%"
        },
        {
          header: Resources.Company.toLocaleUpperCase(),
          name: "company",
          content: row => row.displayName || row.vendorName || contactsCompanyName || row.custName,
          width: "25%"
        },
        {
          header: Resources.Role.toLocaleUpperCase(),
          name: "role",
          content: row => row.contactRole || (roles[row.contactRoleId - 1] || {}).role,
          width: "25%"
        },
        {
          header: "",
          name: "hover-buttons",
          content: row => {
            return <ContactsHoverButtons row={row} />;
          },
          width: "40%"
        }
      ];

  let roles = [
    { value: 1, role: "CFO" },
    { value: 2, role: "Manager/Controller" },
    { value: 3, role: "Disputes" },
    { value: 4, role: "Payments" },
    { value: 5, role: "Remittance" },
    { value: 6, role: "Primary" },
    { value: 7, role: "Secondary" }
  ];

  let contactsColumnsHideCompany = [
    {
      type: "rowSelect",
      name: "rowSelect",
      justify: "center",
      header: "",
      content: row => (
        <Avatar height={"3rem"} imgURL={row.profilePictureURL} type="user" objectName={row.contactName}></Avatar>
      ),
      width: "5.2rem"
    },
    {
      header: Resources.Name.toLocaleUpperCase(),
      name: "name",
      content: row => {
        if (includes(deletedContactKeys, row.entityGlobalKey)) {
          return <span className="contacts-table-disabled">{row.contactName}</span>;
        } else {
          return (
            <React.Fragment>
              <div className="lockstep-verified-check-container">
                <span>{row.contactName}</span>
                {row.isArchived ? (
                  <div className="badge-archived">
                    <span>{Resources.Archived.toLocaleUpperCase()}</span>
                  </div>
                ) : null}
                {row.isPrivate ? (
                  <div className="badge-private">
                    <span>{Resources.Private.toLocaleUpperCase()}</span>
                  </div>
                ) : null}
                {row.isLockstepVerified && !row.isPrivate && !row.isArchived ? (
                  <IconCheckCircle height={14} className="lockstep-verified-checkmark-icon" />
                ) : null}
              </div>
              {row.isPrimaryContact ? <span className="contacts-table-subtext">{Resources.Primary}</span> : null}
              {row.isSecondaryContact ? <span className="contacts-table-subtext">{Resources.Secondary}</span> : null}
            </React.Fragment>
          );
        }
      },
      width: "25%"
    },
    {
      header: Resources.Role.toLocaleUpperCase(),
      name: "role",
      content: row => {
        if (includes(deletedContactKeys, row.entityGlobalKey)) {
          return <span className="contacts-table-disabled">{(roles[row.contactRoleId - 1] || {}).role}</span>;
        } else {
          return (roles[row.contactRoleId - 1] || {}).role;
        }
      },
      width: "20%"
    },
    {
      header: Resources.EmailAddress.toLocaleUpperCase(),
      name: "email",
      content: row => {
        if (includes(deletedContactKeys, row.entityGlobalKey)) {
          return <span className="contacts-table-disabled">{row.emailAddr || row.Email}</span>;
        } else {
          return row.emailAddr === "" || row.emailAddr === null ? (
            <div className="missing-email-address-container">
              <IconAlertCircle height={20} />
              <span>{Resources.MissingEmailAddress}</span>
            </div>
          ) : (
            row.emailAddr
          );
        }
      },
      width: "30%"
    },
    {
      header: Resources.Phone.toLocaleUpperCase(),
      name: "phone",
      content: row => {
        if (includes(deletedContactKeys, row.entityGlobalKey)) {
          return <span className="contacts-table-disabled">{row.phone}</span>;
        } else {
          return row.phone;
        }
      },
      width: "25%"
    },
    {
      header: editMode || signupView ? null : Resources.LastUpdated.toLocaleUpperCase(),
      name: "last-updated",
      content: row => {
        if (editMode) {
          return (
            <button
              onClick={() => deleteContact(row.entityGlobalKey, row)}
              className={`button-primary ${includes(deletedContactKeys, row.entityGlobalKey) ? "inverse" : ""}`}
            >
              <IconTrash className="button-primary-icon" />
              {includes(deletedContactKeys, row.entityGlobalKey) ? "Deleted" : "Delete"}
            </button>
          );
        } else if (primaryArchive) {
          return (
            <button
              onClick={() => setPrimary(row.entityGlobalKey)}
              className={`button-primary ${currentPrimary === row.entityGlobalKey ? "inverse" : ""}`}
            >
              <IconAward className="button-primary-icon" />
              {Resources.MarkAsPrimary}
            </button>
          );
        } else if (signupView) {
          return (
            <ToggleSwitch
              text={find(markedContacts, i => i === row.entityGlobalKey) === undefined ? Resources.No : Resources.Yes}
              checked={find(markedContacts, i => i === row.entityGlobalKey)}
              onChange={() => {
                markForInvite(row.entityGlobalKey, row);
              }}
            />
          );
        } else {
          return formatDate(row.contactUpdateDate || row.profileUpdateDate);
        }
      },
      width: primaryArchive ? "27%" : "15%"
    }
  ];

  if (props.noHover) {
    contactsHoverColumns = contactsColumnsHideCompany;
  }

  return (
    <TableData
      name={name}
      hoverColumns={contactsHoverColumns}
      data={data || []}
      loading={loading}
      maxRows={maxRows}
      columns={noCompany ? contactsColumnsHideCompany : contactsColumns}
      style={{ maxHeight: "40vh" }}
      onLoadMore={onLoadMore}
      loadedPage={loadedPage}
      onRowClick={
        noClick
          ? null
          : row => {
              if (!match.params || !match.params.companyId || !match.params.perspectiveId) {
                return null;
              }
              props.history.push(
                generatePath(`/v2/:companyId/:perspectiveId/network/contacts/${row.entityGlobalKey}`, match.params)
              );
            }
      }
      rowKey={rowKey}
      onRowSelectToggle={onRowSelectToggle}
      selectedKeys={selectedKeys}
      pagination
      rowHeight="5rem"
    />
  );
}

const storeToProps = store => {
  return {
    networkStore: store.network,
    modalStore: store.modal,
    perspectiveStore: store.perspectives
  };
};

export default withRouter(
  connect(
    storeToProps,
    dispatchToProps
  )(function(props) {
    return <ContactsTable {...props} {...this} />;
  })
);
