import React, { Component } from "react";

import Card from "./card";
import { Link } from "react-router-dom";

class SummaryCard extends Component {
  renderValue(item) {
    return item.value || (item.value === 0 ? 0 : "-");
  }

  render() {
    const { summaryItems, title, renderColumns } = this.props;

    const renderSummaryRows = Array.isArray(summaryItems[0]) ? summaryItems : [summaryItems];

    return (
      <Card
        className={`statements-summary ${renderColumns ? "statements-summary--columns" : ""}`}
        headAboveCard
        title={title}
      >
        {renderSummaryRows.map(row => (
          <div className={`statements-summary-${renderColumns ? "column" : "row"}`}>
            {row.map(item => (
              <div className="statements-summary-item" key={item.description}>
                <div className="statements-summary-item-header">{item.description}</div>
                <div className="statements-summary-item-data">
                  {!!item.linkTo ? <Link to={item.linkTo}>{this.renderValue(item)}</Link> : this.renderValue(item)}
                </div>
              </div>
            ))}
          </div>
        ))}
      </Card>
    );
  }
}

export default SummaryCard;
