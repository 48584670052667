import React, { Component } from "react";
import Icons from "../../lib/icons";
import { createKey, isEmpty } from "../../lib/utils";
import Dropdown from "./dropdown";

export default class SelectInput extends Component {
  render() {
    const {
      value,
      options,
      label,
      labelClassName,
      optionValueKey,
      optionDisplayKey,
      displayValue,
      width,
      labelInline,
      disabled,
      style = {}
    } = this.props;

    let dropdownItems = options.map(item => {
      let itemValue = optionValueKey ? item[optionValueKey] : item.value;
      let itemDisplayName = optionDisplayKey ? item[optionDisplayKey] : item.displayName;

      return (
        <button
          key={createKey(label, itemValue || itemDisplayName)}
          className={`dropdown-item ${value && value === itemValue && "active"}`}
          onClick={() => this.props.onSelectOption(item)}
        >
          {itemDisplayName || itemValue}
        </button>
      );
    });

    return (
      <div
        className={`select-input ${this.props.className ? this.props.className : ""} ${
          labelInline ? "flex-align-center" : ""
        }`}
        style={style}
      >
        {label ? (
          <div className={labelClassName || "input-label"} id={`dropdown-${label}`}>
            {label}
          </div>
        ) : null}
        <Dropdown
          buttonContent={
            <React.Fragment>
              <div>{displayValue || value}</div>
              <span className={`select-input-arrow-down ${Icons.angleDown}`} />
            </React.Fragment>
          }
          buttonClassName="button-select-input"
          buttonWidth={width}
          menuWidth={width}
          aria-labelledby={`dropdown-${label}`}
          matchButtonMenuWidth
          disabled={isEmpty(dropdownItems) || disabled}
        >
          {dropdownItems}
        </Dropdown>
      </div>
    );
  }
}
