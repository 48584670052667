import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import Icons from "../../lib/icons";
import Resources from "../../lib/resources";
import {
  formatDate,
  formatCurrency,
  isEmpty,
  updateSelectedRows,
  find,
  isProduction,
  getCurrencySymbol
} from "../../lib/utils";

import { dispatchToProps as saDP } from "../../store/statements-actions";
import { dispatchToProps as aaDP } from "../../store/accounts-actions";
import { dispatchToProps as cgDP } from "../../store/contextGroups-actions";
import { dispatchToProps as paDP } from "../../store/perspectives-actions";
import { dispatchToProps as modDP } from "../../store/modal-actions";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from "react-helmet";

import TableData from "../library/tableData";
import TableDataSortableHeader from "../library/tableDataSortableHeader";
import Card from "../library/card";
import AutoCompleteInput from "../library/autoCompleteInput";
import AutoPayIndicator from "./autoPayIndicator";
import MainLoader from "../mainLoader";

import IconFilter from "../library/icons/iconFilter";
import IconExport from "../library/icons/iconExport";
import IconArrowRight from "../library/icons/iconArrowRight";
import IconInfo from "../library/icons/iconInfo";
import IconClose from "../library/icons/iconClose";
import StatementCurrencySelector from "./statementCurrencySelector";

const dispatchToProps = dispatch => {
  return {
    ...dispatch(saDP),
    ...dispatch(aaDP),
    ...dispatch(cgDP),
    ...dispatch(paDP),
    ...dispatch(modDP)
  };
};

class Payments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.defaultState
    };
    this.toggleSelectRow = this.toggleSelectRow.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
  }

  defaultState = {
    selectedRows: [],
    selectedKeys: [],
    searchTerm: "",
    fromDate: "2017-01-01",
    toDate: moment()
      .startOf("day")
      .format("YYYY-MM-DD")
  };

  componentDidMount() {
    const { withCompanyId, perspectiveId, pageRowCount, companyId } = this.props;

    this.props.clearStatementsSearchResults();
    this.props.fetchPayments(companyId, perspectiveId, withCompanyId, null, null, pageRowCount);
    this.tryUpdate();
  }

  componentDidUpdate(prevProps) {
    this.tryUpdate(prevProps);
  }

  tryUpdate(prevProps) {
    const {
      perspectiveId,
      withCompanyId,
      pageRowCount,
      companyId,
      statementsStore: { paymentsFromDate, paymentsToDate, paymentsFromDueDate, paymentsToDueDate }
    } = this.props;

    if (isEmpty(perspectiveId) || isEmpty(withCompanyId)) {
      return;
    }

    if (this.sortHasChanged(prevProps) || this.dateFilterHasChanged(prevProps) || this.selectedCurrencyHasChanged()) {
      let filter = null;
      if (!isEmpty(paymentsFromDate)) {
        filter = `ProcessedDate%20>=%20${paymentsFromDate}%20and%20ProcessedDate%20<=%20${paymentsToDate}`;
      }

      this.props.fetchPayments(
        companyId,
        perspectiveId,
        withCompanyId,
        paymentsFromDueDate,
        paymentsToDueDate,
        pageRowCount,
        0,
        filter
      );
    }
  }

  sortHasChanged(prevProps) {
    if (isEmpty(prevProps)) {
      return false;
    }
    if (
      this.props.statementsStore.paymentsSortDirection !== prevProps.statementsStore.paymentsSortDirection ||
      this.props.statementsStore.paymentsSortBy !== prevProps.statementsStore.paymentsSortBy
    ) {
      return true;
    }
    return false;
  }

  dateFilterHasChanged(prevProps) {
    if (isEmpty(prevProps)) {
      return false;
    }

    const {
      statementsStore: { paymentsFromDate, paymentsToDate, paymentsFromDueDate, paymentsToDueDate }
    } = this.props;

    if (
      paymentsFromDate !== prevProps.statementsStore.paymentsFromDate ||
      paymentsToDate !== prevProps.statementsStore.paymentsToDate ||
      paymentsFromDueDate !== prevProps.statementsStore.paymentsFromDueDate ||
      paymentsToDueDate !== prevProps.statementsStore.paymentsToDueDate
    ) {
      return true;
    }
    return false;
  }

  selectedCurrencyHasChanged() {
    if (this.props.statementsStore.selectedCurrency !== this.props.statementsStore.paymentsCurrency) {
      return true;
    }
    return false;
  }

  onSortChange() {}

  toggleSelectRow(key, row) {
    let newSelectedRows = updateSelectedRows(row, this.state.selectedRows);
    let newSelectedKeys = updateSelectedRows(key, this.state.selectedKeys);
    this.setState({ selectedRows: newSelectedRows, selectedKeys: newSelectedKeys });
  }

  handleLoadMore(pageToLoad) {
    let { companyId, perspectiveId, withCompanyId, pageRowCount } = this.props;
    let top = pageRowCount;
    let skip = pageToLoad * pageRowCount - pageRowCount;
    this.props.fetchPayments(companyId, perspectiveId, withCompanyId, null, null, top, skip);
  }

  exportRows(rows) {
    if (isEmpty(rows)) {
      return null;
    }

    let { companyId, perspectiveId, withCompanyId, pageRowCount, statementsStore } = this.props;
    let { paymentFromDate, paymentToDate } = statementsStore;

    let options = {
      fromDate: paymentFromDate,
      toDate: paymentToDate
    };

    this.props.displayNotification("exportNotification");
    this.props.fetchTableExport(
      companyId,
      perspectiveId,
      withCompanyId,
      "payments",
      pageRowCount,
      statementsStore.paymentsSkip,
      rows.map(row => row.id),
      options
    );
  }

  getPaymentsColumns(hover) {
    let { statementsStore } = this.props;

    const selectCol = {
      type: "rowSelect",
      width: "5%"
    };

    const transactionDateCol = {
      header: (
        <TableDataSortableHeader
          sortBy={statementsStore.paymentsSortBy}
          sortDirection={statementsStore.paymentsSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.onSortChange();
            this.props.updatePaymentsSort(sortBy, sortDirection);
          }}
          text={Resources.TransactionDate.toLocaleUpperCase()}
          sortKey={"ProcessedDate"}
        />
      ),
      sortable: noSort => noSort === false,
      content: row => (
        <span className="fw-500">
          {formatDate(row.processedDate, row.paymentName.toLowerCase() !== "pending payment", false)}
        </span>
      ),
      width: "16%",
      name: "tran-date"
    };

    const transactionIdCol = {
      header: (
        <TableDataSortableHeader
          sortBy={statementsStore.paymentsSortBy}
          sortDirection={statementsStore.paymentsSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.onSortChange();
            this.props.updatePaymentsSort(sortBy, sortDirection);
          }}
          text={Resources.TransactionId.toLocaleUpperCase()}
          sortKey={"Id"}
        />
      ),
      sortable: noSort => noSort === false,
      content: row => {
        let hasAttachment = !isEmpty(row.attachments);
        return (
          <div className={`statements-invoice-id ${hasAttachment ? "link" : ""}`}>
            <div className="mr-2">{row.id}</div>
            {hasAttachment && <span className={Icons.filePdf} />}
          </div>
        );
      },
      width: "15%",
      name: "tran-id"
    };

    const amountCol = {
      header: (
        <TableDataSortableHeader
          sortBy={statementsStore.paymentsSortBy}
          sortDirection={statementsStore.paymentsSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.onSortChange();
            this.props.updatePaymentsSort(sortBy, sortDirection);
          }}
          text={Resources.PaymentAmt.toLocaleUpperCase()}
          sortKey={"Amount"}
        />
      ),
      sortable: noSort => noSort === false,
      content: row => (
        <div className="statements-currency-row">
          <span className="mr-2">{getCurrencySymbol(row.currencyId)}</span>
          <span>{formatCurrency(row.amount, "")}</span>
        </div>
      ),
      width: "9rem",
      name: "tran-amount"
    };

    const fillerCol = { width: "2%", content: row => null };

    const transactionTypeCol = {
      header: (
        <TableDataSortableHeader
          sortBy={statementsStore.paymentsSortBy}
          sortDirection={statementsStore.paymentsSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.onSortChange();
            this.props.updatePaymentsSort(sortBy, sortDirection);
          }}
          text={Resources.TransactionType.toLocaleUpperCase()}
          sortKey={"PaymentType"}
        />
      ),
      sortable: noSort => noSort === false,
      content: row => (
        <span>{row.paymentName.toLowerCase() === "pending payment" ? Resources.PendingPayment : row.paymentType}</span>
      ),
      name: "tran-type"
    };
    const invoicesCol = {
      header: Resources.Invoices.toLocaleUpperCase(),
      content: row => {
        let invoicesApplicationContent;
        if (row.invoices.length === 1) {
          invoicesApplicationContent = Resources.OneApplication;
        } else {
          invoicesApplicationContent = Resources.NApplications(row.invoices.length);
        }
        return (
          <div className={"payments-invoices" + (row.invoices.length > 0 ? " link" : "")}>
            {row.invoices.length > 0 ? (
              <button
                className="payments-invoices-link"
                onClick={() => {
                  this.props.displayFlyout("invoicesFlyout", {
                    payment: row
                  });
                }}
              >
                {invoicesApplicationContent}
              </button>
            ) : (
              <span>{invoicesApplicationContent}</span>
            )}
          </div>
        );
      },
      width: "15%",
      name: "invoices"
    };

    const unappliedAmountCol = {
      header: (
        <TableDataSortableHeader
          sortBy={statementsStore.paymentsSortBy}
          sortDirection={statementsStore.paymentsSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.onSortChange();
            this.props.updatePaymentsSort(sortBy, sortDirection);
          }}
          text={Resources.UnappliedAmt.toLocaleUpperCase()}
          sortKey={"UnappliedAmt"}
        />
      ),
      sortable: noSort => noSort === false,
      content: row => (
        <div className="statements-currency-row">
          <span className="mr-2">{getCurrencySymbol(row.currencyId)}</span>
          <span>{formatCurrency(row.unappliedAmount, "")}</span>
        </div>
      ),
      width: "10rem",
      name: "unapplied-amount"
    };

    const columns = [
      selectCol,
      transactionDateCol,
      transactionIdCol,
      amountCol,
      fillerCol,
      unappliedAmountCol,
      fillerCol,
      invoicesCol,
      transactionTypeCol
    ];
    const hoverColumns = [
      selectCol,
      transactionDateCol,
      transactionIdCol,
      amountCol,
      fillerCol,
      unappliedAmountCol,
      fillerCol,
      invoicesCol,
      {
        content: (row, i) => (
          <div className="flex-end flex-align-center">
            <button
              data-test-id="payments__icon-export"
              className="button-action-icon"
              onClick={() => this.exportRows([row])}
              disabled={isEmpty(row.id)}
            >
              <IconExport height="20" className="icon-button" />
            </button>
          </div>
        )
      }
    ];

    if (hover) {
      return hoverColumns;
    }
    return columns;
  }

  renderEmptyState(useCard) {
    let svgInternals = (
      <g fill="none" fillRule="evenodd">
        <g transform="translate(0 1)">
          <path
            fill="#F4F3FF"
            d="M256.359007 13.6844038c6.194263 0 11.215706 5.0382134 11.215706 11.2531646 0 6.2149511-5.021443 11.2531645-11.215706 11.2531645h-64.089752c6.194264 0 11.215706 5.0382134 11.215706 11.2531646s-5.021442 11.2531645-11.215706 11.2531645h35.249363c6.194264 0 11.215707 5.0382134 11.215707 11.2531646s-5.021443 11.2531646-11.215707 11.2531646h-16.301089c-7.810158 0-14.141543 5.0382133-14.141543 11.2531645 0 4.1433008 3.204488 7.8943553 9.613463 11.2531643 6.194264 0 11.215707 5.038214 11.215707 11.253165 0 6.214951-5.021443 11.253164-11.215707 11.253164H73.7032144c-6.1942637 0-11.2157066-5.038213-11.2157066-11.253164s5.0214429-11.253165 11.2157066-11.253165H11.2157065C5.02144286 103.70972 0 98.6715069 0 92.4565557s5.02144286-11.2531645 11.2157065-11.2531645h64.0897517c6.1942636 0 11.2157065-5.0382134 11.2157065-11.2531646S81.4997218 58.697062 75.3054582 58.697062H35.2493634c-6.1942637 0-11.2157065-5.0382133-11.2157065-11.2531645 0-6.2149512 5.0214428-11.2531646 11.2157065-11.2531646H99.339115c-6.1942636 0-11.2157065-5.0382134-11.2157065-11.2531645 0-6.2149512 5.0214429-11.2531646 11.2157065-11.2531646h157.019892zm0 45.0126582c6.194263 0 11.215706 5.0382134 11.215706 11.2531646s-5.021443 11.2531646-11.215706 11.2531646c-6.194264 0-11.215707-5.0382134-11.215707-11.2531646s5.021443-11.2531646 11.215707-11.2531646z"
          />
          <path
            fill="#FFF"
            d="M34.9940598 22.8405053L171.588563.89960334c1.635884-.26276888 3.175047.85036137 3.437816 2.486246a3.10756657 3.10756657 0 01.00204.01287956l13.57545 86.9261557c.254871 1.6319821-.857427 3.1629751-2.488286 3.4249367L49.5210804 115.690723c-1.6358846.262769-3.1750474-.850361-3.4378163-2.486246a3.00828154 3.00828154 0 01-.0020401-.012879L32.5057736 26.265442c-.2548702-1.6319821.8574273-3.1629751 2.4882862-3.4249367z"
          />
          <path
            stroke="#BEB6FF"
            strokeLinecap="round"
            strokeWidth="3.5"
            d="M58.2333109 114.035878l-5.1005166.843608c-3.5036009.579234-6.7936572-1.916522-7.3487197-5.574547L33.7271349 29.823123c-.5549122-3.6580485 1.8354537-7.0931614 5.3390317-7.6725393L167.527812.90665403c3.503662-.57884452 6.793719 1.91691106 7.348781 5.57493577.540519 3.5641781.97141 6.4046619 1.292522 8.5214752m.915828 6.0372463c.29086 1.9173827.545566 3.5964353.764118 5.0371578"
          />
          <path
            fill="#DEDAFF"
            d="M41.4275766 26.4921312L166.384315 6.91307652c1.633029-.25587392 3.165358.85740821 3.426669 2.48957633l12.368964 77.25744205c.261928 1.6360195-.851994 3.1746097-2.488013 3.4365374a3.0191097 3.0191097 0 01-.009868.0015631L54.7253282 109.67725c-1.6330293.255874-3.1653582-.857408-3.4266693-2.489576L38.9296952 29.9302318c-.2619277-1.6360196.8519938-3.1746098 2.4880133-3.4365375a3.01622825 3.01622825 0 01.0098681-.0015631z"
          />
          <rect
            width="139.099697"
            height="91.3481013"
            x="65.8397516"
            y="33.1179481"
            fill="#FFF"
            stroke="#BEB6FF"
            strokeWidth="3.5"
            rx="4"
          />
          <path fill="#F4F3FF" d="M68.0953611 52.2666823h134.588478v22.5063291H68.0953611z" />
          <path
            stroke="#BEB6FF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3.5"
            d="M203.759126 52.2666823H98.2670254m95.8786376 20.8987342H67.8254708M125.075156 103.70972H79.7190744m10.4697265-51.4430377h-11.530433"
          />
        </g>
      </g>
    );
    if (useCard) {
      return (
        <Card data-test-id="payments__empty-state" className="payments-empty-state">
          <svg viewBox="0 0 268 128" className="mt-4">
            {svgInternals}
          </svg>
          <h2>{Resources.NothingToSeeHere}</h2>
          <p>{Resources.PortalPaymentsEmpty}</p>
        </Card>
      );
    } else {
      return (
        <div className="payments-empty-state">
          <svg viewBox="0 0 268 128">{svgInternals}</svg>
          <h2>{Resources.NothingToSeeHere}</h2>
          <p>{Resources.PortalPaymentsEmpty}</p>
        </div>
      );
    }
  }

  render() {
    let { statementsStore, withCompanyId, companyId, withCompanyName } = this.props;

    const data = statementsStore.payments;
    const loading = statementsStore.isFetchingPayments;
    const maxRows = statementsStore.isShowingSearchResult ? data.length : statementsStore.paymentsCount;

    const prevYearTotal =
      (statementsStore.paymentsCurrencyStats[statementsStore.selectedCurrency] || {}).totalAmountPastYear ||
      statementsStore.paymentsTotalAmountPastYear;
    const currentYearTotal =
      (statementsStore.paymentsCurrencyStats[statementsStore.selectedCurrency] || {}).totalAmountCurrentYear ||
      statementsStore.paymentsTotalAmountCurrentYear;
    const totalUnnaplied =
      (statementsStore.paymentsCurrencyStats[statementsStore.selectedCurrency] || {}).totalUnapplied ||
      statementsStore.paymentsTotalUnapplied;

    const hasMultiCurrency = (statementsStore.currencies || []).length > 1;

    const helmet = (
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {withCompanyName} | {Resources.Payments}
        </title>
      </Helmet>
    );

    if (
      loading &&
      (isEmpty(prevYearTotal) || prevYearTotal === 0) &&
      (isEmpty(currentYearTotal) || currentYearTotal === 0)
    ) {
      return (
        <React.Fragment>
          {helmet}
          <MainLoader fullScreen className="portal-page-loader" />
        </React.Fragment>
      );
    }

    if (
      isEmpty(data) &&
      isEmpty(statementsStore.paymentsFromDate) &&
      isEmpty(statementsStore.paymentsToDate) &&
      isEmpty(statementsStore.paymentsFromDueDate) &&
      isEmpty(statementsStore.paymentsToDueDate) &&
      isEmpty(this.state.searchTerm) &&
      (isEmpty(prevYearTotal) || prevYearTotal === 0) &&
      (isEmpty(currentYearTotal) || currentYearTotal === 0)
    ) {
      return (
        <React.Fragment>
          {helmet}
          {hasMultiCurrency && <StatementCurrencySelector />}
          {this.renderEmptyState(hasMultiCurrency)}
        </React.Fragment>
      );
    }

    const autoPayAllowed = !isProduction() && (statementsStore.paymentInfo[withCompanyId] || {}).allowAutomaticPayments;
    const autoPayEnabled =
      ((statementsStore.paymentInfo[withCompanyId] || {}).automaticPaymentConfiguration || {})
        .automaticPaymentEnabled || false;

    return (
      <React.Fragment>
        {helmet}
        {hasMultiCurrency && <StatementCurrencySelector />}
        <Card className="statements-summary">
          <div className="statements-summary-row">
            <div className="statements-summary-item">
              <div className="statements-summary-item-header flex-align-center">
                {Resources.TotalPaidIn(
                  moment()
                    .subtract(1, "year")
                    .year()
                )}
                <div style={{ position: "relative" }}>
                  <button
                    data-test-id="payments__icon-info"
                    className="button-info"
                    style={{ marginLeft: ".33rem" }}
                    onClick={() => this.setState({ showLastYearHelperText: true, showThisYearHelperText: false })}
                  >
                    <IconInfo height={16} />
                  </button>
                  {this.state.showLastYearHelperText && (
                    <Card className="account-balance-info">
                      <div className="flex-align-start" style={{ width: "23.6rem" }}>
                        <span className="account-balance-helper-text">{Resources.TotalPaidHelperText}</span>
                        <button
                          data-test-id="payments__icon-close"
                          onClick={() => this.setState({ showLastYearHelperText: false })}
                          style={{ padding: "0", marginTop: "-.4rem" }}
                        >
                          <IconClose height={14} />
                        </button>
                      </div>
                    </Card>
                  )}
                </div>
              </div>
              <div className="statements-summary-item-data">
                {formatCurrency(prevYearTotal, statementsStore.selectedCurrency)}
              </div>
            </div>
            <div className="statements-summary-item">
              <div className="statements-summary-item-header flex-align-center">
                {Resources.TotalPaidIn(moment().year())}
                <div style={{ position: "relative" }}>
                  <button
                    data-test-id="payments__icon-info-other"
                    className="button-info"
                    style={{ marginLeft: ".33rem" }}
                    onClick={() => this.setState({ showThisYearHelperText: true, showLastYearHelperText: false })}
                  >
                    <IconInfo height={16} />
                  </button>
                  {this.state.showThisYearHelperText && (
                    <Card className="account-balance-info">
                      <div className="flex-align-start" style={{ width: "23.6rem" }}>
                        <span className="account-balance-helper-text">{Resources.TotalPaidHelperText}</span>
                        <button
                          data-test-id="payments__icon-close-other"
                          onClick={() => this.setState({ showThisYearHelperText: false })}
                          style={{ padding: "0", marginTop: "-.4rem" }}
                        >
                          <IconClose height={14} />
                        </button>
                      </div>
                    </Card>
                  )}
                </div>
              </div>
              <div className="statements-summary-item-data">
                {formatCurrency(currentYearTotal, statementsStore.selectedCurrency)}
              </div>
            </div>
            <div className="statements-summary-item" style={{ marginRight: "-6rem" }}>
              <div className="statements-summary-item-header flex-align-center">
                {Resources.TotalUnappliedPaymentAmount}
              </div>
              <div className="statements-summary-item-data">
                {formatCurrency(totalUnnaplied, statementsStore.selectedCurrency)}
              </div>
            </div>
            {autoPayAllowed && (
              <AutoPayIndicator
                onClickManage={() => this.props.displayFlyout("manageAutoPayFlyout", { companyId, withCompanyId })}
                autoPayEnabled={autoPayEnabled}
              />
            )}
          </div>
        </Card>
        <Card type="table" className="portal-table">
          <div className="table-data-card-header inline">
            <div className="table-data-card-header-search">
              <AutoCompleteInput
                className="auto-complete-input__table-data-search"
                placeholder={Resources.Search}
                onChange={e => {
                  const searchTerm = e.target.value;
                  this.setState({ searchTerm });
                  this.props.fetchStatementSearchResults({
                    companyId: this.props.companyId,
                    perspectiveId: this.props.perspectiveId,
                    withCompanyId: this.props.withCompanyId,
                    searchTerm,
                    type: "payments"
                  });
                }}
                isShowingSearchResult={statementsStore.isShowingSearchResult}
                handleClearResult={() => {
                  this.setState({ searchTerm: "" });
                  this.props.clearStatementsSearchResults();
                  this.props.fetchPayments(
                    this.props.companyId,
                    this.props.perspectiveId,
                    this.props.withCompanyId,
                    null,
                    null,
                    this.props.pageRowCount
                  );
                }}
                text={this.state.searchTerm}
                noResultsMessage={Resources.NoStatementSearchResultsMessage}
                showNoResultsMessage={statementsStore.fetchedStatementsSearchResults}
                maxOptions={7}
                loading={statementsStore.fetchingStatementsSearchResults}
                handleSelectOption={option => {
                  this.setState({ searchTerm: option.id });
                  this.props.fetchStatementSearchResults({
                    companyId: this.props.companyId,
                    perspectiveId: this.props.perspectiveId,
                    withCompanyId: this.props.withCompanyId,
                    searchTerm: option.id,
                    type: "payments"
                  });
                  this.props.setPaymentsToSearchResult([option]);
                }}
                callToActionButton={
                  <div
                    className="dropdown-item-clean dropdown-call-to-action"
                    onClick={() => this.props.setPaymentsToSearchResult(statementsStore.searchResults)}
                  >
                    {Resources.SeeAllPaymentsResults(this.state.searchTerm)}
                  </div>
                }
                renderOption={option => `${Resources.TransactionId} ${option.id}`}
                options={statementsStore.searchResults}
                width="300px"
              />
            </div>
            <div className="table-data-card-header-buttons">
              <div className="dropdown">
                <button
                  data-test-id="payments__icon-filter"
                  className="button-action-icon dropdown-toggle customer-portal-button-filter"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ marginTop: "1.9rem" }}
                >
                  <IconFilter height="18"></IconFilter>
                </button>
                <div className="dropdown-menu">
                  <form>
                    <div className="mb-2">{Resources.PaymentDate}</div>
                    <div
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    >
                      <span>
                        <DatePicker
                          startOpen
                          className="date-picker-input"
                          calendarClassName="date-picker-calendar"
                          dayClassName={d => "date-picker-day"}
                          dateFormat="MMM d, yyyy"
                          // startDate={new Date(this.state.fromDate)}
                          // endDate={new Date(this.state.toDate)}
                          maxDate={new Date(this.state.toDate)}
                          selected={new Date(this.state.fromDate)}
                          // selectsStart
                          onChange={d =>
                            this.setState({
                              fromDate: d
                            })
                          }
                        />
                      </span>
                      <IconArrowRight line className="mr-3 ml-3" height={14}></IconArrowRight>
                      <span>
                        <DatePicker
                          startOpen
                          className="date-picker-input"
                          calendarClassName="date-picker-calendar"
                          dayClassName={d => "date-picker-day"}
                          dateFormat="MMM d, yyyy"
                          // startDate={new Date(this.state.fromDate)}
                          // endDate={new Date(this.state.toDate)}
                          minDate={new Date(this.state.fromDate)}
                          selected={new Date(this.state.toDate)}
                          // selectsStart
                          onChange={d =>
                            this.setState({
                              toDate: d
                            })
                          }
                        />
                      </span>
                    </div>
                  </form>
                  <div className="filter-dropdown-buttons">
                    <button
                      data-test-id="payments__icon-filter-reset"
                      className="filter-dropdown-reset"
                      onClick={() => {
                        this.setState({
                          fromDate: this.defaultState.fromDate,
                          toDate: this.defaultState.toDate
                        });
                        this.props.updatePaymentsDate(null, null);
                      }}
                    >
                      {Resources.Reset}
                    </button>
                    <button
                      data-test-id="payments__icon-filter-save"
                      className="filter-dropdown-save"
                      onClick={() => {
                        this.props.updatePaymentsDate(
                          moment(this.state.fromDate)
                            .utc()
                            .startOf("day")
                            .format("YYYY-MM-DD"),
                          moment(this.state.toDate)
                            .utc()
                            .startOf("day")
                            .format("YYYY-MM-DD")
                        );
                      }}
                    >
                      {Resources.Save}
                    </button>
                  </div>
                </div>
              </div>
              <button
                data-test-id="payments__button-export"
                className="button-primary"
                disabled={!this.state.selectedRows.some(row => !isEmpty(row.id))}
                onClick={() => this.exportRows(this.state.selectedRows)}
              >
                <IconExport height="20" className="button-primary-icon" />
                {Resources.Export}
              </button>
            </div>
          </div>
          <TableData
            name="payments__table"
            pagination
            data={data}
            columns={this.getPaymentsColumns(false)}
            hoverColumns={this.getPaymentsColumns(true)}
            rowHeight="4em"
            onLoadMore={pageSelected => this.handleLoadMore(pageSelected)}
            maxRows={maxRows}
            loading={loading}
            selectedKeys={this.state.selectedKeys}
            rowKey="ledgerHash"
            onRowSelectToggle={key =>
              this.toggleSelectRow(
                key,
                (Array.isArray(key) ? [...key] : [key]).map(k => find(data, row => row.ledgerHash === k))
              )
            }
            rowClassName="statements-view-row"
            offsetHeight="40"
            emptyRender={
              <div className="table-data-empty-render">
                <h4 className="mt-5">{Resources.EmptyStatements}</h4>
              </div>
            }
          />
        </Card>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    contextGroupsStore: store.contextGroups,
    statementsStore: store.statements,
    perspectivesStore: store.perspectives,
    ledgerStore: store.ledger,
    pageRowCount: store.general.pageRowCount
  };
};

export default connect(storeToProps, dispatchToProps)(Payments);
