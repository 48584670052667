import React, { Component } from "react";
import { connect } from "react-redux";
import { generatePath, withRouter } from "react-router-dom";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

import { dispatchToProps as persDP } from "../../store/perspectives-actions";
import { dispatchToProps as genDP } from "../../store/general-actions";
import { dispatchToProps as modDP } from "../../store/modal-actions";
import { dispatchToProps as networkDP } from "../../store/network-actions";
import { dispatchToProps as userDP } from "../../store/user-actions";
import { dispatchToProps as contDP } from "../../store/contextGroups-actions";

import Avatar from "../library/avatar";
import resources from "../../lib/resources";
import Dropdown from "../library/dropdown";
import { find, isEmpty } from "../../lib/utils";
import IconCircle from "../library/icons/iconCircle";
import IconPlusCircle from "../library/icons/iconPlusCircle";
import { Perspectives } from "../../lib/perspectives";

const dispatchToProps = dispatch => {
  return {
    ...persDP(dispatch),
    ...genDP(dispatch),
    ...modDP(dispatch),
    ...networkDP(dispatch),
    ...userDP(dispatch),
    ...contDP(dispatch)
  };
};

class PerspectiveSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      custContact: {}
    };
  }

  componentDidMount() {
    this.props.fetchMyProfile();
    this.props.fetchContextGroupsStats();
  }

  render() {
    let { selectedAccount } = this.props.accountsStore;

    let companyPerspectives = ((selectedAccount || {}).companies || [])
      .map(company => this.props.getCompanyPerspectives(company.companyId))
      .flat();
    let displayPerspectives = [];

    companyPerspectives.forEach(perspective => {
      if (!isEmpty(perspective) && !find(displayPerspectives, i => perspective.perspectiveId === i.perspectiveId)) {
        displayPerspectives.push(perspective);
      }
    });

    const { match } = this.props;

    const getAvatarText = perspective => {
      switch (perspective.parentPerspectiveId) {
        case "0":
          return perspective.perspectiveName;
        case "a316bce5-ba53-4eac-9af0-5e5609ca3e46":
          return resources.AccountsPayable;
        case "5be36b7d-090a-4597-bb2b-2e7b5876237f":
          return resources.AccountsReceivable;
        default:
          return perspective.parentPerspectiveId === "a316bce5-ba53-4eac-9af0-5e5609ca3e46" ? resources.AccountsPayable : resources.AccountsReceivable;
      }
    };

    let custContact = { ...this.props.networkStore.custContact, ...this.state.custContact };
    return (
      <div className="perspective-selector">
        {displayPerspectives.map(perspective => (
          <div
            className={`perspective-container ${
              perspective.perspectiveId === this.props.match.params.perspectiveId ? "active" : ""
            }`}
            onClick={() => {
              this.props.history.push(
                generatePath(this.props.match.path, {
                  ...this.props.match.params,
                  perspectiveId: perspective.perspectiveId,
                  companyId: perspective.companyId,
                  view: "home"
                })
              );
              this.props.setSelectedPerspectiveId(perspective.perspectiveId);
              this.props.selectCompany(perspective.companyId);
              this.props.fetchContextGroups(perspective.companyId, perspective.perspectiveId);
            }}
            key={perspective.perspectiveId + perspective.companyId}
          >
            <Avatar height="2.66rem" objectName={getAvatarText(perspective)} type="perspective" />
            <IconCircle
              className={`perspective-container__active-indicator ${
                (this.props.contextGroupsStats[perspective.perspectiveId] || {}).displayActiveIndicator ? " active" : ""
              }`}
              height={10}
            />
          </div>
        ))}
        <div className="perspective-container add-workspace-dropdown">
          <Dropdown
            buttonContent={
              <span className="add-workspace-dropdown__icon-container">
                <IconPlusCircle height={40} isFilled />
              </span>
            }
            menuAlign="right"
          >
            <Dropdown.Item disabled>
              <span className="add-workspace-dropdown__title">{resources.AddWorkspaceFor.toLocaleUpperCase()}</span>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                this.props.displayModal("addPerspectiveModal", { parentPerspectiveKey: Perspectives.AccountsPayable });
              }}
            >
              {resources.AccountsPayable}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                this.props.displayModal("addPerspectiveModal", {
                  parentPerspectiveKey: Perspectives.AccountsReceivable
                });
              }}
            >
              {resources.AccountsReceivable}
            </Dropdown.Item>
          </Dropdown>
        </div>
        <div className={"perspective-selector__profile-button"}>
          <Dropdown
            buttonContent={
              <Avatar height="2.66rem" type="contact" isCurrentUser imgURL={custContact.profilePictureURL} />
            }
            buttonClassName="dropdown-avatar"
            menuAlign="right"
          >
            <Dropdown.Item
              onClick={() => {
                this.props.displayFlyout("myProfileView", {
                  myProfile: selectedAccount,
                  companyId: this.props.match.params.companyId,
                  perspectiveId: this.props.match.params.perspectiveId
                });
              }}
            >
              {resources.MyProfile}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                this.props.displayFlyout("companyProfileView", {
                  myProfile: selectedAccount,
                  companyId: this.props.match.params.companyId,
                  perspectiveId: this.props.match.params.perspectiveId
                });
              }}
            >
              {resources.CompanyProfile}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => this.props.history.push(generatePath("/company/:companyId/manage", { ...match.params }))}
            >
              {resources.Settings}
            </Dropdown.Item>
            {this.props.flags.settingsFlyout && (
              <Dropdown.Item
                onClick={() => {
                  this.props.displayFlyout("settingsFlyout");
                }}
              >
                {resources.SettingsV2}
              </Dropdown.Item>
            )}
            <Dropdown.Item onClick={() => this.props.logout()}>{resources.Logout}</Dropdown.Item>
          </Dropdown>
        </div>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    accountsStore: store.accounts,
    contextGroupsStats: store.contextGroups.contextGroupsStats,
    modalStore: store.modal,
    networkStore: store.network,
    userStore: store.user
  };
};

export default withLDConsumer()(withRouter(connect(storeToProps, dispatchToProps)(PerspectiveSelector)));
