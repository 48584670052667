import data from "../lib/data";
import { actions as GeneralActions } from "./general-actions";
import { handlePromiseError } from "./error-actions";
import { find, isEmpty, debounce, saveBlobAsDownload } from "../lib/utils";
import Resources from "../lib/resources";

export const actions = {
  ...GeneralActions,
  ...{
    FETCHING_POPULATED_TEMPLATE: "FETCHING_POPULATED_TEMPLATE",
    FETCHED_POPULATED_TEMPLATE: "FETCHED_POPULATED_TEMPLATE",
    FETCH_POPULATED_TEMPLATE_FAILED: "FETCH_POPULATED_TEMPLATE_FAILED",

    CREATING_EMAIL_REPLY_TEMPLATE: "CREATING_EMAIL_REPLY_TEMPLATE",
    CREATED_EMAIL_REPLY_TEMPLATE: "CREATED_EMAIL_REPLY_TEMPLATE",
    CREATE_EMAIL_REPLY_TEMPLATE_FAILED: "CREATE_EMAIL_REPLY_TEMPLATE_FAILED",

    GETTING_ALL_EMAIL_REPLY_TEMPLATES: "GETTING_ALL_EMAIL_REPLY_TEMPLATES",
    GOT_ALL_EMAIL_REPLY_TEMPLATES: "GOT_ALL_EMAIL_REPLY_TEMPLATES",
    GET_ALL_EMAIL_REPLY_TEMPLATES_FAILED: "GET_ALL_EMAIL_REPLY_TEMPLATES_FAILED",

    CREATING_COMPANY_DOCUMENT: "CREATING_COMPANY_DOCUMENT",
    CREATED_COMPANY_DOCUMENT: "CREATED_COMPANY_DOCUMENT",
    CREATE_COMPANY_DOCUMENT_FAILED: "CREATE_COMPANY_DOCUMENT_FAILED",

    FETCHING_COMPANY_DOCUMENTS: "FETCHING_COMPANY_DOCUMENTS",
    FETCHED_COMPANY_DOCUMENTS: "FETCHED_COMPANY_DOCUMENTS",
    FETCH_COMPANY_DOCUMENTS_FAILED: "FETCH_COMPANY_DOCUMENTS_FAILED",

    IMPORTING_COMPANY_ADDRESS_BOOK: "IMPORTING_COMPANY_ADDRESS_BOOK",
    IMPORTED_COMPANY_ADDRESS_BOOK: "IMPORTED_COMPANY_ADDRESS_BOOK",
    IMPORT_COMPANY_ADDRESS_BOOK_FAILED: "IMPORT_COMPANY_ADDRESS_BOOK_FAILED",

    PUTTING_COMPANY_ADDRESS_BOOK: "PUTTING_COMPANY_ADDRESS_BOOK",
    PUT_COMPANY_ADDRESS_BOOK: "PUT_COMPANY_ADDRESS_BOOK",
    FAILED_PUT_COMPANY_ADDRESS_BOOK: "FAILED_PUT_COMPANY_ADDRESS_BOOK",

    POSTING_COMPANY_ADDRESS_BOOK: "POSTING_COMPANY_ADDRESS_BOOK",
    POST_COMPANY_ADDRESS_BOOK: "POST_COMPANY_ADDRESS_BOOK",
    FAILED_POST_COMPANY_ADDRESS_BOOK: "FAILED_POST_COMPANY_ADDRESS_BOOK",

    FETCHING_COMPANY_ADDRESS_BOOK: "FETCHING_COMPANY_ADDRESS_BOOK",
    FETCHED_COMPANY_ADDRESS_BOOK: "FETCHED_COMPANY_ADDRESS_BOOK",
    FETCH_COMPANY_ADDRESS_BOOK_FAILED: "FETCH_COMPANY_ADDRESS_BOOK_FAILED",

    DELETING_COMPANY_ADDRESS_BOOK_CONTACT: "DELETING_COMPANY_ADDRESS_BOOK_CONTACT",
    DELETED_COMPANY_ADDRESS_BOOK_CONTACT: "DELETED_COMPANY_ADDRESS_BOOK_CONTACT",
    DELETE_COMPANY_ADDRESS_BOOK_CONTACT_FAILED: "DELETE_COMPANY_ADDRESS_BOOK_CONTACT_FAILED",

    UPDATING_COMPANY_DOCUMENT: "UPDATING_COMPANY_DOCUMENT",
    UPDATED_COMPANY_DOCUMENT: "UPDATED_COMPANY_DOCUMENT",
    UPDATE_COMPANY_DOCUMENT_FAILED: "UPDATE_COMPANY_DOCUMENT_FAILED",

    UPDATING_DOCUMENT_METADATA: "UPDATING_DOCUMENT_METADATA",
    UPDATED_DOCUMENT_METADATA: "UPDATED_DOCUMENT_METADATA",
    UPDATE_DOCUMENT_METADATA_FAILED: "UPDATE_DOCUMENT_METADATA_FAILED",

    CLEAR_ALL_REPLY_TEMPLATES: "CLEAR_ALL_REPLY_TEMPLATES",

    GETTING_ALL_EMAIL_CONFIG_TYPES: "GETTING_ALL_EMAIL_CONFIG_TYPES",
    GOT_ALL_EMAIL_CONFIG_TYPES: "GOT_ALL_EMAIL_CONFIG_TYPES",
    GET_ALL_EMAIL_CONFIG_TYPES_FAILED: "GET_ALL_EMAIL_CONFIG_TYPES_FAILED",

    SETTING_COMPANY_EMAIL_CONFIG: "SETTING_COMPANY_EMAIL_CONFIG",
    SET_COMPANY_EMAIL_CONFIG: "SET_COMPANY_EMAIL_CONFIG",
    SET_COMPANY_EMAIL_CONFIG_FAILED: "SET_COMPANY_EMAIL_CONFIG_FAILED",

    GETTING_COMPANY_EMAIL_CONFIG: "GETTING_COMPANY_EMAIL_CONFIG",
    GOT_COMPANY_EMAIL_CONFIG: "GOT_COMPANY_EMAIL_CONFIG",
    GET_COMPANY_EMAIL_CONFIG_FAILED: "GET_COMPANY_EMAIL_CONFIG_FAILED",

    GETTING_COMPANY_EMAIL_CONFIG_VERIFICATION: "GETTING_COMPANY_EMAIL_CONFIG_VERIFICATION",
    GOT_COMPANY_EMAIL_CONFIG_VERIFICATION: "GOT_COMPANY_EMAIL_CONFIG_VERIFICATION",
    GET_COMPANY_EMAIL_CONFIG_VERIFICATION_FAILED: "GET_COMPANY_EMAIL_CONFIG_VERIFICATION_FAILED",

    GETTING_COMPANY_EMAIL_CONFIG_TYPE: "GETTING_COMPANY_EMAIL_CONFIG_TYPE",
    GOT_COMPANY_EMAIL_CONFIG_TYPE: "GOT_COMPANY_EMAIL_CONFIG_TYPE",
    GET_COMPANY_EMAIL_CONFIG_TYPE_FAILED: "GET_COMPANY_EMAIL_CONFIG_TYPE_FAILED",

    GETTING_COMPANY_WORKFLOWS: "GETTING_COMPANY_WORKFLOWS",
    GOT_COMPANY_WORKFLOWS: "GOT_COMPANY_WORKFLOWS",
    FAILED_GET_COMPANY_WORKFLOWS: "FAILED_GET_COMPANY_WORKFLOWS",

    PUTTING_COMPANY_WORKFLOW: "PUTTING_COMPANY_WORKFLOW",
    PUT_COMPANY_WORKFLOW: "PUT_COMPANY_WORKFLOW",
    FAILED_PUT_COMPANY_WORKFLOW: "FAILED_PUT_COMPANY_WORKFLOW",

    DELETING_COMPANY_WORKFLOW: "DELETING_COMPANY_WORKFLOW",
    DELETED_COMPANY_WORKFLOW: "DELETED_COMPANY_WORKFLOW",
    FAILED_DELETE_COMPANY_WORKFLOW: "FAILED_DELETE_COMPANY_WORKFLOW",

    UPDATING_COMPANY_WORKFLOW: "UPDATING_COMPANY_WORKFLOW",
    UPDATED_COMPANY_WORKFLOW: "UPDATED_COMPANY_WORKFLOW",
    FAILED_UPDATE_COMPANY_WORKFLOW: "FAILED_UPDATE_COMPANY_WORKFLOW",

    GETTING_COMMUNICATION_TEMPLATES: "GETTING_COMMUNICATION_TEMPLATES",
    GOT_COMMUNICATION_TEMPLATES: "GOT_COMMUNICATION_TEMPLATES",
    FAILED_GET_COMMUNICATION_TEMPLATES: "FAILED_GET_COMMUNICATION_TEMPLATES",

    POSTING_COMMUNICATION_TEMPLATE: "POSTING_COMMUNICATION_TEMPLATE",
    POSTED_COMMUNICATION_TEMPLATE: "POSTED_COMMUNICATION_TEMPLATE",
    FAILED_POST_COMMUNICATION_TEMPLATE: "FAILED_POST_COMMUNICATION_TEMPLATE",

    DELETING_COMMUNICATION_TEMPLATE: "DELETING_COMMUNICATION_TEMPLATE",
    DELETED_COMMUNICATION_TEMPLATE: "DELETED_COMMUNICATION_TEMPLATE",
    FAILED_DELETE_COMMUNICATION_TEMPLATE: "FAILED_DELETE_COMMUNICATION_TEMPLATE",

    GETTING_CUSTOMER_GROUPS: "GETTING_CUSTOMER_GROUPS",
    GOT_CUSTOMER_GROUPS: "GOT_CUSTOMER_GROUPS",
    FAILED_GET_CUSTOMER_GROUPS: "FAILED_GET_CUSTOMER_GROUPS",

    POSTING_CUSTOMER_GROUP: "POSTING_CUSTOMER_GROUP",
    POSTED_CUSTOMER_GROUP: "POSTED_CUSTOMER_GROUP",
    FAILED_POST_CUSTOMER_GROUP: "FAILED_POST_CUSTOMER_GROUP",

    DELETING_CUSTOMER_GROUP: "DELETING_CUSTOMER_GROUP",
    DELETED_CUSTOMER_GROUP: "DELETED_CUSTOMER_GROUP",
    FAILED_DELETE_CUSTOMER_GROUP: "FAILED_DELETE_CUSTOMER_GROUP",

    GETTING_CUSTOMER_GROUPS_FILTERS: "GETTING_CUSTOMER_GROUPS_FILTERS",
    GOT_CUSTOMER_GROUPS_FILTERS: "GOT_CUSTOMER_GROUPS_FILTERS",
    FAILED_GET_CUSTOMER_GROUPS_FILTERS: "FAILED_GET_CUSTOMER_GROUPS_FILTERS",

    POSTING_CUSTOMER_GROUPS_PREVIEW: "POSTING_CUSTOMER_GROUPS_PREVIEW",
    POSTED_CUSTOMER_GROUPS_PREVIEW: "POSTED_CUSTOMER_GROUPS_PREVIEW",
    FAILED_POST_CUSTOMER_GROUPS_PREVIEW: "FAILED_POST_CUSTOMER_GROUPS_PREVIEW",

    GETTING_CUSTOMERS: "GETTING_CUSTOMERS",
    GOT_CUSTOMERS: "GOT_CUSTOMERS",
    FAILED_GET_CUSTOMERS: "FAILED_GET_CUSTOMERS",

    UPDATE_CUSTOMER_GROUPS_SORT: "UPDATE_CUSTOMER_GROUPS_SORT",

    CLEAR_CUSTOMERS_SEARCH_RESULTS: "CLEAR_CUSTOMERS_SEARCH_RESULTS",
    DISPLAY_CUSTOMERS_SEARCH_RESULTS: "DISPLAY_CUSTOMERS_SEARCH_RESULTS",
    FETCHING_CUSTOMERS_SEARCH_RESULTS: "FETCHING_CUSTOMERS_SEARCH_RESULTS",
    FETCHED_CUSTOMERS_SEARCH_RESULTS: "FETCHED_CUSTOMERS_SEARCH_RESULTS",
    FETCH_CUSTOMERS_SEARCH_RESULTS_FAILED: "FETCH_CUSTOMERS_SEARCH_RESULTS_FAILED",

    GETTING_COMMUNICATION_TEMPLATE_DESTINATIONS: "GETTING_COMMUNICATION_TEMPLATE_DESTINATIONS",
    GOT_COMMUNICATION_TEMPLATE_DESTINATIONS: "GOT_COMMUNICATION_TEMPLATE_DESTINATIONS",
    FAILED_GET_COMMUNICATION_TEMPLATE_DESTINATIONS: "FAILED_GET_COMMUNICATION_TEMPLATE_DESTINATIONS",

    GETTING_COMMUNICATION_TEMPLATE_SNIPPETS: "GETTING_COMMUNICATION_TEMPLATE_SNIPPETS",
    GOT_COMMUNICATION_TEMPLATE_SNIPPETS: "GOT_COMMUNICATION_TEMPLATE_SNIPPETS",
    FAILED_GET_COMMUNICATION_TEMPLATE_SNIPPETS: "FAILED_GET_COMMUNICATION_TEMPLATE_SNIPPETS",

    GETTING_COMMUNICATION_TEMPLATE_ATTACHMENT: "GETTING_COMMUNICATION_TEMPLATE_ATTACHMENT",
    GOT_COMMUNICATION_TEMPLATE_ATTACHMENT: "GOT_COMMUNICATION_TEMPLATE_ATTACHMENT",
    FAILED_GET_COMMUNICATION_TEMPLATE_ATTACHMENT: "FAILED_GET_COMMUNICATION_TEMPLATE_ATTACHMENT",

    UPDATE_COMMUNICATION_TEMPLATES_SORT: "UPDATE_COMMUNICATION_TEMPLATES_SORT",

    GETTING_EMAIL_CONFIG_TEST: "GETTING_EMAIL_CONFIG_TEST",
    GOT_EMAIL_CONFIG_TEST: "GOT_EMAIL_CONFIG_TEST",
    FAILED_GET_EMAIL_CONFIG_TEST: "FAILED_GET_EMAIL_CONFIG_TEST",

    CLEAR_MANAGE_IS_SET_DATA: "CLEAR_MANAGE_IS_SET_DATA"
  }
};

const importCompanyAddressBook = (companyId, contactsCSV) => (dispatch, getState) => {
  if (getState().manage.isImportingCompanyAddressBook) {
    return;
  }
  const formData = new FormData();

  formData.append("csv", contactsCSV);
  dispatch({ type: actions.IMPORTING_COMPANY_ADDRESS_BOOK });

  data
    .post(`v1/api/account/company/${companyId}/addressbook/import`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(response => {
      dispatch({ type: actions.IMPORTED_COMPANY_ADDRESS_BOOK });
      dispatch(fetchCompanyAddressBook(companyId));
    })
    .catch(error => {
      handlePromiseError(
        error,
        "TODO: Importing contacts failed. Please try again. If you continue to have trouble, please contact Lockstep support.",
        "address book"
      );
      dispatch({ type: actions.IMPORT_COMPANY_ADDRESS_BOOK_FAILED });
    });
};

const putAddressBookContact = (companyId, contact) => (dispatch, getState) => {
  if (getState().manage.puttingAddressBookContact) {
    return;
  }

  dispatch({ type: actions.PUTTING_COMPANY_ADDRESS_BOOK });

  return data
    .put(`v1/api/account/company/${companyId}/addressbook`, contact)
    .then(response => {
      dispatch({ type: actions.PUT_COMPANY_ADDRESS_BOOK });
      dispatch(fetchCompanyAddressBook(companyId));
      return response;
    })
    .catch(error => {
      handlePromiseError(error, "TODO: Adding company contact failed. Please try again", "address book");
      dispatch({ type: actions.FAILED_PUT_COMPANY_ADDRESS_BOOK });
      throw error;
    });
};

const postAddressBookContact = (companyId, addressBookContactId, contact) => (dispatch, getState) => {
  if (getState().manage.postingAddressBookContact) {
    return;
  }

  dispatch({ type: actions.POSTING_COMPANY_ADDRESS_BOOK });

  return data
    .post(`v1/api/account/company/${companyId}/addressbook/${addressBookContactId}`, contact)
    .then(response => {
      dispatch({ type: actions.POST_COMPANY_ADDRESS_BOOK });
      dispatch(fetchCompanyAddressBook(companyId));
      return response;
    })
    .catch(error => {
      handlePromiseError(error, "TODO: Editing company contact failed. Please try again", "address book");
      dispatch({ type: actions.FAILED_POST_COMPANY_ADDRESS_BOOK });
      throw error;
    });
};

const fetchCompanyAddressBook = (companyId, filter) => (dispatch, getState) => {
  if (getState().manage.isFetchingCompanyAddressBook) {
    return;
  }

  let endpoint = `v1/api/account/company/${companyId}/addressbook`;

  if (!isEmpty(filter)) {
    endpoint += `?$filter=${filter}`;
  }

  dispatch({ type: actions.FETCHING_COMPANY_ADDRESS_BOOK });
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.FETCHED_COMPANY_ADDRESS_BOOK, addresses: response.data });
      return response.data;
    })
    .catch(rejection => {
      dispatch({ type: actions.FETCH_COMPANY_ADDRESS_BOOK_FAILED });
      handlePromiseError(
        rejection,
        "TODO: Fetching your company address book failed. Please try again.",
        "address book"
      );
    });
};

const getCompanyAddressBook = companyId => (dispatch, getState) => {
  const store = getState().manage;
  if (store.fetchingCompanyAddressBookFailed === null) {
    dispatch(fetchCompanyAddressBook(companyId));
    return null;
  } else if (store.fetchingCompanyAddressBookFailed === true) {
    return;
  }
  let addresses = [];
  const perspectives = store.companyAddressBook.perspectives;
  Object.keys(perspectives).forEach(perspective => {
    perspectives[perspective].forEach(contextGroup => {
      addresses = addresses.concat(contextGroup.items);
    });
  });
  return addresses;
};

const deleteCompanyAddressBookContact = (companyId, addressBookContactId) => (dispatch, getState) => {
  dispatch({ type: actions.DELETING_COMPANY_ADDRESS_BOOK_CONTACT });
  return data
    .delete(`v1/api/account/company/${companyId}/addressbook/${addressBookContactId}`)
    .then(response => {
      dispatch({ type: actions.DELETED_COMPANY_ADDRESS_BOOK_CONTACT, addresses: response.data });
    })
    .catch(rejection => {
      dispatch({ type: actions.DELETE_COMPANY_ADDRESS_BOOK_CONTACT_FAILED });
      handlePromiseError(
        rejection,
        "TODO: Deleting your company address book contact failed. Please try again.",
        "address book contact"
      );
    });
};

let CREATE_EMAIL_REPLY_TEMPLATE_PROMISE = null;
const createEmailReplyTemplate = (companyId, perspective, payloadObj) => dispatch => {
  dispatch({ type: actions.CREATING_EMAIL_REPLY_TEMPLATE });
  CREATE_EMAIL_REPLY_TEMPLATE_PROMISE = data
    .post(`v2/api/sequence/templates/${companyId}/${perspective}`, payloadObj)
    .then(() => {
      dispatch({ type: actions.CREATED_EMAIL_REPLY_TEMPLATE });
      dispatch(fetchAllCompanyReplyTemplates(companyId, perspective));
      return "TEMPLATE_CREATED";
    })
    .catch(e => {
      dispatch({ type: actions.CREATE_EMAIL_REPLY_TEMPLATE_FAILED });
      if (!isEmpty(e.response.data.ResourceId)) {
        handlePromiseError(
          e,
          "TODO: Creating the template failed. A template is already selected to that auto-fill role.",
          "template"
        );
      } else {
        handlePromiseError(
          e,
          "TODO: Creating the email reply template failed.  Please verify your template and try again.",
          "template"
        );
      }
    });

  return CREATE_EMAIL_REPLY_TEMPLATE_PROMISE;
};

export const getAllCompanyReplyTemplates = (companyId, perspective) => (dispatch, getState) => {
  const store = getState().manage;
  if (isEmpty(perspective)) {
    return null;
  }

  if (store.allCompanyReplyTemplatesIsFetched[perspective] !== true) {
    dispatch(fetchAllCompanyReplyTemplates(companyId, perspective));
    return null;
  }
  return store.allCompanyReplyTemplates[perspective];
};

const fetchAllCompanyReplyTemplates = (companyId, perspective) => (dispatch, getState) => {
  if (getState().manage.isGettingAllEmailReplyTemplates === true) {
    return;
  }

  dispatch({ type: actions.GETTING_ALL_EMAIL_REPLY_TEMPLATES });
  data
    .get(`v2/api/sequence/templates/${companyId}/${perspective}`)
    .then(response => {
      dispatch({
        type: actions.GOT_ALL_EMAIL_REPLY_TEMPLATES,
        perspective,
        companyReplyTemplates: response.data
      });
    })
    .catch(e => {
      dispatch({
        type: actions.GET_ALL_EMAIL_REPLY_TEMPLATES_FAILED,
        perspective
      });
      handlePromiseError(
        e,
        "TODO: Getting list of reply templates failed.  Please refresh the page.",
        "reply templates"
      );
    });
};

let DELETE_EMAIL_REPLY_TEMPLATE_PROMISE = null;
const deleteCompanyReplyTemplate = (companyId, perspective, perspectiveEmailTemplateId) => dispatch => {
  DELETE_EMAIL_REPLY_TEMPLATE_PROMISE = data
    .delete(`v1/api/perspective/${perspective}/templates/${perspectiveEmailTemplateId}`)
    .then(response => {
      dispatch(fetchAllCompanyReplyTemplates(companyId, perspective));
    })
    .catch(rejection => {
      dispatch(fetchAllCompanyReplyTemplates(companyId, perspective));
      handlePromiseError(
        rejection,
        "TODO: Deleting the reply template failed.  Please refresh your page and try again.",
        "reply template"
      );
    });

  return DELETE_EMAIL_REPLY_TEMPLATE_PROMISE;
};

const fetchPopulatedTemplate = (companyId, perspective, perspectiveEmailTemplateId, conversationId) => dispatch => {
  dispatch({ type: actions.FETCHING_POPULATED_TEMPLATE });

  return data
    .get(
      `v2/api/sequence/templates/${companyId}/${perspective}/populate/${perspectiveEmailTemplateId}/for/${conversationId}`
    )
    .then(response => {
      dispatch({ type: actions.FETCHED_POPULATED_TEMPLATE });
      return response.data;
    })
    .catch(rejection => {
      dispatch({ type: actions.FETCH_POPULATED_TEMPLATE_FAILED });
      handlePromiseError(
        rejection,
        "TODO: Populating the template variables failed. Please try again.",
        "populated template"
      );
    });
};

const createCompanyDocument = req => dispatch => {
  const { companyId, perspectiveId, upload, metadata } = req;
  const formData = new FormData();

  formData.append("file", upload);
  dispatch({ type: actions.CREATING_COMPANY_DOCUMENT });

  return data
    .put(`v1/api/account/company/${companyId}/${perspectiveId}/documents`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(response => {
      dispatch({ type: actions.CREATED_COMPANY_DOCUMENT });

      const { companyDocumentId } = response.data;
      if (isEmpty(metadata) !== true) {
        dispatch(updateDocumentMetadata(companyId, perspectiveId, companyDocumentId, metadata));
      } else {
        dispatch(fetchCompanyDocuments(companyId, perspectiveId));
      }
      return response.data;
    })
    .catch(e => {
      dispatch({ type: actions.CREATE_COMPANY_DOCUMENT_FAILED });
      handlePromiseError(
        e,
        "TODO: Creating the shared document failed.  Please verify your file and try again.",
        "shared document"
      );
      throw e;
    });
};

const updateDocumentMetadata = (companyId, perspectiveId, companyDocumentId, metadata) => dispatch => {
  dispatch({ type: actions.UPDATING_DOCUMENT_METADATA });

  data
    .post(`v1/api/account/company/${companyId}/${perspectiveId}/documents/${companyDocumentId}/metadata`, metadata, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(response => {
      dispatch({ type: actions.UPDATED_DOCUMENT_METADATA });
      dispatch(fetchCompanyDocuments(companyId, perspectiveId));
    })
    .catch(rejection => {
      dispatch(fetchCompanyDocuments(companyId, perspectiveId));
      dispatch({ type: actions.UPDATE_DOCUMENT_METADATA_FAILED });
      handlePromiseError(
        rejection,
        "TODO: Updating the document metadata failed.  Please verify your file and try again.",
        "document metadata"
      );
    });
};

const updateCompanyDocument = req => dispatch => {
  const { companyId, perspectiveId, companyDocumentId, upload, metadata } = req;
  // isEmpty does not work for a File() object
  if (!upload) {
    dispatch(updateDocumentMetadata(companyId, perspectiveId, companyDocumentId, metadata));
    return;
  }

  const formData = new FormData();

  formData.append("file", upload);

  dispatch({ type: actions.UPDATING_COMPANY_DOCUMENT });
  data
    .post(`v1/api/account/company/${companyId}/${perspectiveId}/documents/${companyDocumentId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(response => {
      dispatch({ type: actions.UPDATED_COMPANY_DOCUMENT });
      dispatch(updateDocumentMetadata(companyId, perspectiveId, companyDocumentId, metadata));
    })
    .catch(rejection => {
      dispatch({ type: actions.UPDATE_COMPANY_DOCUMENT_FAILED });
      handlePromiseError(
        rejection,
        "TODO: Updating the shared document failed. Please verify your file and try again.",
        "company document"
      );
    });
};

const getCompanyDocuments = (companyId, perspectiveId) => (dispatch, getState) => {
  const store = getState().manage;
  if (store.fetchingCompanyDocumentsFailed[perspectiveId] === undefined) {
    dispatch(fetchCompanyDocuments(companyId, perspectiveId));
    return null;
  }
  return store.companyDocuments[perspectiveId];
};

const fetchCompanyDocuments = (companyId, perspectiveId) => (dispatch, getState) => {
  if (getState().manage.isFetchingCompanyDocuments === true) {
    return;
  }

  dispatch({ type: actions.FETCHING_COMPANY_DOCUMENTS });
  data
    .get(`v1/api/account/company/${companyId}/${perspectiveId}/documents`)
    .then(response => {
      dispatch({
        type: actions.FETCHED_COMPANY_DOCUMENTS,
        perspectiveId,
        companyDocuments: response.data
      });
    })
    .catch(e => {
      dispatch({ type: actions.FETCH_COMPANY_DOCUMENTS_FAILED, perspectiveId });
      handlePromiseError(
        e,
        "TODO: Getting list of shared documents failed.  Please refresh the page.",
        "company documents"
      );
    });
};

const deleteCompanyDocument = (companyId, perspectiveId, companyDocumentId) => dispatch => {
  data
    .delete(`v1/api/account/company/${companyId}/${perspectiveId}/documents/${companyDocumentId}`)
    .then(response => {
      dispatch(fetchCompanyDocuments(companyId, perspectiveId));
    })
    .catch(rejection => {
      dispatch(fetchCompanyDocuments(companyId, perspectiveId));
      handlePromiseError(
        rejection,
        "TODO: Deleting the shared document failed.  Please refresh your page and try again.",
        "company document"
      );
    });
};

const fetchCompanyEmailConfigData = companyId => (dispatch, getState) => {
  let state = getState().manage;
  if (state.isGettingCompanyConfigData) return;
  dispatch({ type: actions.GETTING_COMPANY_EMAIL_CONFIG });
  data
    .get(`v1/api/connector/email/config/${companyId}`)
    .then(response => {
      dispatch(getCompanyEmailConfigVerification(companyId));
      dispatch({
        type: actions.GOT_COMPANY_EMAIL_CONFIG,
        companyCurrentConfig: { [companyId]: response.data }
      });
    })
    .catch(e => {
      dispatch({ type: actions.GET_COMPANY_EMAIL_CONFIG_FAILED });
      handlePromiseError(
        e,
        "TODO: Getting your company email configuration failed.  Please refresh the page.",
        "email configuration"
      );
    });
};

const getCompanyEmailConfigData = companyId => (dispatch, getState) => {
  let state = getState().manage;
  return state.companiesEmailConfigData[companyId];
};

const fetchAllEmailConfigTypes = () => (dispatch, getState) => {
  let state = getState().manage;
  if (state.isGettingAllEmailConfigTypes) return;

  dispatch({ type: actions.GETTING_ALL_EMAIL_CONFIG_TYPES });
  data
    .get("v1/api/connector/email/config/types")
    .then(response => {
      dispatch({
        type: actions.GOT_ALL_EMAIL_CONFIG_TYPES,
        allEmailConfigTypes: response.data
      });
    })
    .catch(e => {
      dispatch({ type: actions.GET_ALL_EMAIL_CONFIG_TYPES_FAILED });
      handlePromiseError(
        e,
        "TODO: Getting the company email configurations failed.  Please refresh the page.",
        "email configurations"
      );
    });
};

const getAllEmailConfigTypes = () => (dispatch, getState) => {
  let state = getState().manage;
  return state.allEmailConfigTypes;
};

const fetchCompanyEmailConfigType = companyId => (dispatch, getState) => {
  let state = getState().manage;
  if (state.isGettingCompanyEmailConfigType) return;
  dispatch({ type: actions.GETTING_COMPANY_EMAIL_CONFIG_TYPE });

  Promise.all([data.get(`v1/api/connector/email/config/${companyId}`), data.get("v1/api/connector/email/config/types")])
    .then(response => {
      let currentCompanyEmailConfig = response[0].data;
      currentCompanyEmailConfig.map(config => {
        return { ...config, configurationData: JSON.parse(config.configurationData) };
      });
      let allEmailConfigTypes = response[1].data;
      dispatch({
        type: actions.GOT_COMPANY_EMAIL_CONFIG_TYPE,
        companyEmailConfigType: {
          [companyId]: currentCompanyEmailConfig.reduce((result, c) => {
            result[c.perspectiveId] = find(allEmailConfigTypes, eConfig => {
              return eConfig.emailConfigurationTypeId === c.emailConfigurationTypeId;
            });
            return result;
          }, {})
        }
      });
    })
    .catch(e => {
      dispatch({ type: actions.GET_COMPANY_EMAIL_CONFIG_TYPE_FAILED });
      handlePromiseError(
        e,
        "TODO: Getting the company email configuration failed.  Please refresh the page.",
        "email configuration type"
      );
    });
};

const getCompanyEmailConfigType = companyId => (dispatch, getState) => {
  let state = getState().manage;
  return state.companiesEmailConfigType[companyId];
};

const setCompanyEmailConfig = (companyId, payload) => dispatch => {
  dispatch({ type: actions.SETTING_COMPANY_EMAIL_CONFIG });
  return data
    .post(`v1/api/connector/email/config/${companyId}`, payload, {
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      }
    })
    .then(response => {
      dispatch({ type: actions.SET_COMPANY_EMAIL_CONFIG });
    })
    .catch(e => {
      dispatch({ type: actions.SET_COMPANY_EMAIL_CONFIG_FAILED });
      handlePromiseError(
        e,
        "TODO: Changing the company email configuration failed.  Please try again.",
        "email configuration"
      );
    });
};

const getCompanyEmailConfigVerification = companyId => (dispatch, getState) => {
  dispatch({ type: actions.GETTING_COMPANY_EMAIL_CONFIG_VERIFICATION });
  data
    .post(`v1/api/connector/email/config/${companyId}/verify`)
    .then(response => {
      dispatch({
        type: actions.GOT_COMPANY_EMAIL_CONFIG_VERIFICATION,
        isCurrentEmailConfigVerified: response.data
      });
    })
    .catch(e => {
      dispatch({ type: actions.GET_COMPANY_EMAIL_CONFIG_VERIFICATION_FAILED });
      handlePromiseError(
        e,
        "TODO: The request to verify the company email configuration failed.  Please try again.",
        "email configuration verification"
      );
    });
};

const fetchCompanyWorkflows = companyId => (dispatch, getState) => {
  dispatch({ type: actions.GETTING_COMPANY_WORKFLOWS, companyId });
  data
    .get(`v2/api/ledger/all/${companyId}/reminder?$top=0`)
    .then(response => {
      dispatch({
        type: actions.GOT_COMPANY_WORKFLOWS,
        workflows: response.data.value,
        count: response.data.count,
        companyId
      });
    })
    .catch(e => {
      dispatch({ type: actions.FAILED_GET_COMPANY_WORKFLOWS });
      handlePromiseError(e, "TODO: Could not get company workflows.  Please refresh the page.", "company workflows");
    });
};

const putCompanyWorkflow = (companyId, perspectiveId, name, description, type, resourceName) => (
  dispatch,
  getState
) => {
  dispatch({ type: actions.PUTTING_COMPANY_WORKFLOW });
  let content = { name, description, type, perspectiveId, resourceName };
  return data
    .put(`v1/api/ledger/from/${companyId}/reminder/create`, content)
    .then(response => {
      dispatch({ type: actions.PUT_COMPANY_WORKFLOW });
    })
    .catch(e => {
      dispatch({ type: actions.FAILED_PUT_COMPANY_WORKFLOW });
      handlePromiseError(
        e,
        "TODO: The request to create a company workflow has failed.  Please try again.",
        "company workflow"
      );
    });
};

const deleteCompanyWorkflow = (companyId, ledgerHash) => (dispatch, getState) => {
  dispatch({ type: actions.DELETING_COMPANY_WORKFLOW });
  return data
    .put(`v1/api/ledger/from/${companyId}/on/${ledgerHash}/reminder/delete`, {})
    .then(response => {
      dispatch({ type: actions.DELETED_COMPANY_WORKFLOW });
    })
    .catch(e => {
      dispatch({ type: actions.FAILED_DELETE_COMPANY_WORKFLOW });
      handlePromiseError(
        e,
        "TODO: The request to delete a company workflow has failed.  Please try again.",
        "company workflow"
      );
    });
};

const updateCompanyWorkflow = (companyId, ledgerHash, newJson) => (dispatch, getState) => {
  dispatch({ type: actions.UPDATING_COMPANY_WORKFLOW });
  return data
    .put(`v1/api/ledger/from/${companyId}/on/${ledgerHash}/reminder/update`, newJson)
    .then(response => {
      dispatch({ type: actions.UPDATED_COMPANY_WORKFLOW });
    })
    .catch(e => {
      dispatch({ type: actions.FAILED_UPDATE_COMPANY_WORKFLOW });
      handlePromiseError(
        e,
        "TODO: The request to update a company workflow has failed.  Please try again.",
        "company workflow"
      );
    });
};

const getCommunicationTemplates = companyId => (dispatch, getState) => {
  if (getState().manage.gettingCommunicationTemplates) {
    return false;
  }
  dispatch({ type: actions.GETTING_COMMUNICATION_TEMPLATES });
  return data
    .get(`v2/api/sequence/templates/${companyId}`)
    .then(response => {
      let templates = response.data.map(template => {
        switch (1) {
          case template.emailTemplateStatus:
            template.type = "email";
            break;
          case template.voiceMessageStatus:
            template.type = "voice";
            break;
          case template.smsStatus:
            template.type = "text";
            break;
          default:
            template.type = "inactive";
            break;
        }
        return template;
      });
      dispatch({ type: actions.GOT_COMMUNICATION_TEMPLATES, communicationTemplates: templates });
      return true;
    })
    .catch(e => {
      dispatch({ type: actions.FAILED_GET_COMMUNICATION_TEMPLATES });
      handlePromiseError(e, `TODO: ${Resources.FailedGetCommunicationTemplates}`, "communication templates");
      throw e;
    });
};

const postCommunicationTemplate = (companyId, template, attachmentsToUpload, attachmentsToDelete) => (
  dispatch,
  getState
) => {
  dispatch({ type: actions.POSTING_COMMUNICATION_TEMPLATE });
  return data
    .post(`v2/api/sequence/templates/${companyId}`, template)
    .then(response => {
      let attachmentUploads = attachmentsToUpload.map(attachment =>
        dispatch(
          postCommunicationTemplateAttachment(
            companyId,
            template.emailTemplateKey || response.data.emailTemplateKey,
            attachment
          )
        )
      );
      let attachmentDeletions = attachmentsToDelete.map(attachmentName =>
        dispatch(
          deleteCommunicationTemplateAttachment(
            companyId,
            template.emailTemplateKey || response.data.emailTemplateKey,
            attachmentName
          )
        )
      );
      return Promise.all([attachmentUploads, attachmentDeletions]);
    })
    .then(response => {
      dispatch({ type: actions.POSTED_COMMUNICATION_TEMPLATE, communicationTemplates: response.data });
    })
    .catch(e => {
      dispatch({ type: actions.FAILED_POST_COMMUNICATION_TEMPLATE });
      handlePromiseError(e, `TODO: ${Resources.FailedPostCommunicationTemplate}`, "communication templates");
      throw e;
    });
};

const deleteCommunicationTemplate = (companyId, emailTemplateKey) => (dispatch, getState) => {
  dispatch({ type: actions.DELETING_COMMUNICATION_TEMPLATE });
  return data
    .delete(`v2/api/sequence/templates/${companyId}/${emailTemplateKey}`)
    .then(response => {
      dispatch({ type: actions.DELETED_COMMUNICATION_TEMPLATE, communicationTemplates: response.data });
    })
    .catch(e => {
      dispatch({ type: actions.FAILED_DELETE_COMMUNICATION_TEMPLATE });
      handlePromiseError(e, `TODO: ${Resources.FailedDeleteCommunicationTemplate}`, "communication templates");
    });
};

const getCustomerGroups = companyId => (dispatch, getState) => {
  let manageState = getState().manage;
  let sortBy = manageState.customerGroupsSortBy;
  let sortDirection = manageState.customerGroupsSortDirection;
  if (manageState.gettingCustomerGroups) {
    return false;
  }
  dispatch({ type: actions.GETTING_CUSTOMER_GROUPS });
  return data
    .get(`v2/api/sequence/groups/${companyId}`)
    .then(response => {
      let customerGroups = response.data.map(group => {
        return { ...group, companyCount: group.companies.count };
      });
      customerGroups.sort((a, b) => {
        if (sortDirection === "asc") {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        } else {
          return a[sortBy] > b[sortBy] ? 1 : -1;
        }
      });
      dispatch({ type: actions.GOT_CUSTOMER_GROUPS, customerGroups });
      return customerGroups;
    })
    .catch(e => {
      dispatch({ type: actions.FAILED_GET_CUSTOMER_GROUPS });
      handlePromiseError(e, `TODO: ${Resources.FailedGetAccountGroups}`, "account groups");
      throw e;
    });
};

const postCustomerGroup = (companyId, customerGroupKey, body) => (dispatch, getState) => {
  dispatch({ type: actions.POSTING_CUSTOMER_GROUP });
  return data
    .post(`v2/api/sequence/groups/${companyId}`, body)
    .then(response => {
      dispatch({ type: actions.POSTED_CUSTOMER_GROUP });
      return response.data;
    })
    .catch(e => {
      dispatch({ type: actions.FAILED_POST_CUSTOMER_GROUP });
      handlePromiseError(e, `TODO: ${Resources.FailedSaveAccountGroup}`, "account groups");
      throw e;
    });
};

const deleteCustomerGroup = (companyId, customerGroupKey) => (dispatch, getState) => {
  dispatch({ type: actions.DELETING_CUSTOMER_GROUP });
  return data
    .delete(`v2/api/sequence/groups/${companyId}/${customerGroupKey}`)
    .then(response => {
      dispatch({ type: actions.DELETED_CUSTOMER_GROUP });
      return response.data;
    })
    .catch(e => {
      dispatch({ type: actions.FAILED_DELETE_CUSTOMER_GROUP });
      handlePromiseError(e, `TODO: ${Resources.FailedDeleteAccountGroup}`, "account groups");
      throw e;
    });
};

const getCustomerGroupsFilters = companyId => (dispatch, getState) => {
  dispatch({ type: actions.GETTING_CUSTOMER_GROUPS_FILTERS });
  return data
    .get(`v2/api/sequence/groups/${companyId}/filters`)
    .then(response => {
      dispatch({ type: actions.GOT_CUSTOMER_GROUPS_FILTERS, filters: response.data });
      return response.data;
    })
    .catch(e => {
      dispatch({ type: actions.FAILED_GET_CUSTOMER_GROUPS_FILTERS });
      handlePromiseError(e, `TODO: ${Resources.FailedGetAccountGroupFilters}`, "account groups");
      throw e;
    });
};

const postCustomerGroupsPreview = (companyId, filters) => (dispatch, getState) => {
  dispatch({ type: actions.POSTING_CUSTOMER_GROUPS_PREVIEW });
  return data
    .post(`v2/api/sequence/groups/${companyId}/preview`, { filters })
    .then(response => {
      dispatch({ type: actions.POSTED_CUSTOMER_GROUPS_PREVIEW, customerGroupPreview: response.data });
      return response.data;
    })
    .catch(e => {
      dispatch({ type: actions.FAILED_POST_CUSTOMER_GROUPS_PREVIEW });
      handlePromiseError(e, `TODO: ${Resources.FailedGetAccountGroups}`, "account groups");
      throw e;
    });
};

const updateCustomerGroupsSort = sortBy => (dispatch, getState) => {
  let manageState = getState().manage;
  let customerGroups = [...getState().manage.customerGroups];
  let sortDirection = "asc";

  if (manageState.customerGroupsSortBy === sortBy) {
    if (manageState.customerGroupsSortDirection === "asc") {
      sortDirection = "desc";
    } else {
      sortDirection = "asc";
    }
  }
  customerGroups.sort((a, b) => {
    if (sortDirection === "asc") {
      return a[sortBy] < b[sortBy] ? 1 : -1;
    } else {
      return a[sortBy] > b[sortBy] ? 1 : -1;
    }
  });
  dispatch({ type: actions.UPDATE_CUSTOMER_GROUPS_SORT, sortBy, sortDirection, customerGroups });
};

const getCustomers = options => (dispatch, getState) => {
  dispatch({ type: actions.GETTING_CUSTOMERS });
  let endpoint = `v2/api/customerInfo?$skip=${options.skip}&$top=${options.top}`;
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_CUSTOMERS, customers: response.data.value, count: response.data.count });
      return response.data;
    })
    .catch(e => {
      dispatch({ type: actions.FAILED_GET_CUSTOMERS });
      handlePromiseError(e, `TODO: ${Resources.FailedGetAccountGroups}`, "communication templates");
      throw e;
    });
};

const debouncedCustomersSearchResultsFetch = debounce(
  (endpoint, dispatch, searchTerm) =>
    data
      .get(endpoint)
      .then(res =>
        dispatch({
          type: actions.FETCHED_CUSTOMERS_SEARCH_RESULTS,
          searchResults: res.data.value,
          searchTerm
        })
      )
      .catch(error => {
        dispatch({ type: actions.FETCH_CUSTOMERS_SEARCH_RESULTS_FAILED });
        handlePromiseError(error, "Fetching search results failed.", "search results");
      }),
  400
);

const fetchCustomersSearchResults = (searchTerm, skip) => (dispatch, getState) => {
  if (isEmpty(searchTerm)) {
    return dispatch({ type: actions.CLEAR_CUSTOMERS_SEARCH_RESULTS });
  }

  let endpoint = `v2/api/customerInfo`;

  endpoint = `${endpoint}?$top=${getState().general.pageRowCount}&$filter=custName%20LIKE%20${encodeURI(searchTerm)}`;
  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&$skip=${skip}`;
  }

  dispatch({ type: actions.FETCHING_CUSTOMERS_SEARCH_RESULTS, searchTerm });
  debouncedCustomersSearchResultsFetch(endpoint, dispatch, searchTerm);
};

const getCommunicationTemplateDestinations = () => (dispatch, getState) => {
  dispatch({ type: actions.GETTING_COMMUNICATION_TEMPLATE_DESTINATIONS });
  return data
    .get("v2/api/sequence/templates/destinations")
    .then(response => {
      dispatch({ type: actions.GOT_COMMUNICATION_TEMPLATE_DESTINATIONS, destinations: response.data });
      return response.data;
    })
    .catch(e => {
      dispatch({ type: actions.FAILED_GET_COMMUNICATION_TEMPLATE_DESTINATIONS });
      handlePromiseError(e, `TODO: ${Resources.FailedGetCommunicationTemplateDestinations}`, "communication templates");
    });
};

const getCommunicationTemplateSnippets = () => (dispatch, getState) => {
  dispatch({ type: actions.GETTING_COMMUNICATION_TEMPLATE_SNIPPETS });
  return data
    .get("v2/api/sequence/templates/snippets")
    .then(response => {
      dispatch({ type: actions.GOT_COMMUNICATION_TEMPLATE_SNIPPETS, snippets: response.data });
      return response.data;
    })
    .catch(e => {
      dispatch({ type: actions.FAILED_GET_COMMUNICATION_TEMPLATE_SNIPPETS });
      handlePromiseError(e, `TODO: ${Resources.FailedGetCommunicationTemplateSnippets}`, "communication templates");
    });
};

const postCommunicationTemplateAttachment = (companyId, emailTemplateKey, file) => (dispatch, getState) => {
  let body = new FormData();
  body.append("file", file);
  return data
    .post(`v2/api/sequence/templates/${companyId}/${emailTemplateKey}/attachments`, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(response => {
      return true;
    })
    .catch(e => {
      handlePromiseError(e, "TODO: Adding the attachment failed.  Please try again.", "attachment");
      throw e;
    });
};

const deleteCommunicationTemplateAttachment = (companyId, emailTemplateKey, attachmentName) => (dispatch, getState) => {
  return data
    .delete(`v2/api/sequence/templates/${companyId}/${emailTemplateKey}/attachments/${attachmentName}`)
    .then(response => {
      return true;
    })
    .catch(e => {
      handlePromiseError(e, "TODO: Adding the attachment failed.  Please try again.", "attachment");
      throw e;
    });
};

const getCommunicationTemplateAttachment = (companyId, emailTemplateKey, attachmentName) => (dispatch, getState) => {
  let state = getState().manage;
  if (state.gettingCommunicationTemplateAttachment) {
    return false;
  }
  dispatch({ type: actions.GETTING_COMMUNICATION_TEMPLATE_ATTACHMENT });
  return data
    .get(`v2/api/sequence/templates/${companyId}/${emailTemplateKey}/attachments/${attachmentName}`)
    .then(response => {
      dispatch({ type: actions.GOT_COMMUNICATION_TEMPLATE_ATTACHMENT });
      saveBlobAsDownload(response.data, attachmentName);
      return true;
    })
    .catch(e => {
      dispatch({ type: actions.FAILED_GET_COMMUNICATION_TEMPLATE_ATTACHMENT });
      handlePromiseError(e, "TODO: Adding the attachment failed.  Please try again.", "attachment");
      throw e;
    });
};

const clearManageIsSetData = nameOfStateVar => dispatch => {
  dispatch({ type: actions.CLEAR_MANAGE_IS_SET_DATA, nameOfStateVar });
};

export const getEmailConfigTestStatus = (companyId, accountingGroupId) => (dispatch, getState) => {
  let state = getState().manage;
  return state.accountingGroupEmailConfigTestStatus[accountingGroupId] || "idle";
};

export const getEmailConfigTest = (companyId, accountingGroupId) => (dispatch, getState) => {
  let state = getState().manage;
  return state.accountingGroupEmailConfigTestValue[accountingGroupId] || {};
};

export const testEmailConfig = (companyId, accountingGroupId) => (dispatch, getState) => {
  if (getEmailConfigTestStatus(companyId, accountingGroupId) === "loading") {
    return false;
  }
  dispatch({ type: actions.GETTING_EMAIL_CONFIG_TEST, companyId, accountingGroupId });
  return data
    .get(`v3/api/emailconfig/${companyId}/${accountingGroupId}/status`)
    .then(response => {
      dispatch({ type: actions.GOT_EMAIL_CONFIG_TEST, companyId, accountingGroupId, emailConfigTest: response.data });
      return response.data;
    })
    .catch(e => {
      dispatch({ type: actions.FAILED_GET_EMAIL_CONFIG_TEST, companyId, accountingGroupId });
      handlePromiseError(
        e,
        "TODO: Testing email config failed. Please refresh the page and then try again. If this error persists, contact us.",
        "attachment"
      );
      throw e;
    });
};

export const dispatchToProps = dispatch => ({
  createEmailReplyTemplate: (companyId, perspective, payloadObj) => {
    return dispatch(createEmailReplyTemplate(companyId, perspective, payloadObj));
  },
  fetchAllCompanyReplyTemplates: (companyId, perspective) => {
    dispatch(fetchAllCompanyReplyTemplates(companyId, perspective));
  },
  deleteCompanyReplyTemplate: (companyId, perspective, perspectiveEmailTemplateId) => {
    return dispatch(deleteCompanyReplyTemplate(companyId, perspective, perspectiveEmailTemplateId));
  },
  clearAllReplyTemplates: () => {
    dispatch({ type: actions.CLEAR_ALL_REPLY_TEMPLATES });
  },

  importCompanyAddressBook: (companyId, contactsCSV) => {
    return dispatch(importCompanyAddressBook(companyId, contactsCSV));
  },
  putAddressBookContact: (companyId, contact) => {
    return dispatch(putAddressBookContact(companyId, contact));
  },
  postAddressBookContact: (companyId, addressBookContactId, contact) => {
    return dispatch(postAddressBookContact(companyId, addressBookContactId, contact));
  },
  fetchCompanyAddressBook: (companyId, filter) => {
    return dispatch(fetchCompanyAddressBook(companyId, filter));
  },
  getCompanyAddressBook: companyId => {
    return dispatch(getCompanyAddressBook(companyId));
  },
  deleteCompanyAddressBookContact: (companyId, addressBookContactId) => {
    return dispatch(deleteCompanyAddressBookContact(companyId, addressBookContactId));
  },

  createCompanyDocument: req => {
    return dispatch(createCompanyDocument(req));
  },
  deleteCompanyDocument: (companyId, perspectiveId, companyDocumentId) => {
    dispatch(deleteCompanyDocument(companyId, perspectiveId, companyDocumentId));
  },
  updateCompanyDocument: req => {
    dispatch(updateCompanyDocument(req));
  },
  updateDocumentMetadata: (companyId, perspectiveId, companyDocumentId, metadata) => {
    dispatch(updateDocumentMetadata(companyId, perspectiveId, companyDocumentId, metadata));
  },
  fetchCompanyDocuments: (companyId, perspectiveId) => {
    dispatch(fetchCompanyDocuments(companyId, perspectiveId));
  },
  getCompanyDocuments: (companyId, perspectiveId) => {
    return dispatch(getCompanyDocuments(companyId, perspectiveId));
  },

  getAllCompanyReplyTemplates: (companyId, perspective) => {
    return dispatch(getAllCompanyReplyTemplates(companyId, perspective));
  },
  fetchPopulatedTemplate: (companyId, perspective, perspectiveEmailTemplateId, conversationId) => {
    return dispatch(fetchPopulatedTemplate(companyId, perspective, perspectiveEmailTemplateId, conversationId));
  },

  fetchAllEmailConfigTypes: () => {
    dispatch(fetchAllEmailConfigTypes());
  },
  getAllEmailConfigTypes: () => {
    return dispatch(getAllEmailConfigTypes());
  },

  fetchCompanyEmailConfigData: companyId => {
    dispatch(fetchCompanyEmailConfigData(companyId));
  },
  getCompanyEmailConfigData: companyId => {
    return dispatch(getCompanyEmailConfigData(companyId));
  },

  fetchCompanyEmailConfigType: companyId => {
    dispatch(fetchCompanyEmailConfigType(companyId));
  },
  getCompanyEmailConfigType: companyId => {
    return dispatch(getCompanyEmailConfigType(companyId));
  },

  setCompanyEmailConfig: (companyId, payload) => {
    return dispatch(setCompanyEmailConfig(companyId, payload));
  },
  getCompanyEmailConfigVerification: companyId => {
    dispatch(getCompanyEmailConfigVerification(companyId));
  },
  fetchCompanyWorkflows: companyId => {
    return dispatch(fetchCompanyWorkflows(companyId));
  },
  putCompanyWorkflow: (companyId, perspectiveId, name, description, type, resourceName) => {
    return dispatch(putCompanyWorkflow(companyId, perspectiveId, name, description, type, resourceName));
  },
  deleteCompanyWorkflow: (companyId, ledgerHash) => {
    return dispatch(deleteCompanyWorkflow(companyId, ledgerHash));
  },
  updateCompanyWorkflow: (companyId, ledgerHash, newJson) => {
    return dispatch(updateCompanyWorkflow(companyId, ledgerHash, newJson));
  },
  getCommunicationTemplates: companyId => {
    return dispatch(getCommunicationTemplates(companyId));
  },
  postCommunicationTemplate: (companyId, template, attachmentsToUpload, attachmentsToDelete) => {
    return dispatch(postCommunicationTemplate(companyId, template, attachmentsToUpload, attachmentsToDelete));
  },
  deleteCommunicationTemplate: (companyId, emailTemplateKey) => {
    return dispatch(deleteCommunicationTemplate(companyId, emailTemplateKey));
  },
  getCustomerGroups: companyId => {
    return dispatch(getCustomerGroups(companyId));
  },
  postCustomerGroup: (companyId, customerGroupKey, body) => {
    return dispatch(postCustomerGroup(companyId, customerGroupKey, body));
  },
  deleteCustomerGroup: (companyId, customerGroupKey) => {
    return dispatch(deleteCustomerGroup(companyId, customerGroupKey));
  },
  getCustomerGroupsFilters: companyId => {
    return dispatch(getCustomerGroupsFilters(companyId));
  },
  postCustomerGroupsPreview: (companyId, filters) => {
    return dispatch(postCustomerGroupsPreview(companyId, filters));
  },
  updateCustomerGroupsSort: sortBy => {
    return dispatch(updateCustomerGroupsSort(sortBy));
  },
  getCustomers: options => {
    return dispatch(getCustomers(options));
  },
  fetchCustomersSearchResults: (searchTerm, skip) => {
    return dispatch(fetchCustomersSearchResults(searchTerm, skip));
  },
  displayCustomersSearchResults: () => {
    dispatch({ type: actions.DISPLAY_CUSTOMERS_SEARCH_RESULTS });
  },
  clearCustomersSearchResults: () => {
    dispatch({ type: actions.CLEAR_CUSTOMERS_SEARCH_RESULTS });
  },
  getCommunicationTemplateDestinations: () => {
    return dispatch(getCommunicationTemplateDestinations());
  },
  getCommunicationTemplateSnippets: () => {
    return dispatch(getCommunicationTemplateSnippets());
  },
  postCommunicationTemplateAttachment: (companyId, emailTemplateKey, file) => {
    return dispatch(postCommunicationTemplateAttachment(companyId, emailTemplateKey, file));
  },
  updateCommunicationTemplatesSort(sortBy, sortDirection, sortByType) {
    return dispatch({ type: actions.UPDATE_COMMUNICATION_TEMPLATES_SORT, sortBy, sortDirection, sortByType });
  },
  getCommunicationTemplateAttachment: (companyId, emailTemplateKey, attachmentName) => {
    return dispatch(getCommunicationTemplateAttachment(companyId, emailTemplateKey, attachmentName));
  },
  clearManageIsSetData: nameOfStateVar => {
    dispatch(clearManageIsSetData(nameOfStateVar));
  },
  getEmailConfigTestStatus: (companyId, accountingGroupId) => {
    return dispatch(getEmailConfigTestStatus(companyId, accountingGroupId));
  },
  getEmailConfigTest: (companyId, accountingGroupId) => {
    return dispatch(getEmailConfigTest(companyId, accountingGroupId));
  },
  testEmailConfig: (companyId, accountingGroupId) => {
    return dispatch(testEmailConfig(companyId, accountingGroupId));
  }
});
