import Resources from "../lib/resources";
import data from "../lib/data";

export const actions = {
  CLEAR_DATA: "CLEAR_DATA",
  SELECT_COMPANY: "SELECT_COMPANY",
  SHOW_TOAST: "SHOW_TOAST",
  HIDE_TOAST: "HIDE_TOAST",
  NETWORK_ERROR: "NETWORK_ERROR",
  CLEAR_NETWORK_ERROR: "CLEAR_NETWORK_ERROR",
  UPDATE_PAGE_ROW_COUNT: "UPDATE_PAGE_ROW_COUNT",
  UPDATE_API_STATUS: "UPDATE_API_STATUS"
};

const hideToast = () => (dispatch, getState) => {
  const state = getState().general;
  clearTimeout(state.toastTimeout);
  dispatch({ type: actions.HIDE_TOAST });
};

export const showToast = toastInfo => (dispatch, getState) => {
  clearTimeout(getState().general.toastTimeout);
  const toastTimeout = setTimeout(() => {
    dispatch({ type: actions.HIDE_TOAST });
  }, toastInfo.timeout || 8000);
  dispatch({ type: actions.SHOW_TOAST, toastInfo, toastTimeout });
};

export const networkError = () => dispatch => {
  dispatch(
    showToast({
      // Timeout is longer than poll interval so toast won't disappearbetween polling events
      timeout: 16000,
      text: Resources.NoNetworkMessage,
      type: "orange",
      linkHidden: true
    })
  );
  dispatch({ type: actions.NETWORK_ERROR });
};

export const clearNetworkError = () => dispatch => {
  dispatch(
    showToast({
      timeout: 4000,
      text: Resources.YoureBack,
      type: "green",
      linkHidden: true
    })
  );
  dispatch({ type: actions.CLEAR_NETWORK_ERROR });
  // Force the app to refetch to guarantee state is updated
  dispatch({ type: actions.CLEAR_DATA });
};

export const isAPILive = () => dispatch => {
  return data
    .get(`swagger/index.html`)
    .then(result => {
      return dispatch({ type: actions.UPDATE_API_STATUS, isAPILive: result.status === 200 });
    })
    .catch(error => {
      setTimeout(() => dispatch(isAPILive()), 30000);
      return dispatch({ type: actions.UPDATE_API_STATUS, isAPILive: false });
    });
};

export const selectCompany = companyId => (dispatch, getState) => {
  if (companyId === getState().accounts.selectedCompanyId) {
    return null;
  }
  dispatch({ type: actions.SELECT_COMPANY, companyId });
};

export const dispatchToProps = dispatch => ({
  clearData: () => {
    dispatch({ type: actions.CLEAR_DATA });
  },
  selectCompany: companyId => {
    dispatch(selectCompany(companyId));
  },
  networkError: () => {
    dispatch(networkError());
  },
  clearNetworkError: () => {
    dispatch(clearNetworkError());
  },
  showToast: toastInfo => {
    dispatch(showToast(toastInfo));
  },
  hideToast: () => {
    dispatch(hideToast());
  },
  updatePageRowCount: newCount => {
    dispatch({ type: actions.UPDATE_PAGE_ROW_COUNT, pageRowCount: newCount });
  },
  isAPILive: () => {
    return dispatch(isAPILive());
  }
});
