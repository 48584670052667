import React, { Component } from "react";
import { connect } from "react-redux";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { Switch, Redirect, withRouter, Route, Link, generatePath } from "react-router-dom";

import Resources from "../../../../lib/resources";
import { formatDate, isEmpty, find, updateSelectedRows, openUrlForDownload } from "../../../../lib/utils";

import DepartmentView from "../../../departmentView";
import MainLoader from "../../../mainLoader";
import Card from "../../../library/card";
import ProfileUpdateForm from "../profileUpdate/profileUpdateForm";
import ContactsTable from "../../contactsTable";
import TableData from "../../../library/tableData";
import ViewContentHeader from "../viewContentHeader";
import IconArchive from "../../../library/icons/iconArchive";
import IconPhone from "../../../library/icons/iconPhone";
import IconMapPin from "../../../library/icons/iconMapPin";
import IconLinkedIn from "../../../library/icons/iconLinkedIn";
import IconClipboard from "../../../library/icons/iconClipboard";
import IconMail from "../../../library/icons/iconMail";
import IconEdit from "../../../library/icons/iconEdit";
import IconSend from "../../../library/icons/iconSend";
import IconTrash from "../../../library/icons/iconTrash";
import IconBriefcase from "../../../library/icons/iconBriefcase";
import TextArea from "../../../library/textAreaInput";
import IconAlertCircle from "../../../library/icons/iconAlertCircle";
import AutoCompleteInput from "../../../library/autoCompleteInput";

import Dropdown from "../../../library/dropdown";
import IconAward from "../../../library/icons/iconAward";
import IconLock from "../../../library/icons/iconLock";
import IconUnlock from "../../../library/icons/iconUnlock";
import IconPlusCircle from "../../../library/icons/iconPlusCircle";

import { dispatchToProps as netDP } from "../../../../store/network-actions";
import { dispatchToProps as modDP } from "../../../../store/modal-actions";
import { dispatchToProps as persDP } from "../../../../store/perspectives-actions";
import IconDownload from "../../../library/icons/iconDownload";
import IconFile from "../../../library/icons/iconFile";
import IconAngleDown from "../../../library/icons/iconAngleDown";

const dispatchToProps = dispatch => ({
  ...netDP(dispatch),
  ...modDP(dispatch),
  ...persDP(dispatch)
});

class AccountView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notesEditMode: false,
      selectedAccountKeys: [],
      selectedAccountRows: [],
      selectedEmailAttachmentKeys: [],
      selectedEmailAttachments: [],
      selectedAccountDocuments: [],
      selectedDocumentKeys: [],
      filters: { documents: [] },
      notes: [],
      newNote: "",
      loadedTable: false,
      editMode: false
    };
    this.toggleNotesEditMode = this.toggleNotesEditMode.bind(this);
    this.postNoteChanges = this.postNoteChanges.bind(this);
    this.deleteNote = this.deleteNote.bind(this);
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  componentDidMount() {
    this.refreshData();
    this.refreshTimer = setInterval(() => this.refreshData(), 30000);
  }

  componentWillUnmount() {
    clearInterval(this.refreshTimer);
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.accountId !== match.params.accountId) {
      this.props.fetchAccountProfile(match.params.perspectiveId, match.params.accountId);
    }
  }

  refreshData() {
    const { match } = this.props;
    this.props.fetchAccountProfile(match.params.perspectiveId, match.params.accountId);
    this.props.fetchMyProfile();
    this.props.fetchAccountDocuments(match.params.perspectiveId, match.params.accountId);
    this.props.fetchNotes(this.props.match.params.accountId);
    this.props.fetchEmailAttachments(match.params.perspectiveId, match.params.companyId, match.params.accountId);
  }

  toggleSelectRowAccounts(key, row) {
    let newSelectedKeys = updateSelectedRows(key, this.state.selectedAccountKeys);
    let newSelectedRows = updateSelectedRows(row, this.state.selectedAccountRows);
    this.setState({ selectedAccountKeys: newSelectedKeys, selectedAccountRows: newSelectedRows });
  }

  toggleSelectRowDocuments(key, row) {
    let newSelectedKeys = updateSelectedRows(key, this.state.selectedDocumentKeys);
    let newSelectedRows = updateSelectedRows(row, this.state.selectedAccountDocuments);
    this.setState({ selectedDocumentKeys: newSelectedKeys, selectedAccountDocuments: newSelectedRows });
  }

  toggleSelectEmailAttachments(key, row) {
    let newSelectedKeys = updateSelectedRows(key, this.state.selectedEmailAttachmentKeys);
    let newSelectedRows = updateSelectedRows(row, this.state.selectedEmailAttachments);
    this.setState({ selectedEmailAttachmentKeys: newSelectedKeys, selectedEmailAttachments: newSelectedRows });
  }

  toggleNotesEditMode() {
    this.setState({ notesEditMode: !this.state.notesEditMode });
  }

  toggleEditMode() {
    this.setState({ editMode: false });
    this.props.fetchAccountProfile(this.props.match.params.perspectiveId, this.props.match.params.accountId);
    this.props.fetchAccountList(this.props.match.params.perspectiveId);
  }

  uploadFile() {
    window.document.getElementById("uploadFile").click();
  }

  postNoteChanges() {
    let newNote = this.state.newNote;
    if (newNote !== "") {
      let request = {
        note: newNote
      };
      this.props.postNotes(this.props.match.params.accountId, request).then(response => {
        this.props.fetchNotes(this.props.match.params.accountId);
      });
    }
    this.toggleNotesEditMode();
  }

  deleteNote(note) {
    this.props.deleteNote(this.props.match.params.accountId, note.entityGlobalIdentifierNoteKey).then(response => {
      this.props.fetchNotes(this.props.match.params.accountId);
    });
  }

  renderNotesCard() {
    let accountId = this.props.match.params.accountId;
    return (
      <Card className="card-notes">
        <div className="header">
          <h2>{Resources.Notes.toLocaleUpperCase()}</h2>
          {this.state.notesEditMode ? null : (
            <React.Fragment>
              <div className="header-notes" onClick={this.toggleNotesEditMode}>
                <IconEdit className="icon-button" height={16} />
              </div>
            </React.Fragment>
          )}
        </div>
        {(this.props.gettingNotes && this.props.notes[accountId] === undefined) || this.props.postingNotes ? (
          <div className="notes-loader-container">
            <MainLoader fullScreen={true} />
          </div>
        ) : isEmpty(this.props.notes[accountId] || []) && !this.state.notesEditMode ? (
          <React.Fragment>
            <div className="notes-empty-container">
              <svg viewBox="0 0 268 163" height="75px">
                <g fill="none" fillRule="evenodd">
                  <path fill="#FFF" d="M-586-192H854V832H-586z" />
                  <path fill="#FFF" d="M-586-40H854v871H-586z" />
                  <path
                    fill="#F4F3FF"
                    d="M256.633663 24.0594059c6.2009 0 11.227723 5.0268228 11.227723 11.2277228 0 6.2009001-5.026823 11.2277228-11.227723 11.2277228h-64.158415c6.2009 0 11.227722 5.0268227 11.227722 11.2277228 0 6.2009-5.026822 11.2277227-11.227722 11.2277227h35.287128c6.2009 0 11.227723 5.0268227 11.227723 11.2277228s-5.026823 11.2277228-11.227723 11.2277228h-16.318553c-7.818527 0-14.156694 5.0268227-14.156694 11.2277224 0 4.133934 3.207921 7.876508 9.623762 11.227723 6.2009 0 11.227723 5.026823 11.227723 11.227723 0 6.2009-5.026823 11.227723-11.227723 11.227723H73.7821782c-6.2009 0-11.2277228-5.026823-11.2277228-11.227723 0-6.2009 5.0268228-11.227723 11.2277228-11.227723H11.2277228C5.02682271 113.881188 0 108.854365 0 102.653465c0-6.2008997 5.02682271-11.2277224 11.2277228-11.2277224h64.1584158c6.2009001 0 11.2277228-5.0268227 11.2277228-11.2277228S81.5870387 68.970297 75.3861386 68.970297H35.2871287c-6.2009 0-11.2277228-5.0268227-11.2277228-11.2277227 0-6.2009001 5.0268228-11.2277228 11.2277228-11.2277228h64.1584159c-6.2009001 0-11.2277228-5.0268227-11.2277228-11.2277228 0-6.2009 5.0268227-11.2277228 11.2277228-11.2277228H256.633663zm0 44.9108911c6.2009 0 11.227723 5.0268227 11.227723 11.2277228s-5.026823 11.2277228-11.227723 11.2277228c-6.2009 0-11.227722-5.0268227-11.227722-11.2277228s5.026822-11.2277228 11.227722-11.2277228z"
                  />
                  <path
                    fill="#FFF"
                    stroke="#BEB6FF"
                    strokeLinecap="round"
                    strokeWidth="3.5"
                    d="M171.09746275 22.45544561l14.91985216 108.81705333 1.34044493 10.91704788c.43182842 3.51696024-2.0691646 6.71808531-5.58612484 7.14991373l-93.92827812 11.53294239c-3.51695965.43182835-6.71808492-2.06916464-7.14991334-5.58612488L66.23251038 37.51142872c-.21591419-1.75847992 1.03458241-3.35904247 2.79306224-3.57495665l.03332354-.00409161 7.79264977-.87395535m6.3012086-.70683126l7.35744625-.82531361"
                  />
                  <path
                    fill="#DEDAFF"
                    d="M167.03644893 29.30305224l13.5046427 98.61747678 1.21477826 9.89357497c.39134452 3.18724535-1.84459613 6.08450974-4.99411182 6.47122164l-84.11487626 10.32800815c-3.14951618.38671196-6.01995084-1.88356838-6.41129536-5.07081373L73.01608092 41.87828745c-.13461316-1.0963362.64501741-2.09421783 1.74135362-2.22883099l11.60477308-1.42488697"
                  />
                  <path
                    fill="#FFF"
                    stroke="#BEB6FF"
                    strokeWidth="3.5"
                    d="M176.198643 1.75H99.7113243c-.6213204 0-1.1838204.25183983-1.5909903.65900974-.4071699.40716992-.6590097.96966992-.6590097 1.59099026v126.732673c0 .621321.2518398 1.183821.6590097 1.590991.4071699.407169.9696699.659009 1.5909903.659009h99.4653467c.62132 0 1.18382-.25184 1.59099-.659009.40717-.40717.65901-.96967.65901-1.590991V26.9638138c0-.5969784-.237244-1.1694856-.659502-1.5914824L177.789141 2.40851759C177.367224 1.98686182 176.795139 1.75 176.198643 1.75z"
                  />
                  <path
                    stroke="#BEB6FF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3.5"
                    d="M175.909344 3.85404742V22.4554455c0 2.6575286 2.154353 4.8118812 4.811881 4.8118812h12.72517"
                  />
                  <path
                    stroke="#DEDAFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3.5"
                    d="M114.958849 27.2673267h41.70297m-41.70297 19.2475248h68.970297m-68.970297 20.8514851h68.970297m-68.970297 20.8514852h68.970297m-68.970297 20.8514852h41.70297"
                  />
                </g>
              </svg>
              <p>{Resources.NothingToSeeHereNotes}</p>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {(this.props.notes[accountId] || []).map((note, i) => {
              return (
                <div className="note-container">
                  <div className="note-button-container">
                    <div className="note-body">{note.note}</div>
                    {!this.state.notesEditMode ? null : (
                      <React.Fragment>
                        <div
                          key={i}
                          onClick={() => {
                            this.deleteNote(note);
                            let newNotes = [...this.state.notes];
                            newNotes.splice(i, 1);
                            this.setState({ notes: newNotes });
                          }}
                        >
                          <IconTrash className="icon-button" height={16} />
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                  <p className="note-subtext">
                    {note.userFullName === null ? null : note.userFullName + " "}
                    {formatDate(note.createdDate)}
                  </p>
                </div>
              );
            })}
          </React.Fragment>
        )}

        {this.state.notesEditMode ? (
          <div>
            <div className="note-container">
              <TextArea
                rows={4}
                inputOnChange={e => {
                  let value = e.target.value;
                  this.setState({ newNote: value });
                }}
              />
            </div>
            <div className="notes-button-container">
              <button onClick={this.postNoteChanges} className="button-primary-square">
                {Resources.Save}
              </button>
            </div>
          </div>
        ) : null}
      </Card>
    );
  }

  renderTableHeaderButtons(outside) {
    return (
      <div className={"table-data-card-header-buttons" + (outside ? "__outside" : "")}>
        <button
          className="button-primary"
          onClick={() => {
            outside
              ? this.state.selectedAccountDocuments.forEach(i => {
                  openUrlForDownload(i.documentURL);
                })
              : this.state.selectedEmailAttachments.forEach(i => {
                  let signature = i.downloadURL.split("/")[i.downloadURL.split("/").length - 1];
                  this.props.downloadAttachment(
                    this.props.match.params.perspectiveId,
                    this.props.match.params.companyId,
                    this.props.match.params.accountId,
                    i.attachmentKey,
                    signature
                  );
                });
          }}
        >
          <IconDownload height={22} className="button-primary-icon" />
          {Resources.Download}
        </button>
        <button
          className="button-primary"
          onClick={() => {
            this.props.displayModal("setNewDocumentTypeDialog", {
              selectedDocuments: outside ? this.state.selectedDocumentKeys : this.state.selectedEmailAttachmentKeys,
              documentTypes:
                this.props.perspectiveStore.selectedPerspectiveType !== "vendors"
                  ? [
                      { label: Resources.CreditApplication, value: 1 },
                      { label: Resources.ProofOfInsurance, value: 2 },
                      { label: Resources.TaxExemptionCertificate, value: 3 }
                    ]
                  : [
                      { label: Resources.BankVerification, value: 1 },
                      { label: Resources.WNine, value: 2 },
                      { label: Resources.Other, value: 3 }
                    ],
              perspectiveId: this.props.match.params.perspectiveId,
              accountId: this.props.match.params.accountId,
              companyId: this.props.match.params.companyId,
              attachments: !outside
            });
          }}
        >
          <IconFile height={22} className="button-primary-icon" />
          {Resources.DocumentType}
        </button>
        {/* {outside ? (
          <button
            className="button-primary"
            onClick={() => {
              console.log("document type");
            }}
          >
            <IconLock height={22} className="button-primary-icon" />
            {Resources.MarkAsPrivate}
          </button>
        ) : null} */}
        <button
          className="button-primary"
          onClick={() => {
            this.props.displayModal("deleteDocumentConfirmDialog", {
              selectedDocuments: outside ? this.state.selectedDocumentKeys : this.state.selectedEmailAttachmentKeys,
              perspectiveId: this.props.match.params.perspectiveId,
              accountId: this.props.match.params.accountId,
              companyId: this.props.match.params.companyId,
              attachments: !outside
            });
            this.setState({ selectedDocumentKeys: [], selectedEmailAttachmentKeys: [] });
          }}
        >
          <IconTrash height={22} className="button-primary-icon" />
          {Resources.Delete}
        </button>
      </div>
    );
  }

  render() {
    const {
      match = { params: {} },
      gettingAccountProfiles,
      gettingAccountDocuments,
      gettingAccountContacts
    } = this.props;

    const gettingSelectedAccount = gettingAccountProfiles[match.params.accountId];
    const gettingSelectedAccountDocuments = gettingAccountDocuments[match.params.accountId];
    const gettingSelectedAccountContacts = gettingAccountContacts[match.params.accountId];
    const displayProfileLoadingState =
      isEmpty(this.props.getAccountProfile(match.params.perspectiveId, match.params.accountId) || {}) &&
      gettingSelectedAccount;
    const displayActivitiesLoadingState =
      isEmpty(this.props.getAccountProfile(match.params.perspectiveId, match.params.accountId) || {}) &&
      gettingSelectedAccount;
    const displayContactsLoadingState =
      isEmpty(this.props.getAccountContacts(match.params.perspectiveId, match.params.accountId)) &&
      gettingSelectedAccountContacts;
    const displayDocumentsLoadingState =
      isEmpty(this.props.getAccountDocuments(match.params.perspectiveId, match.params.accountId)) &&
      gettingSelectedAccountDocuments;

    const selectedAccount = this.props.getAccountProfile(match.params.perspectiveId, match.params.accountId) || {};
    const selectedAccountDocuments =
      this.props.getAccountDocuments(match.params.perspectiveId, match.params.accountId) || [];
    const selectedAccountEmailAttachments =
      this.props.getEmailAttachments(match.params.perspectiveId, match.params.companyId, match.params.accountId) || [];
    const selectedAccountContacts =
      this.props.getAccountContacts(match.params.perspectiveId, match.params.accountId) || [];
    const selectedAccountArchivedContacts =
      this.props.getAccountArchivedContacts(match.params.perspectiveId, match.params.accountId) || [];

    const combinedContacts = selectedAccountContacts.concat(selectedAccountArchivedContacts);

    const contactTableHeaderButtons = (
      <div className="table-data-card-header-buttons">
        <button
          className="button-primary"
          onClick={() => {
            this.props.sendContactProfileUpdateRequest([...this.state.selectedAccountKeys]).then(response => {
              this.props.fetchOpenProfileUpdateRequests(match.params.perspectiveId);
              this.props.fetchOpenOnboardingRequests(match.params.perspectiveId);
              this.props.fetchActivityList(match.params.perspectiveId, {
                top: 100,
                skip: 0
              });
            });
            this.props.displayNotification("sendProfileUpdateRequestNotifiation");
          }}
        >
          <IconSend height={22} className="button-primary-icon" />
          {Resources.RequestProfileUpdate}
        </button>
        {/* <button
          disabled
          className="button-primary"
          onClick={() => {
            alert("send message");
          }}
        >
          <IconMessage height={22} className="button-primary-icon" />
          {Resources.SendMessage}
        </button> */}
        <button
          className="button-primary"
          onClick={() => {
            this.state.selectedAccountKeys.map(i => {
              let currentContact = this.props.networkStore.contactsMap[i];
              if (currentContact.isPrivate) {
                this.props
                  .updateContactPrivacy(
                    this.props.match.params.perspectiveId,
                    i,
                    false,
                    this.props.perspectiveStore.selectedPerspectiveType === "vendors" ? "ap" : "ar"
                  )
                  .then(response => {
                    this.props.fetchContactList(this.props.match.params.perspectiveId);
                    this.props.fetchArchivedContactList(match.params.perspectiveId);
                    this.props.fetchAccountArchivedContacts(match.params.perspectiveId, match.params.accountId);
                    this.props.fetchAccountContacts(match.params.perspectiveId, match.params.accountId);
                  });
              }
              return null;
            });
            this.props.displayNotification("contactMarkedPrivateNotification");
          }}
        >
          <IconUnlock height={22} className="button-primary-icon" />
          {Resources.MarkAsPublic}
        </button>
        <button
          className="button-primary"
          onClick={() => {
            this.state.selectedAccountKeys.map(i => {
              let currentContact = this.props.networkStore.contactsMap[i];
              if (!currentContact.isPrivate) {
                this.props
                  .updateContactPrivacy(
                    this.props.match.params.perspectiveId,
                    i,
                    true,
                    this.props.perspectiveStore.selectedPerspectiveType === "vendors" ? "ap" : "ar"
                  )
                  .then(response => {
                    this.props.fetchContactList(this.props.match.params.perspectiveId);
                    this.props.fetchArchivedContactList(match.params.perspectiveId);
                    this.props.fetchAccountArchivedContacts(match.params.perspectiveId, match.params.accountId);
                    this.props.fetchAccountContacts(match.params.perspectiveId, match.params.accountId);
                  });
              }
              return null;
            });
            this.props.displayNotification("contactMarkedPrivateNotification");
          }}
        >
          <IconLock height={22} className="button-primary-icon" />
          {Resources.MarkAsPrivate}
        </button>
        <button
          className="button-primary"
          onClick={() => {
            this.state.selectedAccountKeys.map(i => [
              this.props
                .markContactPrimary(
                  this.props.match.params.perspectiveId,
                  i,
                  this.props.perspectiveStore.selectedPerspectiveType === "vendors" ? "ap" : "ar"
                )
                .then(response => {
                  this.props.fetchContactList(this.props.match.params.perspectiveId);
                  this.props.fetchArchivedContactList(match.params.perspectiveId);
                  this.props.fetchAccountArchivedContacts(match.params.perspectiveId, match.params.accountId);
                  this.props.fetchAccountContacts(match.params.perspectiveId, match.params.accountId);
                })
            ]);
            this.props.displayNotification("markingAsPrimaryNotification");
          }}
        >
          <IconAward height={22} className="button-primary-icon" />
          {Resources.MarkAsPrimary}
        </button>
        <button
          className="button-primary"
          onClick={() => {
            let mode = this.props.perspectiveStore.selectedPerspectiveType === "vendors" ? "ap" : "ar";
            this.state.selectedAccountRows.forEach(i => {
              this.props
                .toggleContactArchived(match.params.perspectiveId, i.entityGlobalKey, !i.isArchived, mode)
                .then(response => {
                  this.props.fetchContactList(match.params.perspectiveId);
                  this.props.fetchArchivedContactList(match.params.perspectiveId);
                  this.props.fetchAccountArchivedContacts(match.params.perspectiveId, match.params.accountId);
                  this.props.fetchAccountContacts(match.params.perspectiveId, match.params.accountId);
                });
            });
            this.setState({ selectedAccountRows: [], selectedAccountKeys: [] });

            this.props.displayNotification("updatedContactStatusNotification");
          }}
        >
          <IconArchive height={20} className="button-primary-icon" />
          {Resources.Archive}
        </button>
      </div>
    );

    let subViews = [
      { subView: "activities", display: Resources.Activities.toLocaleUpperCase() },
      { subView: "profile", display: Resources.Profile.toLocaleUpperCase() },
      { subView: "contacts", display: Resources.Contacts.toLocaleUpperCase() },
      { subView: "documents", display: Resources.Documents.toLocaleUpperCase() }
    ];

    let classifications = [
      { value: 1, classification: "Corporation" },
      { value: 2, classification: "Partnership" },
      { value: 3, classification: "Sole-proprietor" }
    ];

    let docTypeArray =
      this.props.perspectiveStore.selectedPerspectiveType !== "vendors"
        ? [Resources.CreditApplication, Resources.ProofOfInsurance, Resources.TaxExemptionCertificate]
        : [Resources.BankVerification, Resources.WNine, Resources.Other];

    let docTypes =
      this.props.perspectiveStore.selectedPerspectiveType !== "vendors"
        ? {
            1: Resources.CreditApplication,
            2: Resources.ProofOfInsurance,
            3: Resources.TaxExemptionCertificate
          }
        : {
            1: Resources.BankVerification,
            2: Resources.WNine,
            3: Resources.Other
          };

    let documentColumns = [
      {
        type: "rowSelect",
        justify: "center",
        width: "3%"
      },
      {
        header: Resources.DocumentName.toLocaleUpperCase(),
        content: row => {
          return (
            <span
              className="download-link"
              onClick={() => {
                if (row.downloadURL === "" || row.downloadURL === undefined) {
                  openUrlForDownload(row.documentURL);
                } else {
                  let signature = row.downloadURL.split("/")[row.downloadURL.split("/").length - 1];
                  this.props.downloadAttachment(
                    this.props.match.params.perspectiveId,
                    this.props.match.params.companyId,
                    this.props.match.params.accountId,
                    row.attachmentKey,
                    signature
                  );
                }
              }}
            >
              {row.fileName}
            </span>
          );
        },
        width: "30%"
      },
      {
        header: Resources.DocumentType.toLocaleUpperCase(),
        content: row => {
          return (
            <React.Fragment>
              <div className="account-view-documents-dropdown-container">
                {docTypes[row.documentTypeId] !== undefined ? docTypes[row.documentTypeId] : Resources.Other}
                <Dropdown buttonClassName="button-action-icon" buttonContent={<IconAngleDown height="7" />}>
                  <div className="account-view-documents-dropdown">
                    {docTypeArray.map(i => {
                      return (
                        <span
                          onClick={() => {
                            let body = {
                              fileKeys: [row.arDocumentKey || row.apDocumentKey || row.attachmentKey],
                              newType: docTypeArray.indexOf(i) + 1
                            };
                            row.attachmentKey === undefined
                              ? this.props
                                  .updateDocumentType(
                                    this.props.match.params.perspectiveId,
                                    this.props.match.params.accountId,
                                    body
                                  )
                                  .then(response => {
                                    this.props.fetchAccountDocuments(
                                      match.params.perspectiveId,
                                      match.params.accountId
                                    );
                                  })
                              : this.props
                                  .updateEmailAttachmentType(
                                    this.props.match.params.perspectiveId,
                                    this.props.match.params.companyId,
                                    body
                                  )
                                  .then(response => {
                                    this.props.fetchEmailAttachments(
                                      this.props.match.params.perspectiveId,
                                      this.props.match.params.companyId,
                                      this.props.match.params.accountId
                                    );
                                  });
                          }}
                        >
                          {i}
                        </span>
                      );
                    })}
                  </div>
                </Dropdown>
              </div>
            </React.Fragment>
          );
        },
        width: "30%"
      },
      {
        header: Resources.Owner.toLocaleUpperCase(),
        content: row => row.owner,
        width: "25%"
      },
      {
        header: Resources.LastUpdated.toLocaleUpperCase(),
        content: row => formatDate(row.updateDate),
        width: "15%"
      },
      { content: row => null, width: "0%" }
    ];

    const requestProfileUpdateButton = (
      <React.Fragment>
        <button
          className="button-primary bg-white"
          disabled={selectedAccount.emailAddr === "" || selectedAccount.emailAddr === null}
          onClick={() => {
            this.props.sendAccountProfileUpdateRequest([selectedAccount.entityGlobalKey]).then(response => {
              this.props.fetchOpenProfileUpdateRequests(match.params.perspectiveId);
              this.props.fetchOpenOnboardingRequests(match.params.perspectiveId);
              this.props.fetchActivityList(match.params.perspectiveId, {
                top: 100,
                skip: 0
              });
            });
            this.props.displayNotification("sendProfileUpdateRequestNotifiation");
          }}
        >
          <IconSend height="20" className="button-primary-icon" />
          {Resources.RequestProfileUpdate}
        </button>
      </React.Fragment>
    );

    const editProfileButton = (
      <React.Fragment>
        <button
          className="button-primary"
          onClick={() => {
            this.setState({ editMode: true });
          }}
        >
          <IconEdit height="20" className="button-primary-icon" />
          {Resources.EditProfile}
        </button>
      </React.Fragment>
    );

    if (this.props.networkStore.failedGetCustomerAccounts[this.props.match.params.accountId]) {
      return (
        <Redirect
          to={generatePath(`/v2/:companyId/:perspectiveId/network/accounts`, {
            companyId: this.props.match.params.companyId,
            perspectiveId: this.props.match.params.perspectiveId
          })}
        />
      );
    }

    return (
      <Switch>
        <Redirect
          exact
          from={`/v2/:companyId/:perspectiveId/network/accounts/:accountId`}
          to={match.url + "/activities"}
        />
        <Redirect
          from={`/v2/:companyId/:perspectiveId/network/accountArchive/:accountId`}
          to={generatePath("/v2/:companyId/:perspectiveId/network/accounts/:accountId/activities", {
            companyId: this.props.match.params.companyId,
            perspectiveId: this.props.match.params.perspectiveId,
            accountId: this.props.match.params.accountId
          })}
        />
        <Route path={`/v2/:companyId/:perspectiveId/network/accounts/:accountId/:subView`}>
          {!displayActivitiesLoadingState &&
            !isEmpty(
              selectedAccount.displayName ||
                selectedAccount.custName ||
                selectedAccount.vendorName ||
                selectedAccount.companyName
            ) && (
              <ViewContentHeader
                links={subViews}
                accountViewing={selectedAccount}
                onClickBackRoute={generatePath(
                  `/v2/:companyId/:perspectiveId/network/${selectedAccount.isArchived ? "accountArchive" : "accounts"}`,
                  this.props.match.params
                )}
              />
            )}
          <div className="view-content">
            <Switch>
              <Route path={`/v2/:companyId/:perspectiveId/network/accounts/:selectedContextGroupId/activities`}>
                <Route path="/v2/:companyId/:perspectiveId/network/:subView(accounts)/:selectedContextGroupId/:view(activities|documents|statements|profile|settings)/:selectedItemId?/:activityType?">
                  {displayActivitiesLoadingState ? (
                    <MainLoader fullScreen className="pt-5" />
                  ) : (
                    <DepartmentView noHeader></DepartmentView>
                  )}
                </Route>
              </Route>
              <Route exact path={`/v2/:companyId/:perspectiveId/network/accounts/:accountId/profile`}>
                {this.state.editMode ? (
                  <ProfileUpdateForm
                    edit
                    mode={this.props.perspectiveStore.selectedPerspectiveType === "vendors" ? "ar" : "ap"}
                    toggleEditMode={this.toggleEditMode}
                  />
                ) : displayProfileLoadingState ? (
                  <MainLoader fullScreen className="pt-5" />
                ) : (
                  <React.Fragment>
                    <div className="action-buttons">
                      {requestProfileUpdateButton}
                      {editProfileButton}
                    </div>
                    <div className="profile-container">
                      <Card className="account-profile__card" data-test-id="contact-profile">
                        <div
                          className="account-profile__card__last-updated"
                          data-test-id="account-profile__update-date"
                        >
                          {Resources.UpdatedDate(formatDate(selectedAccount.profileUpdateDate))}
                        </div>
                        <div className="">
                          <div className="account-profile-item" data-test-id="account-profile__email">
                            <div className="account-profile-item__icon" data-test-id="account-profile__email--icon">
                              <IconMail height={18} />
                            </div>
                            <div
                              className="account-profile-item__content"
                              data-test-id="account-profile__email--content"
                            >
                              {selectedAccount.emailAddr === "" || selectedAccount.emailAddr === null ? (
                                <div
                                  onClick={() => this.setState({ editMode: true })}
                                  className="missing-email-address-container"
                                >
                                  <IconAlertCircle height={20} />
                                  <span>{Resources.MissingEmailAddress}</span>
                                </div>
                              ) : (
                                <React.Fragment>
                                  <Link
                                    to={generatePath(
                                      `/v2/:companyId/:perspectiveId/network/:subView(accounts)/:selectedContextGroupId/:view(activities|documents|statements|profile|settings)/:selectedItemId?/:activityType?`,
                                      {
                                        ...match.params,
                                        view: "activities",
                                        selectedContextGroupId: selectedAccount.entityGlobalKey || "mine",
                                        selectedItemId: "new",
                                        activityType: "conversation",
                                        subView: "accounts"
                                      }
                                    )}
                                  >
                                    {selectedAccount.emailAddr}
                                  </Link>
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                          {!isEmpty(selectedAccount.phone) && (
                            <div className="account-profile-item" data-test-id="account-profile__phone">
                              <div className="account-profile-item__icon" data-test-id="account-profile__phone--icon">
                                <IconPhone height={22} />
                              </div>
                              <div
                                className="account-profile-item__content"
                                data-test-id="account-profile__phone--content"
                              >
                                {selectedAccount.phone}
                              </div>
                            </div>
                          )}
                          {!isEmpty(selectedAccount.fax) && (
                            <div className="account-profile-item" data-test-id="account-profile__fax">
                              <div className="account-profile-item__icon" data-test-id="account-profile__fax--icon">
                                <IconArchive height={16} />
                              </div>
                              <div
                                className="account-profile-item__content"
                                data-test-id="account-profile__fax--content"
                              >
                                {selectedAccount.fax}
                              </div>
                            </div>
                          )}
                          {!isEmpty(
                            [
                              selectedAccount.mailToAddrLn1,
                              selectedAccount.mailToCity,
                              selectedAccount.mailToState,
                              selectedAccount.mailToPostalcode,
                              selectedAccount.mailToCountry
                            ].filter(value => !isEmpty(value))
                          ) && (
                            <div className="account-profile-item" data-test-id="account-profile__mail-to">
                              <div className="account-profile-item__icon" data-test-id="account-profile__mail-to--icon">
                                <IconMapPin height={22} />
                              </div>
                              <div
                                className="account-profile-item__content"
                                data-test-id="account-profile__mail-to--content"
                              >
                                <div data-test-id="account-profile__mail-to__addrLine1">
                                  {selectedAccount.mailToAddrLn1}
                                </div>
                                <div data-test-id="account-profile__mail-to__city-state-postal">
                                  {selectedAccount.mailToCity}, {selectedAccount.mailToState}{" "}
                                  {selectedAccount.mailToPostalcode}
                                </div>
                                <div data-test-id="account-profile__mail-to__country">
                                  {selectedAccount.mailToCountry}
                                </div>
                              </div>
                            </div>
                          )}
                          {!isEmpty(
                            [
                              selectedAccount.billToAddrLn1,
                              selectedAccount.billToCity,
                              selectedAccount.billToState,
                              selectedAccount.billToPostalcode,
                              selectedAccount.billToCountry
                            ].filter(value => !isEmpty(value))
                          ) && (
                            <div className="account-profile-item" data-test-id="account-profile__bill-to">
                              <div className="account-profile-item__icon" data-test-id="account-profile__bill-to--icon">
                                <IconMapPin height={22} />
                              </div>
                              <div
                                className="account-profile-item__content"
                                data-test-id="account-profile__bill-to--content"
                              >
                                {isEmpty(
                                  [
                                    selectedAccount.shipToAddrLn1,
                                    selectedAccount.shipToCity,
                                    selectedAccount.shipToState,
                                    selectedAccount.shipToPostalcode,
                                    selectedAccount.shipToCountry
                                  ].filter(value => !isEmpty(value))
                                ) ? null : (
                                  <div className="account-profile-item__header">{Resources.BillTo}</div>
                                )}
                                <div data-test-id="account-profile__bill-to__addrLine1">
                                  {selectedAccount.billToAddrLn1}
                                </div>
                                <div data-test-id="account-profile__bill-to__city-state-postal">
                                  {selectedAccount.billToCity}, {selectedAccount.billToState}{" "}
                                  {selectedAccount.billToPostalcode}
                                </div>
                                <div data-test-id="account-profile__bill-to__country">
                                  {selectedAccount.billToCountry}
                                </div>
                              </div>
                            </div>
                          )}
                          {!isEmpty(
                            [
                              selectedAccount.shipToAddrLn1,
                              selectedAccount.shipToCity,
                              selectedAccount.shipToState,
                              selectedAccount.shipToPostalcode,
                              selectedAccount.shipToCountry
                            ].filter(value => !isEmpty(value))
                          ) && (
                            <div className="account-profile-item" data-test-id="account-profile__ship-to">
                              <div className="account-profile-item__icon" data-test-id="account-profile__ship-to--icon">
                                {!isEmpty(
                                  [
                                    selectedAccount.billToAddrLn1,
                                    selectedAccount.billToCity,
                                    selectedAccount.billToState,
                                    selectedAccount.billToPostalcode,
                                    selectedAccount.billToCountry
                                  ].filter(value => !isEmpty(value))
                                ) ? null : (
                                  <IconMapPin height={22} />
                                )}
                              </div>
                              <div
                                className="account-profile-item__content"
                                data-test-id="account-profile__ship-to--content"
                              >
                                {isEmpty(
                                  [
                                    selectedAccount.billToAddrLn1,
                                    selectedAccount.billToCity,
                                    selectedAccount.billToState,
                                    selectedAccount.billToPostalcode,
                                    selectedAccount.billToCountry
                                  ].filter(value => !isEmpty(value))
                                ) ? null : (
                                  <div className="account-profile-item__header">{Resources.ShipTo}</div>
                                )}
                                <div data-test-id="account-profile__ship-to__addrLine1">
                                  {selectedAccount.shipToAddrLn1}
                                </div>
                                <div data-test-id="account-profile__ship-to__city-state-postal">
                                  {selectedAccount.shipToCity}, {selectedAccount.shipToState}{" "}
                                  {selectedAccount.shipToPostalcode}
                                </div>
                                <div data-test-id="account-profile__ship-to__country">
                                  {selectedAccount.shipToCountry}
                                </div>
                              </div>
                            </div>
                          )}
                          {((selectedAccount.entityClassificationId !== null &&
                            selectedAccount.entityClassificationId !== undefined &&
                            selectedAccount.entityClassificationId !== 0) ||
                            !isEmpty(selectedAccount.taxId) ||
                            !isEmpty(selectedAccount.stateOfIncoporation) ||
                            !isEmpty(selectedAccount.dateOfIncoporation)) && (
                            <div className="account-profile-item" data-test-id="account-profile__ein">
                              <div className="account-profile-item__icon" data-test-id="account-profile__ein--icon">
                                <IconBriefcase height={20} />
                              </div>
                              <div
                                className="account-profile-item__content space-divs"
                                data-test-id="account-profile__ein--content"
                              >
                                {!isEmpty(selectedAccount.taxId) && (
                                  <div>
                                    {Resources.ein}: {selectedAccount.taxId}
                                  </div>
                                )}
                                {selectedAccount.entityClassificationId !== null &&
                                  selectedAccount.entityClassificationId !== undefined &&
                                  selectedAccount.entityClassificationId !== 0 && (
                                    <div data-test-id="account-profile__ein__classification">
                                      {Resources.EntityClassification}:{" "}
                                      {
                                        (classifications[selectedAccount.entityClassificationId - 1] || {})
                                          .classification
                                      }
                                    </div>
                                  )}
                                {!isEmpty(selectedAccount.stateOfIncoporation) ? (
                                  !isEmpty(selectedAccount.dateOfIncoporation) ? (
                                    <div data-test-id="account-profile__ein__incorporation--date-state">
                                      {Resources.IncorporatedInStateDate(
                                        selectedAccount.stateOfIncoporation,
                                        selectedAccount.dateOfIncoporation
                                      )}
                                    </div>
                                  ) : (
                                    <div data-test-id="account-profile__ein__incorporation--state">
                                      {Resources.IncorporatedInState(selectedAccount.stateOfIncoporation)}
                                    </div>
                                  )
                                ) : (
                                  !isEmpty(selectedAccount.dateOfIncoporation) && (
                                    <div data-test-id="account-profile__ein__incorporation--date">
                                      {Resources.IncorporatedDate(selectedAccount.dateOfIncoporation)}
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                          {!isEmpty(selectedAccount.linkedInProfile) && (
                            <div className="account-profile-item" data-test-id="account-profile__linkedIn">
                              <div
                                className="account-profile-item__icon"
                                data-test-id="account-profile__linkedIn--icon"
                              >
                                <IconLinkedIn height={19} />
                              </div>
                              <div
                                className="account-profile-item__content"
                                data-test-id="account-profile__linkedIn--content"
                              >
                                <a rel="norefferer noopener" href={selectedAccount.linkedInProfile}>
                                  {selectedAccount.linkedInProfile}
                                </a>
                              </div>
                            </div>
                          )}
                          {!isEmpty(selectedAccount.notes) && (
                            <div className="account-profile-item" data-test-id="account-profile__notes">
                              <div className="account-profile-item__icon" data-test-id="account-profile__notes--icon">
                                <IconClipboard height={20} />
                              </div>
                              <div
                                className="account-profile-item__content"
                                data-test-id="account-profile__notes--content"
                              >
                                {selectedAccount.notes}
                              </div>
                            </div>
                          )}
                        </div>
                      </Card>
                      {this.renderNotesCard()}
                    </div>
                  </React.Fragment>
                )}
              </Route>
              <Route exact path={`/v2/:companyId/:perspectiveId/network/accounts/:accountId/contacts`}>
                {displayContactsLoadingState ? (
                  <MainLoader fullScreen className="pt-5" />
                ) : isEmpty(combinedContacts) ? (
                  <div className="account-contacts-view-empty">
                    <svg viewBox="0 0 257 119" height="150px">
                      <g fill="none" fillRule="evenodd">
                        <path fill="#F8FBFC" d="M-592-449H848v835H-592z" />
                        <rect
                          width="1025"
                          height="399"
                          x=".5"
                          y=".5"
                          stroke="#DBE0E3"
                          rx="2"
                          transform="translate(-385 -54)"
                        />
                        <path
                          fill="#F4F3FF"
                          d="M141.583942 13.8733638c4.599685 0 8.328467 3.7287817 8.328467 8.3284671 0 4.5996854-3.728782 8.3284672-8.328467 8.3284672h76.145985c4.599685 0 8.328467 3.7287817 8.328467 8.3284671 0 4.5996854-3.728782 8.3284672-8.328467 8.3284672h26.175182c4.599686 0 8.328468 3.7287817 8.328468 8.3284671 0 4.5996854-3.728782 8.3284672-8.328468 8.3284672H221.29927c-4.599685 0-8.328467 3.7287818-8.328467 8.3284672s3.728782 8.3284671 8.328467 8.3284671h7.138686c4.599686 0 8.328467 3.728782 8.328467 8.328467 0 4.599686-3.728781 8.328467-8.328467 8.328467H54.729927c-4.5996854 0-8.3284671-3.728781-8.3284671-8.328467 0-4.599685 3.7287817-8.328467 8.3284671-8.328467H8.32846715C3.72878176 80.501101 0 76.7723193 0 72.1726339c0-4.5996854 3.72878176-8.3284672 8.32846715-8.3284672H55.919708c4.5996854 0 8.3284672-3.7287818 8.3284672-8.3284672s-3.7287818-8.3284671-8.3284672-8.3284671H26.1751825c-4.5996854 0-8.3284672-3.7287818-8.3284672-8.3284672s3.7287818-8.3284671 8.3284672-8.3284671h47.5912409c-4.5996854 0-8.3284672-3.7287818-8.3284672-8.3284672s3.7287818-8.3284671 8.3284672-8.3284671h67.8175186zM248.664234 80.501101c4.599685 0 8.328467 3.728782 8.328467 8.328467 0 4.599686-3.728782 8.328467-8.328467 8.328467-4.599686 0-8.328468-3.728781-8.328468-8.328467 0-4.599685 3.728782-8.328467 8.328468-8.328467z"
                        />
                        <path
                          fill="#FFF"
                          stroke="#BEB6FF"
                          strokeLinecap="round"
                          strokeWidth="3.5"
                          d="M166.17279745 107.02239646c3.6146549-2.32609718 6.82845862-5.22822167 8.91928923-7.5711839m3.37400025-3.8935014h0c1.72940125-2.11828131 3.28399227-4.33354406 4.66346654-6.62632468 3.03399566-5.04271066 5.22090195-10.46040722 6.55746555-16.04604294 1.35174522-5.6490759 1.8337225-11.46992934 1.4425674-17.24837902-.34131943-5.04225069-1.34746282-10.05221548-3.0206638-14.88758666-1.65998973-4.79719214-3.97654523-9.4225385-6.95184827-13.73707933-1.77293767-2.57096828-3.77978284-5.03157578-6.020997-7.35242098-3.29049166-3.40740387-6.89969389-6.32043838-10.7345626-8.73731806-4.12044186-2.59685753-8.50141282-4.62091257-13.02749639-6.0699501-5.66830714-1.81472358-11.56421331-2.72757801-17.46101542-2.7342107-4.58806691-.005161-9.17667682.53828492-13.65904813 1.63238808-5.04625316 1.2317411-9.95785677 3.16139904-14.58244747 5.79189904-4.06529863 2.31237104-7.9088086 5.1663168-11.42703122 8.56382489-4.42130771 4.26960722-8.00263811 9.08257522-10.74076418 14.23348261-2.68074757 5.0429675-4.55325928 10.40985856-5.61450817 15.90789845-1.09336635 5.66442908-1.32562374 11.46806492-.6934609 17.20009516.8193584 7.42939585 3.09087815 14.73849969 6.8217692 21.46829165 2.38728898 4.30619872 5.3720955 8.37520694 8.95630846 12.08676811 5.68998526 5.89215223 12.3329791 10.30604053 19.44788279 13.23242635 8.65236331 3.55874845 18.0026224 4.91766709 27.18554749 4.06014082 7.70706478-.71970724 15.29626083-3.0006662 22.25607514-6.85270006"
                        />
                        <g
                          stroke="#BEB6FF"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3.5"
                          transform="translate(105 28.193772)"
                        >
                          <path d="M59.1818182 62v-7.2020202c0-7.9551319-6.6241507-14.4040404-14.7954546-14.4040404H14.7954545C6.62415063 40.3939394 0 46.8428479 0 54.7979798V62" />
                          <circle cx="29.1212121" cy="14.0909091" r="14.0909091" />
                        </g>
                      </g>
                    </svg>
                    <h2>{Resources.NoContactsHaveBeenAddedYet}</h2>
                    <p>{Resources.RequestProfileUpdateToHaveAccountAddContactsToProfile}</p>
                    {requestProfileUpdateButton}
                  </div>
                ) : (
                  <React.Fragment>
                    <div className="add-contact-button-container">
                      {!isEmpty(this.state.selectedAccountKeys) ? (
                        contactTableHeaderButtons
                      ) : (
                        <button
                          onClick={() => {
                            this.props.displayFlyout("addContactFlyout", {
                              perspectiveId: this.props.match.params.perspectiveId,
                              companyId: this.props.match.params.companyId,
                              accountId: this.props.match.params.accountId
                            });
                          }}
                          className="button-primary"
                        >
                          <IconPlusCircle className="icon" height={18} />
                          {Resources.AddContact}
                        </button>
                      )}
                    </div>
                    <Card className="card-table">
                      <ContactsTable
                        name="account-view__contacts-table"
                        data={combinedContacts}
                        noCompany
                        loading={this.props.networkStore.gettingNetworkContactList}
                        selectedKeys={this.state.selectedAccountKeys}
                        rowKey="entityGlobalKey"
                        onRowSelectToggle={key =>
                          this.toggleSelectRowAccounts(
                            key,
                            (Array.isArray(key) ? [...key] : [key]).map(k =>
                              find(combinedContacts, row => row.entityGlobalKey === k)
                            )
                          )
                        }
                      />
                    </Card>
                  </React.Fragment>
                )}
              </Route>
              <Route exact path={`/v2/:companyId/:perspectiveId/network/accounts/:accountId/documents`}>
                {displayDocumentsLoadingState ? (
                  <MainLoader fullScreen className="pt-5"></MainLoader>
                ) : isEmpty(selectedAccountDocuments) && isEmpty(selectedAccountEmailAttachments) ? (
                  <div className="account-documents-view-empty">
                    <svg viewBox="0 0 268 163" height="150px">
                      <g fill="none" fillRule="evenodd">
                        <path fill="#FFF" d="M-586-192H854V832H-586z" />
                        <path fill="#F8FBFC" d="M-586-40H854v871H-586z" />
                        <path
                          fill="#F4F3FF"
                          d="M256.633663 24.0594059c6.2009 0 11.227723 5.0268228 11.227723 11.2277228 0 6.2009001-5.026823 11.2277228-11.227723 11.2277228h-64.158415c6.2009 0 11.227722 5.0268227 11.227722 11.2277228 0 6.2009-5.026822 11.2277227-11.227722 11.2277227h35.287128c6.2009 0 11.227723 5.0268227 11.227723 11.2277228s-5.026823 11.2277228-11.227723 11.2277228h-16.318553c-7.818527 0-14.156694 5.0268227-14.156694 11.2277224 0 4.133934 3.207921 7.876508 9.623762 11.227723 6.2009 0 11.227723 5.026823 11.227723 11.227723 0 6.2009-5.026823 11.227723-11.227723 11.227723H73.7821782c-6.2009 0-11.2277228-5.026823-11.2277228-11.227723 0-6.2009 5.0268228-11.227723 11.2277228-11.227723H11.2277228C5.02682271 113.881188 0 108.854365 0 102.653465c0-6.2008997 5.02682271-11.2277224 11.2277228-11.2277224h64.1584158c6.2009001 0 11.2277228-5.0268227 11.2277228-11.2277228S81.5870387 68.970297 75.3861386 68.970297H35.2871287c-6.2009 0-11.2277228-5.0268227-11.2277228-11.2277227 0-6.2009001 5.0268228-11.2277228 11.2277228-11.2277228h64.1584159c-6.2009001 0-11.2277228-5.0268227-11.2277228-11.2277228 0-6.2009 5.0268227-11.2277228 11.2277228-11.2277228H256.633663zm0 44.9108911c6.2009 0 11.227723 5.0268227 11.227723 11.2277228s-5.026823 11.2277228-11.227723 11.2277228c-6.2009 0-11.227722-5.0268227-11.227722-11.2277228s5.026822-11.2277228 11.227722-11.2277228z"
                        />
                        <path
                          fill="#FFF"
                          stroke="#BEB6FF"
                          strokeLinecap="round"
                          strokeWidth="3.5"
                          d="M171.09746275 22.45544561l14.91985216 108.81705333 1.34044493 10.91704788c.43182842 3.51696024-2.0691646 6.71808531-5.58612484 7.14991373l-93.92827812 11.53294239c-3.51695965.43182835-6.71808492-2.06916464-7.14991334-5.58612488L66.23251038 37.51142872c-.21591419-1.75847992 1.03458241-3.35904247 2.79306224-3.57495665l.03332354-.00409161 7.79264977-.87395535m6.3012086-.70683126l7.35744625-.82531361"
                        />
                        <path
                          fill="#DEDAFF"
                          d="M167.03644893 29.30305224l13.5046427 98.61747678 1.21477826 9.89357497c.39134452 3.18724535-1.84459613 6.08450974-4.99411182 6.47122164l-84.11487626 10.32800815c-3.14951618.38671196-6.01995084-1.88356838-6.41129536-5.07081373L73.01608092 41.87828745c-.13461316-1.0963362.64501741-2.09421783 1.74135362-2.22883099l11.60477308-1.42488697"
                        />
                        <path
                          fill="#FFF"
                          stroke="#BEB6FF"
                          strokeWidth="3.5"
                          d="M176.198643 1.75H99.7113243c-.6213204 0-1.1838204.25183983-1.5909903.65900974-.4071699.40716992-.6590097.96966992-.6590097 1.59099026v126.732673c0 .621321.2518398 1.183821.6590097 1.590991.4071699.407169.9696699.659009 1.5909903.659009h99.4653467c.62132 0 1.18382-.25184 1.59099-.659009.40717-.40717.65901-.96967.65901-1.590991V26.9638138c0-.5969784-.237244-1.1694856-.659502-1.5914824L177.789141 2.40851759C177.367224 1.98686182 176.795139 1.75 176.198643 1.75z"
                        />
                        <path
                          stroke="#BEB6FF"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3.5"
                          d="M175.909344 3.85404742V22.4554455c0 2.6575286 2.154353 4.8118812 4.811881 4.8118812h12.72517"
                        />
                        <path
                          stroke="#DEDAFF"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3.5"
                          d="M114.958849 27.2673267h41.70297m-41.70297 19.2475248h68.970297m-68.970297 20.8514851h68.970297m-68.970297 20.8514852h68.970297m-68.970297 20.8514852h41.70297"
                        />
                      </g>
                    </svg>
                    <h2>{Resources.NoDocumentsHaveBeenAddedYet}</h2>
                    <p>{Resources.RequestProfileUpdateToHaveAccountAddDocumentsToProfile}</p>
                    {requestProfileUpdateButton}
                  </div>
                ) : (
                  <div className="profile-share-view-documents-container__account-view">
                    {!isEmpty(this.state.selectedDocumentKeys) ? (
                      this.renderTableHeaderButtons(true)
                    ) : (
                      <div className="add-contact-button-container">
                        <button onClick={() => this.uploadFile()} className="button-primary">
                          <IconPlusCircle className="icon" height={18} />
                          {Resources.AddDocument}
                        </button>
                        <input
                          className="hidden-input"
                          type="file"
                          id="uploadFile"
                          style={{ display: "none" }}
                          onChange={e => {
                            let mode = this.props.perspectiveStore.selectedPerspectiveType === "vendors" ? "ap" : "ar";
                            this.props
                              .addAccountingGroupDocument(
                                match.params.perspectiveId,
                                match.params.accountId,
                                mode,
                                e.target.files[0]
                              )
                              .then(() => {
                                this.props.fetchAccountDocuments(match.params.perspectiveId, match.params.accountId);
                              });
                            this.props.displayNotification("uploadingDocumentNotification");
                          }}
                        />
                      </div>
                    )}
                    <Card className="card-table">
                      <React.Fragment>
                        <TableData
                          data={this.props.networkStore.accountDocumentsMap[this.props.match.params.accountId]}
                          columns={documentColumns}
                          pagination
                          noHover
                          loading={this.props.networkStore.gettingAccountDocuments[match.params.accountId]}
                          selectedKeys={this.state.selectedDocumentKeys}
                          rowKey={
                            this.props.perspectiveStore.selectedPerspectiveType === "vendors"
                              ? "arDocumentKey"
                              : "apDocumentKey"
                          }
                          onRowSelectToggle={key =>
                            this.toggleSelectRowDocuments(
                              key,
                              (Array.isArray(key) ? [...key] : [key]).map(k =>
                                find(
                                  this.props.networkStore.accountDocumentsMap[this.props.match.params.accountId],
                                  row =>
                                    this.props.perspectiveStore.selectedPerspectiveType === "vendors"
                                      ? row.arDocumentKey === k
                                      : row.apDocumentKey === k
                                )
                              )
                            )
                          }
                          rowHeight={"5rem"}
                        />
                      </React.Fragment>
                    </Card>
                    <h2 className="">{Resources.EmailAttachments}</h2>
                    <Card className="card-table">
                      <React.Fragment>
                        <div className="table-data-card-header">
                          {!isEmpty(this.state.selectedEmailAttachmentKeys) ? (
                            this.renderTableHeaderButtons(false)
                          ) : (
                            <div className="table-data-card-header-search">
                              <AutoCompleteInput
                                loading={this.props.networkStore.gettingNetworkAccountSearchResults}
                                className="auto-complete-input__table-data-search"
                                placeholder={Resources.Search}
                                onChange={e => {
                                  const accountsSearchTerm = e.target.value;
                                  this.setState({ accountsSearchTerm });
                                  this.props.debouncedFetchAttachmentSearchResults(
                                    match.params.perspectiveId,
                                    match.params.companyId,
                                    match.params.accountId,
                                    {
                                      searchTerm: accountsSearchTerm
                                    }
                                  );
                                }}
                                callToActionButton={
                                  <div
                                    className="dropdown-item-clean dropdown-call-to-action"
                                    onClick={() => this.setState({ displayAccountSearchResults: true })}
                                  >
                                    {Resources.SeeAllResultsFor(this.props.networkStore.accountSearchTerm)}
                                  </div>
                                }
                                isShowingSearchResult={this.state.displayAccountSearchResults}
                                handleClearResult={() => {
                                  this.setState({
                                    accountsSearchTerm: "",
                                    displayAccountSearchResults: false,
                                    accountsSearchLoadedPage: 1
                                  });
                                }}
                                text={this.state.accountsSearchTerm}
                                noResultsMessage={Resources.NoResultsFound}
                                showNoResultsMessage={this.props.networkStore.gotNetworkAccountSearchResults}
                                maxOptions={7}
                                handleSelectOption={option => {
                                  this.setState({
                                    accountsSearchTerm: option.fileName,
                                    displayAccountSearchResults: true,
                                    selectedEmailAttachmentKeys: [option.attachmentKey]
                                  });
                                  this.props.fetchAttachmentSearchResults(
                                    match.params.perspectiveId,
                                    match.params.companyId,
                                    match.params.accountId,
                                    {
                                      searchTerm: option.fileName
                                    }
                                  );
                                }}
                                renderOption={option => `${option.displayName || option.fileName}`}
                                options={this.props.networkStore.accountSearchResults.value}
                                width="300px"
                              />
                            </div>
                          )}
                        </div>
                        <TableData
                          data={selectedAccountEmailAttachments}
                          columns={documentColumns}
                          pagination
                          noHover
                          loading={this.props.networkStore.gettingEmailAttachments}
                          selectedKeys={this.state.selectedEmailAttachmentKeys}
                          rowKey={"attachmentKey"}
                          onRowSelectToggle={key =>
                            this.toggleSelectEmailAttachments(
                              key,
                              (Array.isArray(key) ? [...key] : [key]).map(k =>
                                find(this.props.networkStore.emailAttachments.value, row => row.attachmentKey === k)
                              )
                            )
                          }
                          rowHeight={"5rem"}
                        />
                      </React.Fragment>
                    </Card>
                  </div>
                )}
              </Route>
            </Switch>
          </div>
        </Route>
      </Switch>
    );
  }
}

const storeToProps = store => {
  return {
    gettingAccountProfiles: store.network.gettingAccountProfiles,
    gettingAccountContacts: store.network.gettingAccountContacts,
    gettingAccountDocuments: store.network.gettingAccountDocuments,
    notes: store.network.notes,
    networkStore: store.network,
    gettingNotes: store.network.gettingNotes,
    postingNotes: store.network.postingNotes,
    perspectiveStore: store.perspectives
  };
};

export default withLDConsumer()(withRouter(connect(storeToProps, dispatchToProps)(AccountView)));
