import React, { Component } from "react";
import LockstepLogo from "../library/lockstepLogo";
import Resources from "../../lib/resources";

class PortalAccountError extends Component {
  render() {
    return (
      <div className="portal-account-error">
        <div className="connected-by-lockstep-container">
          <span className="mr-1">{Resources.ConnectedBy}</span> <LockstepLogo />
        </div>
        <svg viewBox="0 0 268 122">
          <g fill="none" fillRule="evenodd">
            <path fill="#FFF" d="M-586-90H854V934H-586z" />
            <g transform="translate(0 1)">
              <path
                fill="#F4F3FF"
                d="M147.648148 24.8148148c4.796695 0 8.685185 3.8884899 8.685185 8.6851852s-3.88849 8.6851852-8.685185 8.6851852h79.407408c4.796695 0 8.685185 3.8884898 8.685185 8.6851852 0 4.7966953-3.88849 8.6851852-8.685185 8.6851852h27.296296c4.796695 0 8.685185 3.8884898 8.685185 8.6851851 0 4.7966954-3.88849 8.6851852-8.685185 8.6851852h-23.574074c-4.796696 0-8.685185 3.8884899-8.685185 8.6851852s3.888489 8.6851852 8.685185 8.6851852h7.444444c4.796696 0 8.685185 3.8884899 8.685185 8.6851847 0 4.796696-3.888489 8.685186-8.685185 8.685186H57.0740741c-4.7966954 0-8.6851852-3.88849-8.6851852-8.685186 0-4.7966948 3.8884898-8.6851847 8.6851852-8.6851847H8.68518519C3.88848986 94.2962963 0 90.4078064 0 85.6111111s3.88848986-8.6851852 8.68518519-8.6851852H58.3148148C63.1115101 76.9259259 67 73.0374361 67 68.2407407c0-4.7966953-3.8884899-8.6851851-8.6851852-8.6851851H27.2962963c-4.7966953 0-8.6851852-3.8884899-8.6851852-8.6851852 0-4.7966954 3.8884899-8.6851852 8.6851852-8.6851852h49.6296296c-4.7966953 0-8.6851852-3.8884899-8.6851852-8.6851852s3.8884899-8.6851852 8.6851852-8.6851852h70.7222221zm111.666667 69.4814815c4.796695 0 8.685185 3.8884899 8.685185 8.6851847 0 4.796696-3.88849 8.685186-8.685185 8.685186-4.796696 0-8.685185-3.88849-8.685185-8.685186 0-4.7966948 3.888489-8.6851847 8.685185-8.6851847z"
              />
              <path
                fill="#FFF"
                stroke="#BEB6FF"
                strokeLinecap="round"
                strokeWidth="2.5"
                d="M93.0555556 119.111111c-15.0753282 0-27.2962963-11.943219-27.2962963-26.6759258 0-14.7327071 12.2209681-26.6759259 27.2962963-26.6759259.6410622 0 1.2769629.0215967 1.9069819.0640863-.4377397-2.4366321-.6662412-4.9458967-.6662412-7.5085308 0-23.2982344 18.8869507-42.1851852 42.1851847-42.1851852 18.599998 0 34.388453 12.0376475 39.999763 28.7473405 1.329493-.139011 2.679549-.2103034 4.046534-.2103034 20.899887 0 37.842592 16.6649565 37.842592 37.2222222 0 19.5300151-15.29175 35.6431771-34.74074 37.1951551v.027067h-70.092159m-5.598025 0h-8.6412621"
              />
              <circle cx="137.101852" cy="94.9166667" r="6.82407407" stroke="#BEB6FF" strokeWidth="2.5" />
              <path
                stroke="#BEB6FF"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.5"
                d="M117.87037 80.6481481l8.685186-6.8155924-8.685186-6.4881483M156.333334 80.6481481l-8.685185-6.8155924 8.685185-6.4881483"
              />
              <path
                stroke="#BEB6FF"
                strokeLinecap="round"
                strokeWidth="2.5"
                d="M148.888888 28.537037c8.075846 2.1372721 14.452402 8.4529904 16.675909 16.4933963"
              />
              <circle cx="174.944444" cy="3.72222222" r="3.72222222" stroke="#BEB6FF" strokeWidth="2" />
              <circle cx="213.407407" cy="23.5740741" r="3.72222222" fill="#BEB6FF" />
              <path
                stroke="#BEB6FF"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.5"
                d="M195.09735 17.0693162l-10.370387 10.3703879m-.157647-10.3703879l10.370388 10.3703879M74.9454629 39.2026852l-7.4444444 7.4444445m0-7.4444445l7.4444444 7.4444445"
              />
              <circle cx="84.3703704" cy="27.2962963" r="3.72222222" fill="#BEB6FF" />
            </g>
          </g>
        </svg>
        <h2>{Resources.OopsThereIsAnIssueAccessingYourAccount}</h2>
        <p>{Resources.PleaseContactSupportToReportThisError}</p>
      </div>
    );
  }
}

export default PortalAccountError;
