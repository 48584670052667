import React, { Component } from "react";
import { Switch, Route, generatePath, Redirect, withRouter } from "react-router-dom";

import Resources from "../../../lib/resources";
import { formatDate, isEmpty } from "../../../lib/utils";
import { connect } from "react-redux";

import SummaryCard from "../../library/summaryCard";
import Card from "../../library/card";

import ViewContentHeader from "./viewContentHeader";
import OpenRequestsView from "./openRequestsView";

import IconSend from "../../library/icons/iconSend";

import { withLDConsumer } from "launchdarkly-react-client-sdk";

import { dispatchToProps as saDP } from "../../../store/statements-actions";
import { dispatchToProps as aaDP } from "../../../store/accounts-actions";
import { dispatchToProps as cgDP } from "../../../store/contextGroups-actions";
import { dispatchToProps as paDP } from "../../../store/perspectives-actions";
import { dispatchToProps as modDP } from "../../../store/modal-actions";
import { dispatchToProps as networkDP } from "../../../store/network-actions";
import { dispatchToProps as netDP } from "../../../store/network-actions";
import { dispatchToProps as genDP } from "../../../store/general-actions";
import { dispatchToProps as userDP } from "../../../store/user-actions";

import Avatar from "../../library/avatar";
import MainLoader from "../../mainLoader";

const dispatchToProps = dispatch => {
  return {
    ...dispatch(saDP),
    ...dispatch(aaDP),
    ...dispatch(cgDP),
    ...dispatch(paDP),
    ...dispatch(modDP),
    ...networkDP(dispatch),
    ...dispatch(netDP),
    ...dispatch(genDP),
    ...dispatch(userDP)
  };
};

class HomeView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleActivityClick(activty) {
    let activitykey = activty.networkActivityKey;
    this.setState({ [activitykey]: false });
    switch (activty.networkActivityTypeId) {
      case 3:
      case 1:
        this.props.history.push(
          generatePath("/v2/:companyId/:perspectiveId/home/open-requests/profile-update", {
            companyId: this.props.match.params.companyId,
            perspectiveId: this.props.match.params.perspectiveId
          })
        );
        break;
      case 2:
        if (activty.entityTypeId === 13) {
          this.props.displayFlyout("myProfileView", {
            myProfile: this.props.networkStore.custContact,
            companyId: this.props.match.params.companyId,
            perspectiveId: this.props.match.params.perspectiveId
          });
        } else {
          this.props.history.push(
            generatePath("/v2/:companyId/:perspectiveId/network/contacts/:entityGlobalKey/profile", {
              companyId: this.props.match.params.companyId,
              perspectiveId: this.props.match.params.perspectiveId,
              entityGlobalKey: activty.entityGlobalKey
            })
          );
        }
        break;
      case 4:
        if (activty.entityTypeId === 12) {
          this.props.displayFlyout("companyProfileView", {
            myProfile: this.props.accountsStore.selectedAccount,
            companyId: this.props.match.params.companyId,
            perspectiveId: this.props.match.params.perspectiveId
          });
        } else {
          this.props.history.push(
            generatePath("/v2/:companyId/:perspectiveId/network/accounts/:entityGlobalKey/profile", {
              companyId: this.props.match.params.companyId,
              perspectiveId: this.props.match.params.perspectiveId,
              entityGlobalKey: activty.entityGlobalKey
            })
          );
        }
        break;
      default:
    }
  }

  renderActivityItem(activity) {
    let activityType = activity.networkActivityTypeId;
    let activitykey = activity.networkActivityKey;
    let activityDescription = "";
    if (activityType === 1) {
      let name = activity.profileName;
      // if (activity.entityTypeId === 13) {
      //   name = Resources.You;
      // }
      activityDescription =
        activity.itemCount === 1
          ? Resources.SentContactUpdateRequest(name)
          : Resources.SentContactUpdateRequests(name, activity.itemCount);
    } else if (activityType === 2) {
      // if (activity.entityTypeId === 13) {
      //   activityDescription = Resources.UpdatedYourContactInfo;
      // } else {
      activityDescription = Resources.UpdatedContactInfo(activity.profileName);
    } else if (activityType === 3) {
      let name = activity.profileName;
      activityDescription =
        activity.itemCount === 1
          ? Resources.SentProfileUpdateRequest(name)
          : Resources.SentProfileUpdateRequests(name, activity.itemCount);
    } else if (activityType === 4) {
      let name = activity.profileName;
      activityDescription = Resources.UpdatedProfileInfo(name);
    } else {
      activityDescription = Resources.InvalidActivityType;
    }

    return (
      <Card onClick={() => this.handleActivityClick(activity)} className="network-activity-card" key={activitykey}>
        <div className="network-activity-card-avatar">
          <Avatar
            height={"3rem"}
            imgURL={activity.profileLogoURL}
            type="user"
            objectName={activity.profileName}
            width={"3.4rem"}
          ></Avatar>
        </div>
        <div>
          <div className="network-activity-card-description">{activityDescription}</div>
          <div>{formatDate(activity.insertDate)}</div>
        </div>
        <button className="button-primary network-activity-card__button">
          {activityType === 1 || activityType === 3 ? Resources.ViewOpenRequests : Resources.ViewProfile}
        </button>
      </Card>
    );
  }

  getContextGroupSummaryValues() {
    const { contextGroupsStore } = this.props;

    if (!contextGroupsStore.gotContextGroups) {
      return {};
    }

    let unassignedCount = 0;
    let mineCount = 0;

    contextGroupsStore.value.forEach(group => {
      unassignedCount += group.activeCounts.unassigned;
      mineCount += group.activeCounts.assignedToMe;
    });

    return { unassignedCount, mineCount };
  }

  componentDidMount() {
    let { perspectiveId } = this.props.match.params;
    this.props.fetchOpenProfileUpdateRequests(perspectiveId);
    this.props.fetchOpenOnboardingRequests(perspectiveId);
  }

  render() {
    const { match = { params: {} } } = this.props;
    let { perspectiveId } = this.props.match.params;

    if (
      this.props.perspectivesStore.selectedPerspectiveType !== "vendors" &&
      this.props.perspectivesStore.selectedPerspectiveType !== "customers" &&
      !isEmpty(this.props.perspectivesStore.selectedPerspectiveType)
    ) {
      return <Redirect to={generatePath("/v2/:companyId/:perspectiveId/activities", match.params)} />;
    }

    let summaryItems = [
      [
        {
          description: Resources.YourOpenActivities,
          value: this.getContextGroupSummaryValues().mineCount,
          linkTo: location => generatePath("/v2/:companyId/:perspectiveId/activities", match.params) + "/mine"
        },
        {
          description: Resources.OpenOnboardingRequests,
          value: this.props.getOpenOnboardingRequests(perspectiveId).count,
          linkTo: location => location.pathname + "/open-requests/onboarding"
        }
        // {
        //   description: Resources.AverageApprovalTimeByApprover,
        //   value: this.props.getOpenOnboardingRequests(perspectiveId).count,
        //   linkTo: location => location.pathname + "/activities/unassigned"
        // }
      ],
      [
        {
          description: Resources.UnassignedOpenActivities,
          value: this.getContextGroupSummaryValues().unassignedCount,
          linkTo: location => generatePath("/v2/:companyId/:perspectiveId/activities", match.params) + "/unassigned"
        },

        {
          description: Resources.OpenProfileUpdateRequests,
          value: this.props.getOpenProfileUpdateRequests(perspectiveId).count,
          linkTo: location => location.pathname + "/open-requests/profile-update"
        }
      ]
    ];
    this.props.getActivityList(perspectiveId, {
      top: 100,
      skip: this.props.networkStore.activityList.value.length
    });
    let activityCount = this.props.networkStore.activityList.count;

    return (
      <React.Fragment>
        <Switch>
          <Route exact path="/v2/:companyId/:perspectiveId/home">
            <ViewContentHeader />
            <div className="view-content">
              <SummaryCard
                summaryItems={summaryItems}
                title={Resources.HelloCompanyNameWelcomeBack(this.props.networkStore.custContact.contactName || "")}
                renderColumns
              ></SummaryCard>
              <h2 className="card-title">{Resources.NetworkActivity}</h2>
              {(this.props.networkStore.activityList.value || []).map(activity => this.renderActivityItem(activity))}
              {this.props.networkStore.gettingNetworkActivityList ? (
                <div className="activity-loader">
                  <MainLoader fullScreen={true} />
                </div>
              ) : null}
              {activityCount > this.props.networkStore.activityList.value.length &&
                !this.props.gettingNetworkActivityList && (
                  <button
                    className="button-secondary search__see-more"
                    onClick={() =>
                      this.props.fetchActivityList(match.params.companyId, match.params.perspectiveId, {
                        top: 20,
                        skip: this.props.networkStore.activityList.value.length
                      })
                    }
                  >
                    {Resources.SeeMore}
                  </button>
                )}
              {this.props.networkStore.gettingNetworkActivityList ? null : this.props.networkStore.activityList.value
                  .length === 0 ? (
                <React.Fragment>
                  <div className="home-empty-svg-container">
                    <svg viewBox="0 0 251 164">
                      <g fill="none">
                        <path fill="#FFF" d="M-595-190H845V834H-595z" />
                        <path fill="#F8FBFC" d="M-595-37H845v871H-595z" />
                        <path
                          fill="#F4F3FF"
                          d="M240.479042 44C246.289607 44 251 48.7010101 251 54.5S246.289607 65 240.479042 65h-60.119761c5.810565 0 10.520959 4.7010101 10.520959 10.5S186.169846 86 180.359281 86h33.065869c5.810564 0 10.520958 4.7010101 10.520958 10.5 0 5.79899-4.710394 10.5-10.520958 10.5h-15.291331c-7.326364 0-13.265556 4.70101-13.265556 10.5 0 3.865993 3.005988 7.365993 9.017965 10.5 5.810564 0 10.520958 4.70101 10.520958 10.5s-4.710394 10.5-10.520958 10.5H69.1377246c-5.8105648 0-10.5209581-4.70101-10.5209581-10.5S63.3271598 128 69.1377246 128H10.5209581C4.71039338 128 0 123.29899 0 117.5S4.71039338 107 10.5209581 107h60.1197605c5.8105647 0 10.520958-4.70101 10.520958-10.5 0-5.7989899-4.7103933-10.5-10.520958-10.5H33.0658683c-5.8105647 0-10.5209581-4.7010101-10.5209581-10.5S27.2553036 65 33.0658683 65h60.1197604c-5.8105647 0-10.520958-4.7010101-10.520958-10.5S87.375064 44 93.1856287 44H240.479042zm0 42C246.289607 86 251 90.7010101 251 96.5c0 5.79899-4.710393 10.5-10.520958 10.5s-10.520958-4.70101-10.520958-10.5c0-5.7989899 4.710393-10.5 10.520958-10.5z"
                        />
                        <path
                          fill="#FFF"
                          stroke="#BEB6FF"
                          d="M166.969231 64.75c1.182132 0 2.252364.4790605 3.027055 1.2536335.774594.7744765 1.253714 1.8443996 1.253714 3.0262172h0v91.9552243c0 .349269-.141611.66546-.370528.894343-.229014.228979-.5454.370582-.894857.370582h0-67.846153c-.349457 0-.665843-.141603-.894857-.370582-.228917-.228883-.370528-.545074-.370528-.894343h0v-27.45182l-1.8107799.062929c-.2301273.007997-.4611678.012025-.6930663.012025-5.952978 0-11.3333882-2.660451-15.2314583-6.935103C79.1839635 122.337331 76.75 116.344462 76.75 109.731343c0-6.613119 2.4339635-12.6059876 6.3877725-16.9417629 3.8980701-4.2746516 9.2784803-6.9351025 15.2314583-6.9351025.2318985 0 .462939.0040278.6930663.0120252h0l1.8107799.0629288V69.0298507c0-1.1818176.479119-2.2517407 1.253714-3.0262172.774691-.774573 1.844923-1.2536335 3.027055-1.2536335h0zM98.3692308 95.1679104c-3.5223673 0-6.7295251 1.6492273-9.0441163 4.3687243-2.2205915 2.6090533-3.6115248 6.2089613-3.6115248 10.1947083 0 3.985748 1.3909333 7.585655 3.6115248 10.194709 2.3145912 2.719497 5.521749 4.368724 9.0441163 4.368724.3004289 0 .5984641-.012048.8937761-.035737l1.6100701-.129155V95.3328026l-1.6100701-.1291547c-.295312-.023689-.5933472-.0357375-.8937761-.0357375z"
                        />
                        <path stroke="#DEDAFF" d="M159.5 113.689386v7.196525m0-43.6103183v27.7933733" />
                        <path
                          stroke="#BEB6FF"
                          d="M48.1924412 153h10.3374733m161.1497065 0H224m-45.128205 0h33.737446m-145.0536854 0h32.2728365"
                        />
                        <path
                          stroke="#DEDAFF"
                          d="M140.016577 2C136.005526 8.29032074 134 13.7642216 134 18.4217026c0 8.2946801 7 12.0093905 7 20.9943828 0 4.5602882-2.333333 9.0882597-7 13.5839146m-12.79532-35C119.809948 26.4141139 126 27.9702226 126 35.5138335c0 3.8287222-1.59844 7.6574443-4.79532 11.4861665m28.918208-32c-2.010365 5.1513862-.88094 8.2912986 0 10.1394581C152.005895 29.0898964 155 32.5 155 36.5199571c0 4.6689227-1.625704 9.1622703-4.877112 13.4800429"
                        />
                      </g>
                    </svg>
                  </div>
                  <h2 className="svg-matching-bold">{Resources.NothingToSeeHere}</h2>
                  <p className="svg-matching-p">{Resources.homeViewEmptyMessage}</p>
                  <div className="profile-update-small-button-container">
                    <button
                      className="button-primary bg-white"
                      onClick={() =>
                        this.props.displayFlyout("profileUpdateRequestFlyout", {
                          companyId: this.props.match.params.companyId,
                          accountingGroupId: this.props.match.params.perspectiveId
                        })
                      }
                    >
                      <IconSend className="button-primary-icon" height={19} />
                      {Resources.RequestProfileUpdates}
                    </button>
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          </Route>
          <Route exact path="/v2/:companyId/:perspectiveId/home/open-requests/:subView?">
            <OpenRequestsView />
          </Route>
          <Redirect to={generatePath("/v2/:companyId/:perspectiveId/home", { ...this.props.match.params })} />
        </Switch>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    contextGroupsStore: store.contextGroups,
    statementsStore: store.statements,
    accountsStore: store.accounts,
    perspectivesStore: store.perspectives,
    ledgerStore: store.ledger,
    pageRowCount: store.general.pageRowCount,
    networkStore: store.network,
    gettingNetworkActivityList: store.network.gettingNetworkActivityList
  };
};

export default withLDConsumer()(withRouter(connect(storeToProps, dispatchToProps)(HomeView)));
