import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Resources from "../../../../lib/resources";
import { formatDate, isEmpty, find, copyToClipboard } from "../../../../lib/utils";

import Card from "../../../library/card";
import ProfileUpdateForm from "../profileUpdate/profileUpdateForm";
import Modal from "react-modal";
import MainLoader from "../../../mainLoader";
import Dropdown from "../../../library/dropdown";

import IconClose from "../../../library/icons/iconClose";
import IconArchive from "../../../library/icons/iconArchive";
import IconPhone from "../../../library/icons/iconPhone";
import IconMapPin from "../../../library/icons/iconMapPin";
import IconLinkedIn from "../../../library/icons/iconLinkedIn";
import IconClipboard from "../../../library/icons/iconClipboard";
import IconMail from "../../../library/icons/iconMail";
import IconEdit from "../../../library/icons/iconEdit";
import IconShare from "../../../library/icons/iconShare";

import { dispatchToProps as networkDP } from "../../../../store/network-actions";
import { dispatchToProps as modDP } from "../../../../store/modal-actions";
import { dispatchToProps as persDP } from "../../../../store/perspectives-actions";
import DocumentUploadForm from "../documentUploadForm";
import moment from "moment";
import SelectInput from "../../../library/selectInput";

const dispatchToProps = dispatch => ({
  ...networkDP(dispatch),
  ...persDP(dispatch),
  ...modDP(dispatch)
});

class CompanyProfileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFlyout: true,
      editMode: false,
      selectedAccount: {},
      editedAccount: {},
      currentDocumentType: "",
      loadedTable: false,
      selectedAccountDocumets: {},
      view: props.selectedPerspectiveType === "vendors" ? "a316bce5-ba53-4eac-9af0-5e5609ca3e46" : "5be36b7d-090a-4597-bb2b-2e7b5876237f",
      perspectiveId: "",
      sameAsBilling: false,
      currentSubsidiary: "",
      subsidiaries: [],
      emailAddr: "",
      documents: [
        {
          type: 1,
          typeTitle: "",
          files: [],
          lastUpdated: "N/A"
        },
        {
          type: 2,
          files: [],
          lastUpdated: "N/A"
        },
        {
          type: 3,
          files: [],
          lastUpdated: "N/A"
        }
      ]
    };

    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
    this.formOnChange = this.formOnChange.bind(this);
    this.toggleSameAsBilling = this.toggleSameAsBilling.bind(this);
    this.documentsOnChange = this.documentsOnChange.bind(this);
    this.documentsUpdate = this.documentsUpdate.bind(this);
    this.updateCurrentDocType = this.updateCurrentDocType.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.hideFlyout = this.hideFlyout.bind(this);
  }

  toggleEditMode() {
    this.setState({ editMode: !this.state.editMode });
  }

  hideFlyout() {
    this.setState({ showFlyout: false });
  }

  componentDidMount() {
    let modeMap = {
      "a316bce5-ba53-4eac-9af0-5e5609ca3e46": "ap",
      "5be36b7d-090a-4597-bb2b-2e7b5876237f": "ar"
    };
    let companyPerspectives = ((this.props.myProfile || {}).companies || [])
      .map(company => this.props.getCompanyPerspectives(company.companyId))
      .flat()
      .filter(i => !!i);

    let companyDetailToggle = this.state.view === "a316bce5-ba53-4eac-9af0-5e5609ca3e46" ? "companyAPDetails" : "companyARDetails";
    let accountingGroupToggle = this.state.view === "a316bce5-ba53-4eac-9af0-5e5609ca3e46" ? "accountingGroupAPDetails" : "accountingGroupARDetails";

    this.props
      .fetchSubsidiaryList(
        find(companyPerspectives, i => i.parentPerspectiveId === String(this.state.view)).perspectiveId
      )
      .then(response => {
        if (isEmpty(this.props.networkStore.subsidiaryList)) {
          this.hideFlyout();
          let errorMessage = Resources.NoSubsidiariesErrorMessage;
          this.props.displayModal("errorModal", { errorMessage });
        } else {
          this.setState({
            subsidiaries: this.props.networkStore.subsidiaryList,
            currentSubsidiary: this.props.networkStore.subsidiaryList[0].companyName
          });
          this.props
            .fetchAccountingGroupProfile(
              find(companyPerspectives, i => i.parentPerspectiveId === String(this.state.view)).perspectiveId,
              this.props.networkStore.subsidiaryList[0].companyGlobalKey,
              modeMap[this.state.view]
            )
            .then(response => {
              this.setState({
                selectedAccount: this.props.networkStore.custAccount[companyDetailToggle],
                editedAccount: this.props.networkStore.custAccount[companyDetailToggle],
                perspectiveId: find(companyPerspectives, i => i.parentPerspectiveId === this.state.view).perspectiveId,
                emailAddr: this.props.networkStore.custAccount[accountingGroupToggle].emailAddr
              });
            });
          this.props
            .fetchAccountingGroupProfileDocuments(
              find(companyPerspectives, i => i.parentPerspectiveId === String(this.state.view)).perspectiveId,
              this.props.networkStore.subsidiaryList[0].companyGlobalKey,
              modeMap[this.state.view]
            )
            .then(response => {
              this.setState({ selectedAccountDocumets: this.props.networkStore.custAccountDocuments });
            });
        }
      });
  }

  formOnChange(field, value) {
    if (field === "emailAddr") {
      this.setState({ emailAddr: value });
    } else {
      this.setState(prevState => {
        return { editedAccount: { ...prevState.editedAccount, [field]: value } };
      });
    }
  }

  documentsOnChange(response) {
    let document = response;
    let filePath = document;
    let fileName = document.split("/");
    let currentDate = moment().format();
    let currentDoc = {
      fileName: fileName[fileName.length - 1],
      documentURL: filePath,
      documentTypeId: this.state.currentDocumentType,
      updateDate: currentDate
    };
    const documentToAmendIndex = this.state.documents.findIndex(i => i.type === this.state.currentDocumentType);
    const documentToAmend = this.state.documents[documentToAmendIndex];
    const updatedDocument = { ...documentToAmend, files: [...documentToAmend.files, currentDoc] } || {};
    this.setState({
      documents: [
        ...this.state.documents.slice(0, documentToAmendIndex),
        updatedDocument,
        ...this.state.documents.slice(documentToAmendIndex + 1)
      ]
    });
  }

  documentsUpdate() {
    if (!this.state.loadedTable) {
      const docs = (this.props.networkStore.custAccountDocuments || {}).documents || [];
      let docsArray = [[], [], []];
      docs.map(currentDoc => {
        let currentType = currentDoc.documentTypeId;
        docsArray[currentType - 1].push(currentDoc);
        return null;
      });
      let newDocuments = [
        {
          type: 1,
          typeTitle: this.props.match.params.mode === "ar" ? "Bank verification documents" : "Credit application",
          files: docsArray[0],
          lastUpdated: "N/A"
        },
        {
          type: 2,
          typeTitle: this.props.match.params.mode === "ar" ? "W9" : "Proof of insurance",
          files: docsArray[1],
          lastUpdated: "N/A"
        },
        {
          type: 3,
          typeTitle: this.props.match.params.mode === "ar" ? "Other" : "Tax exemption certificate",
          files: docsArray[2],
          lastUpdated: "N/A"
        }
      ];
      this.setState({ documents: newDocuments });
      this.setState({ loadedTable: true });
    }
  }

  updateCurrentDocType(newDocType) {
    this.setState({ currentDocumentType: newDocType });
  }

  removeFile(fileName, type) {
    const documentToAmendIndex = this.state.documents.findIndex(i => i.type === type);
    const documentToAmend = this.state.documents[documentToAmendIndex];
    const updatedDocument = {
      ...documentToAmend,
      files: [...documentToAmend.files.filter(f => f.fileName !== fileName)]
    };
    this.setState({
      documents: [
        ...this.state.documents.slice(0, documentToAmendIndex),
        updatedDocument,
        ...this.state.documents.slice(documentToAmendIndex + 1)
      ]
    });
  }

  formSubmit() {
    let modeMap = {
      "a316bce5-ba53-4eac-9af0-5e5609ca3e46": "ap",
      "5be36b7d-090a-4597-bb2b-2e7b5876237f": "ar"
    };
    let documentsArray = [];
    this.state.documents.map(i => i.files.map(j => documentsArray.push(j)));
    let documentRequest = {
      companyName: this.state.editedAccount.companyName,
      emailAddr: this.state.editedAccount.emailAddr,
      Documents: documentsArray
    };
    this.props
      .postAccountingGroupProfileDocuments(
        this.state.perspectiveId,
        find(this.state.subsidiaries, i => i.companyName === this.state.currentSubsidiary).companyGlobalKey,
        modeMap[this.state.view],
        documentRequest
      )
      .then(response => {
        this.props.fetchAccountingGroupProfileDocuments(
          this.state.perspectiveId,
          this.props.companyId,
          modeMap[this.state.view]
        );
      });

    let companyDetails = {
      accountProfileKey: this.state.selectedAccount.accountProfileKey,
      accountKey: this.state.selectedAccount.accountKey,
      aPProfileKey: this.state.editedAccount.ProfileKey,
      companyName: this.state.editedAccount.companyName,
      taxId: this.state.editedAccount.taxId,
      entityClassificationId: this.state.editedAccount.entityClassificationId,
      entityClassification: this.state.editedAccount.entityClassification,
      dateOfIncoporation: this.state.editedAccount.dateOfIncoporation,
      stateOfIncoporation: this.state.editedAccount.stateOfIncoporation,
      countryOfIncoporation: this.state.editedAccount.countryOfIncoporation,
      emailAddr: this.state.editedAccount.emailAddr,
      phone: this.state.editedAccount.phone,
      phoneExt: this.state.editedAccount.phoneExt,
      fax: this.state.editedAccount.fax,
      faxExt: this.state.editedAccount.faxExt,
      billToAddrLn1: this.state.editedAccount.billToAddrLn1,
      billToCity: this.state.editedAccount.billToCity,
      billToState: this.state.editedAccount.billToState,
      billToCountry: this.state.editedAccount.billToCountry,
      billToPostalcode: this.state.editedAccount.billToPostalcode,
      shipToAddrLn1: this.state.sameAsBilling
        ? this.state.editedAccount.billToAddrLn1
        : this.state.editedAccount.shipToAddrLn1,
      shipToAddrLn2: this.state.editedAccount.shipToAddrLn2,
      shipToCity: this.state.sameAsBilling ? this.state.editedAccount.billToCity : this.state.editedAccount.shipToCity,
      shipToState: this.state.sameAsBilling
        ? this.state.editedAccount.billToState
        : this.state.editedAccount.shipToState,
      shipToCountry: this.state.sameAsBilling
        ? this.state.editedAccount.billToCountry
        : this.state.editedAccount.shipToCountry,
      shipToPostalcode: this.state.sameAsBilling
        ? this.state.editedAccount.billToPostalcode
        : this.state.editedAccount.shipToPostalcode,
      mailToAddrLn1: this.state.editedAccount.mailToAddrLn1,
      mailToCity: this.state.editedAccount.mailToCity,
      mailToCountry: this.state.editedAccount.mailToCountry,
      mailToPostalcode: this.state.editedAccount.mailToPostalcode,
      mailToState: this.state.editedAccount.mailToState,
      isLockstepVerified: true,
      notes: this.state.editedAccount.notes,
      linkedInProfile: this.state.editedAccount.linkedInProfile,
      logoURL: this.state.editedAccount.logoURL,
      profileUpdateDate: moment().format()
    };

    let companyDetailToggle = this.state.view === "a316bce5-ba53-4eac-9af0-5e5609ca3e46" ? "companyAPDetails" : "companyARDetails";
    let accountingGroupToggle = this.state.view === "a316bce5-ba53-4eac-9af0-5e5609ca3e46" ? "accountingGroupAPDetails" : "accountingGroupARDetails";

    let postRequest = {
      [companyDetailToggle]: companyDetails,
      [accountingGroupToggle]: {
        emailAddr: this.state.emailAddr
      },
      isLockstepVerified: true
    };

    this.props
      .postAccountingGroupProfile(
        this.state.perspectiveId,
        find(this.state.subsidiaries, i => i.companyName === this.state.currentSubsidiary).companyGlobalKey,
        modeMap[this.state.view],
        postRequest
      )
      .then(response => {
        this.props
          .fetchAccountingGroupProfile(
            this.state.perspectiveId,
            find(this.state.subsidiaries, i => i.companyName === this.state.currentSubsidiary).companyGlobalKey,
            modeMap[this.state.view]
          )
          .then(response => {
            this.setState({ selectedAccount: this.props.networkStore.custAccount[companyDetailToggle] });
            this.props.fetchActivityList(this.state.perspectiveId, {
              top: 100,
              skip: 0
            });
            this.toggleEditMode();
          });
      });
  }

  toggleSameAsBilling() {
    this.setState({ sameAsBilling: !this.state.sameAsBilling });
  }

  render() {
    let companyPerspectives = ((this.props.myProfile || {}).companies || [])
      .map(company => this.props.getCompanyPerspectives(company.companyId))
      .flat()
      .filter(i => !!i);
    let perspectivesMap = {
      "a316bce5-ba53-4eac-9af0-5e5609ca3e46": Resources.AccountsPayable.toLocaleUpperCase(),
      "5be36b7d-090a-4597-bb2b-2e7b5876237f": Resources.AccountsReceivable.toLocaleUpperCase()
    };
    let modeMap = {
      "a316bce5-ba53-4eac-9af0-5e5609ca3e46": "ap",
      "5be36b7d-090a-4597-bb2b-2e7b5876237f": "ar"
    };
    let links = [];
    companyPerspectives.forEach(i => {
      if (!find(links, link => link.perspectiveId === i.perspectiveId)) {
        let currentLink = {
          subView: i.parentPerspectiveId,
          display: i.perspectiveName,
          perspectiveId: i.perspectiveId
        };
        links.push(currentLink);
      }
    });

    return (
      <Modal
        isOpen={this.state.showFlyout}
        onRequestClose={this.hideFlyout}
        onAfterClose={!this.state.showFlyout ? this.props.hideFlyout : null}
        closeTimeoutMS={400}
        contentLabel="Example Modal"
        className="flyout profile-managment-flyout"
        overlayClassName="flyout-overlay"
      >
        <React.Fragment>
          {this.props.networkStore.gettingCustAccount || this.props.networkStore.gettingSubsidiaryList ? null : (
            <div className="profile-managment-header">
              <h2>{links.length > 1 ? Resources.CompanyProfiles : Resources.CompanyProfile}</h2>
              <div onClick={() => this.hideFlyout()} className="flyout-heading-close">
                <IconClose />
              </div>
            </div>
          )}
          {this.props.networkStore.gettingCustAccount || this.props.networkStore.gettingSubsidiaryList
            ? null
            : links && (
                <React.Fragment>
                  {links.length > 1 ? (
                    <React.Fragment>
                      <div className="profile-managment-content-header-links">
                        {links.map(link => (
                          <button
                            id={link.perspectiveId}
                            className={
                              this.state.view === link.subView ? "content-header-link-active" : "content-header-link"
                            }
                            onClick={() => {
                              this.props
                                .fetchSubsidiaryList(
                                  find(companyPerspectives, i => i.parentPerspectiveId === String(this.state.view))
                                    .perspectiveId
                                )
                                .then(response => {
                                  this.setState({
                                    subsidiaries: this.props.networkStore.subsidiaryList,
                                    currentSubsidiary: this.props.networkStore.subsidiaryList[0].companyName
                                  });
                                  this.props
                                    .fetchAccountingGroupProfile(
                                      link.perspectiveId,
                                      this.props.networkStore.subsidiaryList[0].companyGlobalKey,
                                      modeMap[link.subView]
                                    )
                                    .then(response => {
                                      let accountingGroupToggle =
                                        link.subView === "a316bce5-ba53-4eac-9af0-5e5609ca3e46" ? "accountingGroupAPDetails" : "accountingGroupARDetails";
                                      this.setState({
                                        view: link.subView,
                                        selectedAccount:
                                          this.props.networkStore.custAccount.companyARDetails ||
                                          this.props.networkStore.custAccount.companyAPDetails,
                                        editedAccount:
                                          this.props.networkStore.custAccount.companyARDetails ||
                                          this.props.networkStore.custAccount.companyAPDetails,
                                        perspectiveId: link.perspectiveId,
                                        emailAddr: this.props.networkStore.custAccount[accountingGroupToggle].emailAddr
                                      });
                                      this.props
                                        .fetchAccountingGroupProfileDocuments(
                                          link.perspectiveId,
                                          this.props.networkStore.subsidiaryList[0].companyGlobalKey,
                                          modeMap[link.subView]
                                        )
                                        .then(response => {
                                          this.setState({
                                            selectedAccountDocumets: this.props.networkStore.custAccountDocuments
                                          });
                                        });
                                    });
                                });
                            }}
                          >
                            {link.display}
                          </button>
                        ))}
                      </div>
                      <hr></hr>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              )}
          {this.props.networkStore.gettingCustAccount ||
          this.props.networkStore.gettingSubsidiaryList ||
          this.props.networkStore.gettingCustAccountDocuments ? (
            <div className="loading-icon-container">
              <MainLoader fullScreen={true} />
            </div>
          ) : (
            <React.Fragment>
              {this.state.subsidiaries.length <= 1 ? null : (
                <SelectInput
                  className="subsidiary-company-dropdown"
                  value={(this.state.currentSubsidiary || this.state.subsidiaries[0] || {}).companyName}
                  displayValue={this.state.currentSubsidiary}
                  optionValueKey="companyName"
                  optionDisplayKey="companyName"
                  options={this.state.subsidiaries}
                  labelInline={false}
                  label={null}
                  width={"20rem"}
                  onSelectOption={option => {
                    let accountingGroupToggle =
                      this.state.view === "a316bce5-ba53-4eac-9af0-5e5609ca3e46" ? "accountingGroupAPDetails" : "accountingGroupARDetails";
                    this.props
                      .fetchAccountingGroupProfile(
                        this.state.perspectiveId,
                        find(this.state.subsidiaries, i => i.companyName === option.companyName).companyGlobalKey,
                        modeMap[this.state.view]
                      )
                      .then(response => {
                        this.setState({
                          selectedAccount:
                            this.props.networkStore.custAccount.companyARDetails ||
                            this.props.networkStore.custAccount.companyAPDetails,
                          editedAccount:
                            this.props.networkStore.custAccount.companyARDetails ||
                            this.props.networkStore.custAccount.companyAPDetails,
                          perspectiveId: this.state.perspectiveId,
                          emailAddr: this.props.networkStore.custAccount[accountingGroupToggle].emailAddr
                        });
                      });
                    this.props
                      .fetchAccountingGroupProfileDocuments(
                        this.state.perspectiveId,
                        find(this.state.subsidiaries, i => i.companyName === option.companyName).companyGlobalKey,
                        modeMap[this.state.view]
                      )
                      .then(response => {
                        this.setState({ selectedAccountDocumets: this.props.networkStore.custAccountDocuments });
                      });
                    this.setState({ currentSubsidiary: option.companyName });
                  }}
                />
              )}

              {this.state.editMode ? (
                <React.Fragment>
                  <h3>{Resources.Profile}</h3>
                  <ProfileUpdateForm
                    small={true}
                    mode={modeMap[this.state.view]}
                    perspectiveId={this.state.perspectiveId}
                    toggleEditMode={this.toggleEditMode}
                    companyId={this.props.companyId}
                    formOnChange={this.formOnChange}
                    editedAccount={this.state.editedAccount}
                    sameAsBilling={this.state.sameAsBilling}
                    toggleSameAsBilling={this.toggleSameAsBilling}
                    emailAddr={this.state.emailAddr}
                  />
                  <h3>{Resources.Documents}</h3>
                  <DocumentUploadForm
                    small={true}
                    editMode={this.state.editMode}
                    mode={modeMap[this.state.view]}
                    companyId={this.props.companyId}
                    perspectiveId={this.state.perspectiveId}
                    documentsOnChange={this.documentsOnChange}
                    documentsUpdate={this.documentsUpdate}
                    updateCurrentDocType={this.updateCurrentDocType}
                    removeFile={this.removeFile}
                  />
                  <div className="profile-managment-small-button-container">
                    <button className="button-secondary-square" onClick={this.toggleEditMode}>
                      {Resources.Cancel}
                    </button>
                    <button className="button-primary-square" onClick={this.formSubmit}>
                      {Resources.Save}
                    </button>
                  </div>
                </React.Fragment>
              ) : (
                <div>
                  <div className="profile-management-button-container">
                    <Dropdown
                      buttonContent={
                        <React.Fragment>
                          <IconShare className="button-primary-icon" />
                          <span>{Resources.ShareProfile}</span>
                        </React.Fragment>
                      }
                      buttonClassName="button-primary-large-dropdown"
                    >
                      <Dropdown.Item
                        onClick={() => {
                          this.props.displayFlyout("shareFlyout", {
                            myProfile: this.state.selectedAccount,
                            entityGlobalKey: this.props.networkStore.custAccount.entityGlobalKey,
                            companyId: find(
                              this.props.networkStore.subsidiaryList,
                              i => i.companyName === this.state.currentSubsidiary
                            ).companyGlobalKey,
                            groupKey:
                              this.state.view === "a316bce5-ba53-4eac-9af0-5e5609ca3e46"
                                ? this.props.networkStore.custAccount.accountingGroupAPDetails.apAccountingGroupKey
                                : this.props.networkStore.custAccount.accountingGroupARDetails.arAccountingGroupKey,
                            perspectiveId: this.state.perspectiveId,
                            isCompany: true,
                            mode: this.state.view
                          });
                        }}
                      >
                        <span className="share-dropdown-item">{Resources.SendInMessage}</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          this.props
                            .fetchShareLink(
                              this.state.view === "a316bce5-ba53-4eac-9af0-5e5609ca3e46" ? "ap" : "ar",
                              this.props.networkStore.custAccount.entityGlobalKey
                            )
                            .then(repsonse => {
                              copyToClipboard(this.props.networkStore.shareLink);
                            });
                          this.props.displayNotification("copyToClipboardNotification");
                        }}
                      >
                        <span className="share-dropdown-item">{Resources.CopyLink}</span>
                      </Dropdown.Item>
                    </Dropdown>
                    <button onClick={() => this.toggleEditMode()} className="button-primary large">
                      <IconEdit className="button-primary-icon" />
                      {Resources.EditProfile}
                    </button>
                  </div>
                  <h3>{Resources.Profile}</h3>
                  <div className="profile-managment-card-container">
                    <div className="profile-container">
                      <Card className="account-profile__card">
                        <div className="account-profile__card__last-updated">
                          {Resources.UpdatedDate(formatDate(this.state.selectedAccount.profileUpdateDate))}
                        </div>
                        <div className="">
                          <div className="account-profile-item">
                            <div className="account-profile-item__icon">
                              <IconMail height={18} />
                            </div>
                            <div className="account-profile-item__content">
                              {
                                (
                                  this.props.networkStore.custAccount.accountingGroupARDetails ||
                                  this.props.networkStore.custAccount.accountingGroupAPDetails ||
                                  {}
                                ).emailAddr
                              }
                            </div>
                          </div>
                          {!isEmpty(this.state.selectedAccount.phone) && (
                            <div className="account-profile-item">
                              <div className="account-profile-item__icon">
                                <IconPhone height={22} />
                              </div>
                              <div className="account-profile-item__content">{this.state.selectedAccount.phone}</div>
                            </div>
                          )}
                          {!isEmpty(this.state.selectedAccount.fax) && (
                            <div className="account-profile-item">
                              <div className="account-profile-item__icon">
                                <IconArchive height={16} />
                              </div>
                              <div className="account-profile-item__content">{this.state.selectedAccount.fax}</div>
                            </div>
                          )}
                          {!isEmpty(
                            [
                              this.state.selectedAccount.mailToAddrLn1,
                              this.state.selectedAccount.mailToCity,
                              this.state.selectedAccount.mailToState,
                              this.state.selectedAccount.mailToPostalcode,
                              this.state.selectedAccount.mailToCountry
                            ].filter(value => !isEmpty(value))
                          ) && (
                            <div className="account-profile-item">
                              <div className="account-profile-item__icon">
                                <IconMapPin height={22} />
                              </div>
                              <div className="account-profile-item__content">
                                <div className="account-profile-item__header">Remit-to</div>
                                <div>{this.state.selectedAccount.mailToAddrLn1}</div>
                                <div>
                                  {this.state.selectedAccount.mailToCity}, {this.state.selectedAccount.mailToState}{" "}
                                  {this.state.selectedAccount.mailToPostalcode}
                                </div>
                                <div>{this.state.selectedAccount.mailToCountry}</div>
                              </div>
                            </div>
                          )}
                          {!isEmpty(
                            [
                              this.state.selectedAccount.billToAddrLn1,
                              this.state.selectedAccount.billToCity,
                              this.state.selectedAccount.billToState,
                              this.state.selectedAccount.billToPostalcode,
                              this.state.selectedAccount.billToCountry
                            ].filter(value => !isEmpty(value))
                          ) && (
                            <div className="account-profile-item">
                              <div className="account-profile-item__icon">
                                <IconMapPin height={22} />
                              </div>
                              <div className="account-profile-item__content">
                                <div className="account-profile-item__header">Bill-to</div>
                                <div>{this.state.selectedAccount.billToAddrLn1}</div>
                                <div>
                                  {this.state.selectedAccount.billToCity}, {this.state.selectedAccount.billToState}{" "}
                                  {this.state.selectedAccount.billToPostalcode}
                                </div>
                                <div>{this.state.selectedAccount.billToCountry}</div>
                              </div>
                            </div>
                          )}
                          {!isEmpty(
                            [
                              this.state.selectedAccount.shipToAddrLn1,
                              this.state.selectedAccount.shipToCity,
                              this.state.selectedAccount.shipToState,
                              this.state.selectedAccount.shipToPostalcode,
                              this.state.selectedAccount.shipToCountry
                            ].filter(value => !isEmpty(value))
                          ) && (
                            <div className="account-profile-item">
                              <div className="account-profile-item__icon"></div>
                              <div className="account-profile-item__content">
                                <div className="account-profile-item__header">Ship-to</div>
                                <div>{this.state.selectedAccount.shipToAddrLn1}</div>
                                <div>
                                  {this.state.selectedAccount.shipToCity}, {this.state.selectedAccount.shipToState}{" "}
                                  {this.state.selectedAccount.shipToPostalcode}
                                </div>
                                <div>{this.state.selectedAccount.shipToCountry}</div>
                              </div>
                            </div>
                          )}
                          {!isEmpty(this.state.selectedAccount.linkedInProfile) && (
                            <div className="account-profile-item">
                              <div className="account-profile-item__icon">
                                <IconLinkedIn height={19} />
                              </div>
                              <div className="account-profile-item__content">
                                <a rel="norefferer noopener" href={this.state.selectedAccount.linkedInProfile}>
                                  {this.state.selectedAccount.linkedInProfile}
                                </a>
                              </div>
                            </div>
                          )}
                          {!isEmpty(this.state.selectedAccount.notes) && (
                            <div className="account-profile-item">
                              <div className="account-profile-item__icon">
                                <IconClipboard height={20} />
                              </div>
                              <div className="account-profile-item__content">{this.state.selectedAccount.notes}</div>
                            </div>
                          )}
                        </div>
                      </Card>
                    </div>
                  </div>
                  <h3>{Resources.Documents}</h3>
                  {this.props.networkStore.gotCustAccountDocuments && this.props.networkStore.gotCustAccount ? (
                    <DocumentUploadForm
                      small={true}
                      mode={modeMap[this.state.view]}
                      companyId={this.props.companyId}
                      perspectiveId={this.state.perspectiveId}
                      viewOnly={true}
                    />
                  ) : null}
                </div>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal,
    networkStore: store.network,
    selectedPerspectiveType: store.perspectives.selectedPerspectiveType
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(CompanyProfileView));
