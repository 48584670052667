import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { dispatchToProps as moDP } from "../../../store/modal-actions";
import { dispatchToProps as manDP } from "../../../store/manage-actions";

import Resources from "../../../lib/resources";
import { isEmpty, findIndex, createKey, find, updateSelectedRows, includes } from "../../../lib/utils";
import { isInt } from "../../../lib/validation";
import MainLoader from "../../mainLoader";
import TextInput from "../../library/textInput";
import TableData from "../../library/tableData";
import SelectInput from "../../library/selectInput";
import RadioInput from "../../library/radioInput";
import Card from "../../library/card";
import AutoCompleteInput from "../../library/autoCompleteInput";
import IconAngleLeft from "../../library/icons/iconAngleLeft";
import IconTrash from "../../library/icons/iconTrash";
import IconPlusCircle from "../../library/icons/iconPlusCircle";
import IconFilter from "../../library/icons/iconFilter";

import CustomerGroupsHelpDropdown from "./customerGroupsHelpDropdown";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...manDP(dispatch)
});

class CreateCustomerGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactAddressBookId: null,

      name: "",
      description: "",
      type: "dynamic",

      selectedCompanyIds: [],
      selectedRows: [],

      filters: [],
      staticFilters: [],

      showStaticFilter: false,

      searchTerm: "",
      searchResults: [],
      displaySearchResults: false,

      focusedField: "",
      view: "edit"
    };

    this.previewDynamicGroupContent = this.previewDynamicGroupContent.bind(this);
    this.renderEditGroupContent = this.renderEditGroupContent.bind(this);
    this.saveCustomerGroup = this.saveCustomerGroup.bind(this);
  }

  componentDidMount() {
    let { customerGroup } = this.props;
    if (!isEmpty(customerGroup)) {
      let customerGroupToState = { name: customerGroup.customerGroupId, description: customerGroup.description };
      if (!isEmpty(customerGroup.filters)) {
      }
      if (!isEmpty(customerGroup.companies)) {
        customerGroupToState.type = "static";
        customerGroupToState.selectedCompanyIds = customerGroup.companies.value.map(company => company.companyID);
        customerGroupToState.selectedRows = customerGroup.companies.value;
      }
      this.setState(customerGroupToState);
    }
    this.props.getCustomers({ top: this.props.pageRowCount, skip: 0 });
    this.props.getCustomerGroupsFilters(this.props.companyId);
  }

  updateFilter(i, newFilter, isDynamic) {
    let updatedFilter = { ...newFilter };
    if (
      findIndex(updatedFilter.filterCriteriaOptions, option => {
        return JSON.stringify(option) === JSON.stringify(updatedFilter.selectedOption);
      }) === -1
    ) {
      updatedFilter.selectedOption = updatedFilter.filterCriteriaOptions[0];
    }
    if (isDynamic) {
      let newFilters = [...this.state.filters];
      newFilters[i] = updatedFilter;
      this.setState({ filters: newFilters });
    } else {
      let newFilters = [...this.state.staticFilters];
      newFilters[i] = updatedFilter;
      this.setState({ staticFilters: newFilters });
    }
  }

  getFilterBuilder(isDynamic) {
    let filters = isDynamic ? this.state.filters : this.state.staticFilters;
    return (
      <div>
        {isDynamic && (
          <h2 className={`portal-input-label ${this.state.focusedField === "filters" ? " focused" : ""}`}>
            {Resources.Filters}
          </h2>
        )}
        {filters.map((filter, i) => {
          return this.getFilterItem(filter, i, isDynamic);
        })}
        <button
          className={`button-secondary account-groups-filter-add-new ${isDynamic ? "is-dynamic" : ""}`}
          style={{ fontSize: "0.93rem", marginTop: "0.8rem" }}
          onClick={() => {
            if (isDynamic) {
              this.setState({
                filters: [...this.state.filters, { field: "", comparator: "", value: "", editing: true }]
              });
            } else {
              this.setState({
                staticFilters: [...this.state.staticFilters, { field: "", comparator: "", value: "", editing: true }]
              });
            }
          }}
        >
          <IconPlusCircle height={18} />
          <span style={{ paddingLeft: "0.3rem" }}>{Resources.AddFilter}</span>
        </button>
      </div>
    );
  }

  getReadableFilterText(filter) {
    let { value, filterDescription, selectedOption } = filter;
    return `${filterDescription} ${selectedOption.optionDescription}${value ? " " + value : ""}`;
  }

  getFilterItem(filter, i, isDynamic) {
    let { manageStore } = this.props;
    let content;
    let actionItems = manageStore.customerGroupsFilters || [];
    let comparatorItems = filter.filterCriteriaOptions || [];
    const hideValueInput =
      isEmpty(filter.selectedOption) || includes(["IsNotNull", "IsNull"], (filter.selectedOption || {}).optionKey);

    if (!isDynamic || filter.editing) {
      content = (
        <div key={createKey(filter.filterKey, i)}>
          <div className="customer-group-dynamic-filter-item-editing-content">
            <SelectInput
              className="field"
              value={filter.filterKey}
              displayValue={filter.filterDescription}
              optionValueKey="filterKey"
              optionDisplayKey="filterDescription"
              options={actionItems}
              onSelectOption={option => {
                if (isDynamic) {
                  this.updateFilter(
                    i,
                    {
                      ...this.state.filters[i],
                      ...option
                    },
                    isDynamic
                  );
                } else {
                  this.updateFilter(
                    i,
                    {
                      ...this.state.staticFilters[i],
                      ...option
                    },
                    isDynamic
                  );
                }
              }}
            />
            <SelectInput
              className="comparator"
              value={(filter.selectedOption || {}).optionKey}
              displayValue={(filter.selectedOption || {}).optionDescription}
              options={comparatorItems}
              optionValueKey="optionKey"
              optionDisplayKey="optionDescription"
              onSelectOption={option => {
                if (isDynamic) {
                  this.updateFilter(i, { ...this.state.filters[i], selectedOption: { ...option } }, isDynamic);
                } else {
                  this.updateFilter(i, { ...this.state.staticFilters[i], selectedOption: { ...option } }, isDynamic);
                }
              }}
            />
            {!hideValueInput && (
              <TextInput
                style={{ marginBottom: 0 }}
                width="20rem"
                textValue={filter.value}
                inputOnChange={e => {
                  let newValue;
                  switch (filter.filterValueType) {
                    case "int":
                      newValue = isInt(e.target.value) ? e.target.value : filter.value;
                      break;
                    case "string":
                    default:
                      newValue = e.target.value;
                      break;
                  }
                  if (isDynamic) {
                    this.updateFilter(i, { ...this.state.filters[i], value: newValue }, isDynamic);
                  } else {
                    this.updateFilter(i, { ...this.state.staticFilters[i], value: newValue }, isDynamic);
                  }
                }}
              />
            )}
          </div>
          {isDynamic && (
            <button
              className="button-primary-square"
              style={{ marginTop: "2.13rem", marginRight: "1.33rem" }}
              disabled={
                isEmpty(filter.filterKey) ||
                isEmpty(filter.selectedOption) ||
                (!hideValueInput && isEmpty(filter.value))
              }
              onClick={e => {
                e.stopPropagation();
                if (isDynamic) {
                  let newFilters = [...this.state.filters];
                  newFilters[i].editing = false;
                  this.setState({ filters: newFilters });
                } else {
                  let newFilters = [...this.state.staticFilters];
                  newFilters[i].editing = false;
                  this.setState({ staticFilters: newFilters });
                }
              }}
            >
              {Resources.Done}
            </button>
          )}
          {isDynamic && (
            <button
              className="button-secondary-square"
              style={{ marginTop: "2.13rem" }}
              onClick={e => {
                e.stopPropagation();
                if (isDynamic) {
                  let newFilters = [...this.state.filters];
                  newFilters.splice(i, 1);
                  this.setState({ filters: newFilters });
                } else {
                  let newFilters = [...this.state.staticFilters];
                  newFilters.splice(i, 1);
                  this.setState({ staticFilters: newFilters });
                }
              }}
            >
              {Resources.Cancel}
            </button>
          )}
        </div>
      );
    } else {
      content = this.getReadableFilterText(filter);
    }

    return (
      <div className="customer-group-dynamic-filter-item" key={createKey(filter.field, i)}>
        {isDynamic && <span className="label">{i + 1}</span>}
        <span
          className={`content ${isDynamic ? null : "no-border static"}`}
          onClick={() => {
            if (filter.editing === false) {
              let newFilters = [...this.state.filters];
              newFilters[i].editing = true;
              this.setState({ filters: newFilters });
            }
          }}
        >
          {content}
        </span>
        {(!isDynamic || !filter.editing) && (
          <button
            className="button-action-icon"
            onClick={() => {
              if (isDynamic) {
                let newFilters = [...this.state.filters];
                newFilters.splice(i, 1);
                this.setState({ filters: newFilters });
              } else {
                let newFilters = [...this.state.staticFilters];
                newFilters.splice(i, 1);
                this.setState({ staticFilters: newFilters });
              }
            }}
          >
            <IconTrash height="20" className="icon-button" />
          </button>
        )}
      </div>
    );
  }

  customerColumns = select =>
    select
      ? [
          {
            type: "rowSelect",
            width: "9%"
          },
          { header: Resources.CompanyName, content: row => row.companyID },
          { header: Resources.CustomerName, content: row => row.custName },
          { header: Resources.CustomerId, content: row => row.custID },
          { header: Resources.SalesPerson, content: row => row.sperName },
          { header: Resources.TotalAging, content: row => row.companyId }
        ]
      : [
          { header: Resources.CompanyName, content: row => row.companyID },
          { header: Resources.CustomerName, content: row => row.custName },
          { header: Resources.CustomerId, content: row => row.custID },
          { header: Resources.SalesPerson, content: row => row.sperName },
          { header: Resources.TotalAging, content: row => row.companyId }
        ];

  toggleSelectRow(key, row) {
    let newSelectedKeys = updateSelectedRows(key, this.state.selectedCompanyIds);
    let newSelectedRows = updateSelectedRows(row, this.state.selectedRows);
    this.setState({ selectedCompanyIds: newSelectedKeys, selectedRows: newSelectedRows });
  }

  updateSearchTerm(searchTerm) {
    let searchResults = this.searchData(searchTerm);
    this.setState({ searchTerm, searchResults });
  }

  searchData(searchTerm) {
    let data = this.props.fetch;
    return data.filter(group => group.customerGroupId.toLowerCase().includes(searchTerm.toLowerCase()));
  }

  displaySearchResults(searchTerm) {
    this.updateSearchTerm(searchTerm);
    this.setState({ displaySearchResults: true });
  }

  getFiltersForAPI(isDynamic) {
    if (isDynamic) {
      return this.state.filters.map(filter => {
        return {
          filterKey: filter.filterKey,
          filterCriteria: (filter.selectedOption || {}).optionKey,
          filterValue: filter.value
        };
      });
    } else {
      return this.state.filters.map(filter => {
        return {
          filterKey: filter.filterKey,
          filterCriteria: (filter.selectedOption || {}).optionKey,
          filterValue: filter.value
        };
      });
    }
  }

  previewDynamicGroupContent() {
    let filters = this.getFiltersForAPI(true);
    this.props.postCustomerGroupsPreview(this.props.companyId, filters);
    this.setState({ view: "preview" });
  }

  renderDynamicGroupPreviewContent() {
    let { manageStore } = this.props;
    let data = (manageStore.customerGroupPreview.companies || {}).value || [];

    if (manageStore.postingCustomerGroupsPreview) {
      return <MainLoader fullScreen />;
    }
    if (manageStore.postedCustomerGroupsPreview && data.length > 0) {
      return (
        <React.Fragment>
          <div className="flyout-heading">
            <div>{Resources.AccountGroupPreview}</div>
          </div>
          <TableData
            pagination
            data={data}
            columns={this.customerColumns(false)}
            rowHeight="4em"
            maxRows={data.length}
            loading={this.props.manageStore.postingCustomerGroupsPreview}
            onRowClick={row => {
              return null;
            }}
            offsetHeight="40"
            emptyRender={
              <div className="flex-center">
                <h4 className="mt-5">{Resources.EmptyCustomerGroups}</h4>
              </div>
            }
          />
        </React.Fragment>
      );
    }
    return (
      <div className="account-groups-preview-empty">
        <svg viewBox="0 0 311 162" height="162">
          <g fill="none" fillRule="evenodd">
            <path fill="#FFF" d="M-727-164H713V877H-727z" />
            <path fill="#FFF" d="M-403-89H713v1286H-403z" />
            <path
              fill="#F4F3FF"
              d="M297.931034 0c7.198747 0 13.034483 5.83573671 13.034483 13.0344828 0 7.198746-5.835736 13.0344827-13.034483 13.0344827h-74.482758c7.198746 0 13.034483 5.8357367 13.034483 13.0344828 0 7.198746-5.835737 13.0344827-13.034483 13.0344827h40.965517c7.198746 0 13.034483 5.8357367 13.034483 13.0344828 0 7.198746-5.835737 13.0344828-13.034483 13.0344828h-18.944528c-9.076679 0-16.434782 5.8357367-16.434782 13.0344827 0 4.799164 3.724138 9.1439917 11.172414 13.0344827 7.198746 0 13.034482 5.835737 13.034482 13.034483s-5.835736 13.034483-13.034482 13.034483H85.6551724c-7.198746 0-13.0344827-5.835737-13.0344827-13.034483s5.8357367-13.034483 13.0344827-13.034483H13.0344828C5.83573671 104.275862 0 98.4401254 0 91.2413793c0-7.198746 5.83573671-13.0344827 13.0344828-13.0344827h74.4827586c7.198746 0 13.0344826-5.8357368 13.0344826-13.0344828 0-7.1987461-5.8357366-13.0344828-13.0344826-13.0344828H40.9655172c-7.198746 0-13.0344827-5.8357367-13.0344827-13.0344827 0-7.1987461 5.8357367-13.0344828 13.0344827-13.0344828h74.4827588c-7.198746 0-13.034483-5.8357367-13.034483-13.0344827C102.413793 5.83573671 108.24953 0 115.448276 0h182.482758zm0 52.137931c7.198747 0 13.034483 5.8357367 13.034483 13.0344828 0 7.198746-5.835736 13.0344828-13.034483 13.0344828-7.198746 0-13.034482-5.8357368-13.034482-13.0344828 0-7.1987461 5.835736-13.0344828 13.034482-13.0344828z"
            />
            <g transform="translate(74.482759 1.862069)">
              <circle
                cx="62.3793103"
                cy="62.3793103"
                r="62.3793103"
                fill="#DEDAFF"
                stroke="#BEB6FF"
                strokeWidth="3.5"
              />
              <path
                fill="#FFF"
                stroke="#BEB6FF"
                strokeLinecap="round"
                strokeWidth="3.5"
                d="M52.3838732 110.781738c3.274884.604488 6.6066963.918622 9.9954371.9424 27.2523958 0 49.3448277-22.0924319 49.3448277-49.3448277 0-27.2523957-22.0924319-49.3448275-49.3448277-49.3448275-7.000706 0-13.660909 1.4578667-19.6935761 4.0865673-10.4834096 4.5680865-19.0717502 12.6719238-24.2587225 22.8052126-3.4475866 6.7352182-5.3925289 14.3670001-5.3925289 22.4530476 0 7.3365582 1.6011015 14.299159 4.4729477 20.5574457 2.0502955 4.4679748 4.7482834 8.5769641 7.9737514 12.2067556"
              />
              <path
                stroke="#BEB6FF"
                strokeLinecap="round"
                strokeWidth="2.5"
                d="M31.2779663 100.690866c3.9999467 3.251136 8.5188843 5.888424 13.4133084 7.76836"
              />
              <path
                stroke="#DEDAFF"
                strokeLinecap="round"
                strokeWidth="3.5"
                d="M63.3103448 27.9310345c-3.2077597 0-6.3158107.4269038-9.2704378 1.2269965-15.042918 4.0735183-26.1088725 17.82062-26.1088725 34.1523138"
              />
              <path stroke="#BEB6FF" strokeWidth="3.5" d="M113.586207 111.724138l11.172414 11.172414" />
              <path
                fill="#DEDAFF"
                stroke="#BEB6FF"
                strokeWidth="3.5"
                d="M136.124227 120.682474c-1.874963-1.874964-4.332403-2.812445-6.789842-2.812445-2.45744 0-4.914879.937481-6.789842 2.812445-1.874964 1.874963-2.812445 4.332402-2.812445 6.789842s.937481 4.914879 2.812445 6.789842l20.641575 20.641575c1.874963 1.874963 4.332402 2.812445 6.789842 2.812445 2.457439 0 4.914879-.937482 6.789842-2.812445 1.874963-1.874963 2.812445-4.332403 2.812445-6.789842 0-2.45744-.937482-4.914879-2.812445-6.789842l-20.641575-20.641575z"
              />
              <path
                stroke="#FFF"
                strokeLinecap="round"
                strokeWidth="2.5"
                d="M132.206897 124.758621l20.482758 20.482758"
              />
            </g>
            <path
              stroke="#DEDAFF"
              strokeLinecap="round"
              stroke-linejoin="round"
              strokeWidth="3.5"
              d="M248.586207 50.2758621h-31.982489m45.016972 0h-4.136012m-20.674603 14.4819504h-15.223868M60.5172414 89.3793103H28.5347522m-10.845097 0H9.82950566M69.2238685 105.72333H54"
            />
          </g>
        </svg>
        <h2>{Resources.AccountGroupPreviewEmpty}</h2>
      </div>
    );
  }

  saveCustomerGroup() {
    let isDynamic = this.state.type === "dynamic";
    let saveGroupPromise;
    let customerGroup = { customerGroupId: this.state.name, description: this.state.description };
    if (isDynamic) {
      saveGroupPromise = this.props.postCustomerGroup(this.props.companyId, null, {
        ...customerGroup,
        filters: this.getFiltersForAPI(true)
      });
    } else {
      saveGroupPromise = this.props.postCustomerGroup(this.props.companyId, null, {
        ...customerGroup,
        companies: this.state.selectedRows
      });
    }

    saveGroupPromise.then(response => null);
  }

  renderEditGroupContent() {
    const disableSave = isEmpty(this.state.name) || (isEmpty(this.state.filters) && isEmpty(this.state.selectedRows));
    let { manageStore } = this.props;
    let data = manageStore.displayCustomersSearchResults
      ? manageStore.customersSearchResults
      : manageStore.customers.value;
    let displayLoader = this.props.manageStore.postingCustomerGroup || this.props.manageStore.deletingCustomerGroup;

    let disablePreview = this.state.filters.reduce((a, b) => a.editing || b.editing, true);
    if (displayLoader) {
      return <MainLoader fullScreen />;
    }
    return (
      <React.Fragment>
        <div className="flyout-heading">
          <div>{isEmpty(this.props.customerGroup) ? Resources.CreateANewAccountGroup : Resources.ViewAndEditGroup}</div>
        </div>
        <div className="flyout-content">
          <TextInput
            label={Resources.Name}
            labelInline={false}
            width="20rem"
            textValue={this.state.name}
            inputOnChange={e => {
              this.setState({ name: e.target.value });
            }}
          />
          <TextInput
            label={Resources.Description}
            labelInline={false}
            width="20rem"
            textValue={this.state.description}
            inputOnChange={e => {
              this.setState({ description: e.target.value });
            }}
          />

          <RadioInput
            inline
            label={
              <div style={{ display: "flex" }}>
                {Resources.GroupType}
                <CustomerGroupsHelpDropdown />
              </div>
            }
            inputs={[
              { label: Resources.Dynamic, value: "dynamic" },
              { label: Resources.Static, value: "static" }
            ]}
            radioName="customerGroupType"
            value={this.state.type}
            onChange={item => {
              this.setState({
                type: item.value
              });
            }}
          />
          <p className="account-groups-type-explanation">
            {this.state.type === "dynamic" ? Resources.DynamicGroupsExplanation : Resources.StaticGroupsExplanation}
          </p>

          <hr style={{ marginTop: "2rem", marginBottom: "2rem" }}></hr>

          {this.state.type === "static" ? (
            <Card type="company-info-contact-us" className="company-info-contact-us">
              <div className="table-data-card-header">
                <div className="d-flex align-items-center">
                  <div className="table-data-card-header-search">
                    {/* <AutoCompleteInput
                      className="auto-complete-input__table-data-search"
                      placeholder={Resources.Search}
                      onChange={e => {
                        this.updateSearchTerm(e.target.value);
                      }}
                      isShowingSearchResult={this.state.displaySearchResults}
                      handleClearResult={() => {
                        this.setState({ searchTerm: "", searchResults: [], displaySearchResults: false });
                      }}
                      text={this.state.searchTerm}
                      noResultsMessage={Resources.NoCustomerGroupsSearchResultsMessage}
                      showNoResultsMessage={
                        this.state.searchTerm.length !== 0 && this.props.manageStore.gotCustomerGroups
                      }
                      maxOptions={7}
                      loading={this.props.manageStore.gettingCustomerGroups}
                      handleSelectOption={option => {
                        this.displaySearchResults(option.customerGroupId);
                      }}
                      callToActionButton={
                        <div
                          className="dropdown-item-clean dropdown-call-to-action"
                          onClick={() => this.displaySearchResults(this.state.searchTerm)}
                        >
                          {Resources.SeeAllResultsFor(this.state.searchTerm)}
                        </div>
                      }
                      renderOption={option => `${option.customerId}`}
                      options={this.state.searchResults}
                      width="300px"
                    /> */}
                    <AutoCompleteInput
                      className="auto-complete-input__table-data-search"
                      placeholder={Resources.Search}
                      onChange={e => {
                        const searchTerm = e.target.value;
                        this.setState({ searchTerm });
                        this.props.fetchCustomersSearchResults(searchTerm);
                      }}
                      isShowingSearchResult={manageStore.displayCustomersSearchResults}
                      handleClearResult={() => {
                        this.setState({ searchTerm: "", selectedRows: [] });
                        this.props.clearCustomersSearchResults();
                      }}
                      text={this.state.searchTerm}
                      noResultsMessage={Resources.NoStatementSearchResultsMessage}
                      showNoResultsMessage={manageStore.fetchedCustomersSearchResults}
                      maxOptions={7}
                      loading={manageStore.fetchingCustomersSearchResults}
                      handleSelectOption={option => {
                        this.setState({ selectedRows: [], searchTerm: option.custName });
                        this.props.fetchCustomersSearchResults(option.custName);
                        this.props.displayCustomersSearchResults();
                      }}
                      callToActionButton={
                        <div
                          className="dropdown-item-clean dropdown-call-to-action"
                          onClick={() => this.props.displayCustomersSearchResults()}
                        >
                          {Resources.SeeAllResultsFor(this.state.searchTerm)}
                        </div>
                      }
                      renderOption={option => `${option.custName}`}
                      options={manageStore.customersSearchResults}
                      width="300px"
                    />
                  </div>
                  <div className="account-groups-static-filter-dropdown dropdown">
                    <button
                      className="button button-action-icon dropdown-toggle"
                      data-toggle="dropdown-filter"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={{ marginTop: "2rem" }}
                      onClick={() => this.setState({ showStaticFilter: !this.state.showStaticFilter })}
                    >
                      <IconFilter height="20"></IconFilter>
                    </button>
                    <div
                      className={`content dropdown-menu ${this.state.showStaticFilter ? "show" : ""}`}
                      style={{ minWidth: "30rem", padding: "1rem" }}
                    >
                      <form>{this.getFilterBuilder(false)}</form>
                      <div>
                        <button className="button-primary-square">{Resources.Done}</button>
                        <button className="button-secondary-square" style={{ marginLeft: "1.33rem" }}>
                          {Resources.Cancel}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <TableData
                pagination
                data={data}
                columns={this.customerColumns(true)}
                rowHeight="4em"
                maxRows={manageStore.customers.count}
                loading={
                  manageStore.displayCustomersSearchResults
                    ? manageStore.fetchingCustomersSearchResults
                    : manageStore.gettingCustomers
                }
                onLoadMore={page =>
                  this.props.getCustomers({
                    skip: (page - 1) * this.props.pageRowCount,
                    top: this.props.pageRowCount
                  })
                }
                rowKey="custID"
                selectedKeys={this.state.selectedCompanyIds}
                onRowSelectToggle={key =>
                  this.toggleSelectRow(
                    key,
                    (Array.isArray(key) ? [...key] : [key]).map(k => find(data, row => row.custID === k))
                  )
                }
                rowClassName="statements-view-row"
                offsetHeight="40"
                emptyRender={
                  <div className="flex-center">
                    <h4 className="mt-5">{Resources.EmptyCustomers}</h4>
                  </div>
                }
              />
            </Card>
          ) : (
            this.getFilterBuilder(true)
          )}
          {!isEmpty(this.props.customerGroup) && (
            <React.Fragment>
              <button
                className="button-cancel-square "
                onClick={() =>
                  this.props
                    .deleteCustomerGroup(this.props.companyId, this.props.customerGroup.customerGroupKey)
                    .then(response => this.props.getCustomerGroups(this.props.companyId))
                    .then(response => this.props.onCloseCustomerGroup())
                    .catch(e => null)
                }
                style={{ marginBottom: "1rem", width: "16.66rem" }}
              >
                {Resources.DeleteGroup}
              </button>
              <br />
            </React.Fragment>
          )}

          {this.state.type === "dynamic" && (
            <React.Fragment>
              <button
                className="button-secondary-square "
                onClick={this.previewDynamicGroupContent}
                disabled={disablePreview}
                style={{ marginBottom: "1rem", width: "16.66rem" }}
              >
                {Resources.PreviewGroup}
              </button>
              <br />
            </React.Fragment>
          )}
          <button
            className="button-primary-square"
            onClick={this.saveCustomerGroup}
            disabled={disableSave}
            style={{ width: "16.66rem" }}
          >
            {Resources.SaveGroup}
          </button>
        </div>
      </React.Fragment>
    );
  }

  render() {
    let content = this.state.view === "edit" ? this.renderEditGroupContent() : this.renderDynamicGroupPreviewContent();
    return (
      <React.Fragment>
        <div className="sequences-create-header">
          <button
            className="button-back"
            onClick={() => {
              if (this.state.view === "edit") {
                this.props.onCloseCustomerGroup();
              } else {
                this.setState({ view: "edit" });
              }
            }}
          >
            <IconAngleLeft />
            {Resources.Back.toLocaleUpperCase()}
          </button>
        </div>
        <hr style={{ marginTop: "1rem", marginBottom: "1.66rem" }} />
        <div className="manage-content">{content}</div>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal,
    conversationsStore: store.conversations,
    ledgerStore: store.ledger,
    accountsStore: store.accounts,
    manageStore: store.manage,
    pageRowCount: store.general.pageRowCount
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(CreateCustomerGroup));
