import React from "react";
import { components } from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import { isEmailValid } from "../../lib/utils";
import Avatar from "../library/avatar";

const ContactEmailSelect = ({ loadOptions, onChange, label, value = [] }) => {
  const [isActive, setIsActive] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  const getSelectItem = (address, name) => ({
    address,
    name,
    isInvalid: !isEmailValid(address)
  });

  return (
    <div className="contact-email-select">
      <div className={`input-label email-select-label ${isActive ? "email-select__label--is-active" : ""}`}>
        {label}
      </div>

      <AsyncCreatableSelect
        inputValue={inputValue}
        isMulti
        defaultOptions
        cacheOptions
        allowCreateWhileLoading
        autoFocus
        closeMenuOnSelect
        tabSelectsValue
        openMenuOnClick={false}
        className="email-select-container"
        classNamePrefix="email-select"
        placeholder={null}
        isClearable={false}
        loadOptions={loadOptions}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
        // The `isNew` key is used to exclude new e-mail address from the list of options,
        // as well as to hide the menu completely when only new options are present.
        getNewOptionData={(_, label) => ({ isNew: true, address: label, label })}
        onInputChange={value => setInputValue(value)}
        onChange={selected => {
          const emails = (selected || []).map(s => {
            let address = "";

            if (s.address.includes("[") || s.address.includes("<")) {
              address = s.address;
            } else {
              address = s.address.split(",")[0];
            }

            return { address, name: s.name, isInvalid: s.isInvalid || false };
          });
          onChange(emails);
        }}
        onCreateOption={string => {
          onChange([...value, getSelectItem(string, "")]);
        }}
        formatCreateLabel={userInput => userInput}
        components={{
          Option: props => {
            if (props.data.isNew) {
              return null;
            }
            return (
              <components.Option {...props}>
                <div className="email-select__option__container">
                  <Avatar imgURL={props.data.profilePictureURL} objectName={props.data.name} />
                  <div className="email-select__option__user-info w-85">
                    {/* For newly created contacts (which don't have name assigned), backend saves e-mail as their name.
                  We don't want to display this address twice, so we check if name is different than e-mail.  */}
                    {props.data.name !== props.value && <div className="overflow-ellipsis">{props.data.name}</div>}
                    <div className="overflow-ellipsis">{props.value}</div>
                  </div>
                </div>
              </components.Option>
            );
          },
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          Menu: props => {
            if (inputValue === "" || props.options.every(option => option.isNew)) {
              return null;
            }

            return <components.Menu {...props}>{props.children}</components.Menu>;
          },
          MultiValueContainer: ({ innerProps, ...props }) => {
            const newProps = {
              ...innerProps,
              className: props.data.isInvalid ? `${innerProps.className} invalid-email-container` : innerProps.className
            };
            return <components.MultiValueContainer {...props} innerProps={newProps} />;
          }
        }}
        value={value}
        getOptionLabel={option => option.name || option.address}
        getOptionValue={option => option.address}
      />
    </div>
  );
};

export default ContactEmailSelect;
