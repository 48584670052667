import React, { Component, useEffect } from "react";
import Resources from "../../lib/resources";
import Icons from "../../lib/icons";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { isEmpty, isProduction, find } from "../../lib/utils";

import LockstepLogo from "../library/lockstepLogo";
import IconApps from "../library/icons/iconApps";
import IconCheck from "../library/icons/iconCheck";

import { dispatchToProps as uaDP } from "../../store/user-actions";
import { dispatchToProps as saDP } from "../../store/search-actions";
import { dispatchToProps as moDP } from "../../store/modal-actions";

import "./main-content-header.scss";
import { useLDClient, withLDConsumer } from "launchdarkly-react-client-sdk";
import { event as FullStoryEvent } from "react-fullstory";

import IconPlusCircle from "../library/icons/iconPlusCircle";

const dispatchToProps = dispatch => {
  return {
    ...uaDP(dispatch),
    ...saDP(dispatch),
    ...moDP(dispatch)
  };
};

function MainContentHeader({
  title,
  subtitle,
  links,
  customContent,
  iconObject,
  isNewCompany,
  logoSrc,
  companySelect,
  isCustomerPortal,
  selectedAccountId,
  flags,
  displayModal = () => null
}) {
  const ldClient = useLDClient();

  useEffect(() => {
    let eventName = "View add vendors button";
    try {
      FullStoryEvent(eventName + "--" + flags.portalAddVendorsConversionV3);
    } catch (e) {
      console.log(e);
    }
  }, [isCustomerPortal]);

  let submittedAddVendorsFormForAccountIds = JSON.parse(
    window.localStorage.getItem("submitted-add-vendors-for-accountids")
  );
  let selectedAccountIdSubmittedForm = !isEmpty(
    find(submittedAddVendorsFormForAccountIds, accountId => accountId === selectedAccountId)
  );

  let conversionButtonText = Resources.AddVendors;
  switch (flags.portalAddVendorsConversionV3) {
    case "join-lockstep":
      conversionButtonText = Resources.JoinLockstep;
      break;
    case "process-more-invoices":
      conversionButtonText = Resources.ProcessMoreInvoices;
      break;
    case "add-vendors":
    default:
      conversionButtonText = Resources.AddVendors;
      break;
  }

  let addVendorsButton = selectedAccountIdSubmittedForm ? (
    <div className="button-info success-text">
      <IconCheck className="success-icon" height={13} />
      {Resources.RequestToAddVendorsSubmitted}
    </div>
  ) : (
    <button
      className="button-primary"
      onClick={() => {
        const eventName = "Click add vendors button";
        ldClient.track(eventName);
        FullStoryEvent(eventName + "--" + flags.portalAddVendorsConversionV3);
        displayModal("portalAddVendorsModal");
      }}
    >
      <IconPlusCircle className="button-primary-icon" />
      {conversionButtonText}
    </button>
  );

  return (
    <div id="content-header-container">
      <div className="d-flex flex-column">
        <div id="content-header-title">
          {isCustomerPortal && !isEmpty(logoSrc) && (
            <img className="content-header-title-logo" src={logoSrc} alt={Resources.Logo}></img>
          )}
          {isNewCompany && <span className={Icons.activeConversation + " pr-2 new-company-header-icon"} />}
          <div>
            {companySelect ? (
              <div className="content-header">{companySelect}</div>
            ) : (
              <h3 className="content-header">{title}</h3>
            )}
            {!isEmpty(subtitle) && <h4 className="content-header-subtitle">{subtitle}</h4>}
          </div>
          {iconObject}
        </div>

        {!isEmpty(links) && <div id="content-header-nav">{links}</div>}
        {customContent}
      </div>
      <div className="content-header__right-column">
        {isCustomerPortal ? (
          <a
            className="connected-by-lockstep-container"
            href="https://lockstephq.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="mr-1">{Resources.ConnectedBy}</span> <LockstepLogo height={33} />
          </a>
        ) : (
          <React.Fragment>
            {!isProduction() && (
              <Link to={"/v2"}>
                <button className="button-action-icon app-switcher-button">
                  <IconApps height={18}></IconApps>
                </button>
              </Link>
            )}
          </React.Fragment>
        )}
        {isCustomerPortal && flags.portalAddVendorsConversion.enabled && (
          <div className="content-header__add-vendors-container">{addVendorsButton}</div>
        )}
      </div>
    </div>
  );
}

MainContentHeader.defaultProps = { company: { isNew: false } };

export default withLDConsumer()(
  withRouter(
    connect(store => {
      return {
        selectedAccountId: store.accounts.selectedAccountId,
        showModal: store.modal.showModal
      };
    }, dispatchToProps)(MainContentHeader)
  )
);
