import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import RadioInput from "../../../library/radioInput";
import { dispatchToProps as paDP } from "../../../../store/perspectives-actions";
import { dispatchToProps as uaDP } from "../../../../store/user-actions";
import { dispatchToProps as gaDP } from "../../../../store/general-actions";
import EditableField from "./editableField";
import Resources from "../../../../lib/resources";
import IconClose from "../../../library/icons/iconClose";
import IconAngleLeft from "../../../library/icons/iconAngleLeft";
import TableData from "../../../library/tableData";
import { SettingsViews } from "./settingsControllerFlyout";

const getPerspectiveType = perspective => {
  const parentPerspectiveName = perspective.parentPerspective && perspective.parentPerspective.perspectiveName;

  switch (parentPerspectiveName) {
    case "vendors":
      return Resources.AccountsPayable;
    case "customers":
      return Resources.AccountsReceivable;
    default:
      return perspective.perspectiveName;
  }
};

const dispatchToProps = dispatch => {
  return {
    ...paDP(dispatch),
    ...uaDP(dispatch),
    ...gaDP(dispatch)
  };
};

const WorkspacePreferences = ({
  persistedPerspectives,
  getUserConfig,
  updateUserConfig,
  persistedDefaultPerspective,
  updateCompanyPerspective,
  hideFlyout,
  setSettingsView
}) => {
  useEffect(() => {
    getUserConfig();
  }, [getUserConfig]);

  useEffect(() => {
    setDefaultPerspective(persistedDefaultPerspective);
  }, [persistedDefaultPerspective]);

  const [perspectives, setPerspectives] = useState(persistedPerspectives);
  const [defaultPerspective, setDefaultPerspective] = useState(persistedDefaultPerspective);

  const onDefaultPerspectiveChange = perspectiveId => () => setDefaultPerspective(perspectiveId);

  const onPerspectiveNameChange = perspectiveId => event => {
    event.preventDefault();

    const updated = { ...perspectives[perspectiveId], current: event.target.value };
    setPerspectives({
      ...perspectives,
      [perspectiveId]: updated
    });
  };

  const onSaveButtonClick = event => {
    event.preventDefault();

    const perspectiveNamesToUpdate = Object.entries(perspectives).filter(
      ([, { initial, current }]) => initial !== current
    );

    perspectiveNamesToUpdate.forEach(([perspectiveId, { current }]) => {
      updateCompanyPerspective(perspectiveId, { perspectiveName: current });
    });

    if (defaultPerspective !== persistedDefaultPerspective) {
      updateUserConfig({ defaultPerspective: defaultPerspective }).then(getUserConfig);
    }
  };

  return (
    <div>
      <div className="settings-flyout__header flyout-heading">
        <IconAngleLeft onClick={() => setSettingsView(SettingsViews.SETTINGS)} className="settings-flyout__back" />
        <h2>{Resources.WorkspacePreferences}</h2>
        <div onClick={hideFlyout} className="flyout-heading-close">
          <IconClose />
        </div>
      </div>

      <TableData
        noScroll
        data={Object.entries(perspectives)}
        columns={[
          {
            header: Resources.WorkspaceName.toLocaleUpperCase(),
            width: "50%",
            content: ([id, { current }]) => <EditableField value={current} onChange={onPerspectiveNameChange(id)} />
          },
          { header: Resources.Type.toLocaleUpperCase(), content: ([, { type }]) => type, width: "40%" },
          {
            header: Resources.Default.toLocaleUpperCase(),
            content: ([id]) => (
              <RadioInput
                className="d-flex justify-content-center m-0"
                inputClassName="w-45"
                disabled={false}
                radioName="workspaces"
                inputs={[{ value: id }]}
                value={defaultPerspective}
                onChange={onDefaultPerspectiveChange(id)}
              />
            ),
            width: "10%"
          }
        ]}
        rowHeight="4rem"
      />

      <button className="button-primary-square mt-4" style={{ minWidth: "14rem" }} onClick={onSaveButtonClick}>
        {Resources.Save}
      </button>
    </div>
  );
};

const storeToProps = store => {
  return {
    persistedDefaultPerspective: store.user.userConfig.defaultPerspective,
    persistedPerspectives: store.perspectives.companyPerspectives.reduce(
      (acc, perspective) => ({
        ...acc,
        [perspective.perspectiveId]: {
          // We'll keep `initial` values to only trigger updates for perspectives which actually changed
          initial: perspective.perspectiveName,
          current: perspective.perspectiveName,
          type: getPerspectiveType(perspective),
          id: perspective.perspectiveId
        }
      }),
      {}
    )
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(WorkspacePreferences));
