import { actions as GeneralActions, networkError, isAPILive } from "./general-actions";
import { actions as ModalActions } from "./modal-actions";
import store from "./store";

import { event as FullStoryEvent } from "react-fullstory";

import Resources from "../lib/resources";
import { updateConfig, isLocalHost, isProduction } from "../lib/utils";

export const actions = {
  ...GeneralActions,
  ...ModalActions,
  ...{
    RAISE_ERROR: "RAISE_ERROR"
  }
};

export function raiseError(message, customContent = null) {
  const errorMessage =
    message ||
    "TODO: We goofed! Sorry about that. Please try again or contact support if you continue to have trouble.";
  store.dispatch({ type: actions.SHOW_MODAL, modalType: "errorModal", modalProps: { errorMessage, customContent } });
}

export function raiseValidationError(error) {
  const errorMessage = Resources.TheFollowingValidationErrorsOccurred;

  store.dispatch({
    type: actions.SHOW_MODAL,
    modalType: "errorModal",
    modalProps: { errorMessage, validationErrors: (error.response || {}).data }
  });
}

export function handlePromiseError(error, message, resource, validationHandler) {
  if (window.logToConsole) console.log(error.message);
  let response = error.response;
  if (window.logToConsole) console.log(response);

  if (isLocalHost() !== true && isProduction()) {
    FullStoryEvent("error", { ...error, message, resource });
  }

  if (error.message === "Network error") {
    store.dispatch(networkError());
  } else if (error.response) {
    switch (error.response.status) {
      case 400:
        if (validationHandler) {
          validationHandler();
        } else {
          raiseError(message);
        }
        break;
      case 401:
        window.localStorage.removeItem("saml");
        window.localStorage.removeItem("saml-provider");
        window.localStorage.removeItem("id_token");
        window.localStorage.removeItem("logged_in");
        updateConfig({ AccessToken: undefined });
        store.dispatch({ type: actions.CLEAR_DATA });

        window.location = "/#/login";
        break;
      case 403:
        const methods = { get: "access", put: "create or change", post: "change", delete: "delete" };
        let affectedResource = resource || "resource";
        raiseError(Resources.YouDontHavePermissions(methods[error.config.method] || "change", affectedResource));
        break;
      case 404:
        raiseError(message);
        break;
      case 502:
        isAPILive();
        break;
      default:
        raiseError(message);
    }
  } else if (error.name === "ConversationEditorError") {
    store.dispatch({
      type: actions.SHOW_MODAL,
      modalType: "errorModal",
      modalProps: { errorMessage: error.message, title: error.title }
    });
  } else {
    raiseError(message);
  }

  return error;
}

export const dispatchToProps = dispatch => ({
  raiseError: message => {
    raiseError(message);
  }
});
