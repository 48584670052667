import React, { Component } from "react";
import { connect } from "react-redux";

import { dispatchToProps } from "../store/general-actions";

import UploadNotification from "./notifications/uploadNotification";
import ExportNotification from "./notifications/exportNotification";
import CreatingDisputeNotification from "./notifications/creatingDisputeNotification";
import CreatingPromiseToPayNotification from "./notifications/creatingPromiseToPayNotification";
import DownloadNotification from "./notifications/downloadNotification";
import ContactSubmittingNotification from "./notifications/contactSubmittingNotification";
import ActionNotification from "./notifications/actionNotification";
import Resources from "../lib/resources";

class NotificationManager extends Component {
  render() {
    let { manageStore, ledgerStore, statementsStore, networkStore, conversationsStore } = this.props;

    if (this.props.modal.showNotification === false) {
      return null;
    }

    switch (this.props.modal.notificationType) {
      case "uploadNotification":
        return <UploadNotification {...this.props.modal.notificationProps} />;
      case "exportNotification":
        return <ExportNotification {...this.props.modal.notificationProps} />;
      case "creatingDisputeNotification":
        return <CreatingDisputeNotification {...this.props.modal.notificationProps} />;
      case "creatingPromiseToPayNotification":
        return <CreatingPromiseToPayNotification {...this.props.modal.notificationProps} />;
      case "downloadNotification":
        return <DownloadNotification {...this.props.modal.notificationProps} />;
      case "contactSubmittingNotification":
        return <ContactSubmittingNotification {...this.props.modal.notificationProps} />;
      case "profileUpdateRequestSentNotification":
        return (
          <ActionNotification
            actionPending={
              networkStore.postingAccountProfileUpdateRequest || networkStore.postingContactProfileUpdateRequest
            }
            actionPendingText={Resources.SendingProfileUpdateRequest}
            actionComplete={
              networkStore.postedAccountProfileUpdateRequest || networkStore.postedContactProfileUpdateRequest
            }
            actionCompleteText={Resources.ProfileUpdateRequestSent}
            actionFailed={
              networkStore.failedPostAccountProfileUpdateRequest || networkStore.failedPostContactProfileUpdateRequest
            }
            actionFailedText={Resources.FailedToSendProfileUpdateRequest}
            {...this.props.modal.notificationProps}
          />
        );
      case "uploadingImageNotification":
        return (
          <ActionNotification
            actionPending={
              networkStore.postingCustContactPicture ||
              networkStore.postingCustAccountPicture ||
              networkStore.postingMyProfilePicture
            }
            actionPendingText={Resources.UploadingImage}
            actionComplete={
              networkStore.postedCustContactPicture ||
              networkStore.postedCustAccountPicture ||
              networkStore.postedMyProfilePicture
            }
            actionCompleteText={Resources.UploadedImage}
            actionFailed={
              networkStore.failedPostCustContactPicture ||
              networkStore.failedPostCustAccountPicture ||
              networkStore.failedPostAccountPicture ||
              networkStore.failedPostMyProfilePicture
            }
            actionFailedText={Resources.UploadingImageFailed}
            {...this.props.modal.notificationProps}
          />
        );
      case "contactMarkedPrivateNotification":
        return (
          <ActionNotification
            actionPending={networkStore.updatingContactPrivacy}
            actionPendingText={Resources.UpdatingContactPrivacy}
            actionComplete={networkStore.updatedContactPrivacy}
            actionCompleteText={Resources.UpdatedContactPrivacy}
            actionFailed={networkStore.failedUpdateContactPrivacy}
            actionFailedText={Resources.FailedUpdateContactPrivacy}
            {...this.props.modal.notificationProps}
          />
        );
      case "deletingDocumentNotification":
        return (
          <ActionNotification
            actionPending={networkStore.deletingDocument}
            actionPendingText={Resources.DeletingDocumens}
            actionComplete={networkStore.deletedDocument}
            actionCompleteText={Resources.DeletedDocument}
            actionFailed={networkStore.failedDeleteDocument}
            actionFailedText={Resources.FailedDeleteDocuments}
            {...this.props.modal.notificationProps}
          />
        );
      case "uploadingDocumentNotification":
        return (
          <ActionNotification
            actionPending={networkStore.uploadingAccountDocument || networkStore.postingCustAccountPicture}
            actionPendingText={Resources.UploadingDocument}
            actionComplete={networkStore.uploadAccountDocument || networkStore.postedCustAccountPicture}
            actionCompleteText={Resources.UploadedDocument}
            actionFailed={networkStore.failedUploadAccountDocument || networkStore.failedPostCustAccountPicture}
            actionFailedText={Resources.UploadDocumentFailed}
            {...this.props.modal.notificationProps}
          />
        );
      case "addingContactNotification":
        return (
          <ActionNotification
            actionPending={networkStore.addingContact}
            actionPendingText={Resources.AddingContact}
            actionComplete={networkStore.addedContact}
            actionCompleteText={Resources.AddedContact}
            actionFailed={networkStore.failedAddContact}
            actionFailedText={Resources.FailedAddContact}
            {...this.props.modal.notificationProps}
          />
        );
      case "markingAsPrimaryNotification":
        return (
          <ActionNotification
            actionPending={networkStore.markingContactPrimary}
            actionPendingText={Resources.MarkingContactPrimary}
            actionComplete={networkStore.markedContactPrimary}
            actionCompleteText={Resources.MarkedContactPrimary}
            actionFailed={networkStore.failedMarkContactPrimary}
            actionFailedText={Resources.FailedMarkContactPrimary}
            {...this.props.modal.notificationProps}
          />
        );
      case "addingContactSendingUpdateRequestNotification":
        return (
          <ActionNotification
            actionPending={networkStore.addingContact || networkStore.postingContactProfileUpdateRequest}
            actionPendingText={Resources.AddingContactAndSendingRequest}
            actionComplete={networkStore.addedContact && networkStore.postedContactProfileUpdateRequest}
            actionCompleteText={Resources.AddedContactAndSentRequest}
            actionFailed={networkStore.failedAddContact || networkStore.failedPostContactProfileUpdateRequest}
            actionFailedText={Resources.FailedAddContact}
            {...this.props.modal.notificationProps}
          />
        );
      case "copyToClipboardNotification":
        return (
          <ActionNotification
            actionPending={networkStore.gettingShareLink}
            actionPendingText={Resources.GettingLink}
            actionComplete={networkStore.gotShareLink}
            actionCompleteText={Resources.LinkCopied}
            actionFailed={networkStore.failedGetShareLink}
            actionFailedText={Resources.FailedCopyLink}
            {...this.props.modal.notificationProps}
          />
        );
      case "archiveProfileNotification":
        return (
          <ActionNotification
            actionPending={networkStore.archivingAccount}
            actionPendingText={Resources.ArchivingAccount}
            actionComplete={networkStore.archivedAccount}
            actionCompleteText={Resources.AccountArchived}
            actionFailed={networkStore.failedArchiveAccount}
            actionFailedText={Resources.FailedArchiveAccount}
            {...this.props.modal.notificationProps}
          />
        );
      case "unArchiveProfileNotification":
        return (
          <ActionNotification
            actionPending={networkStore.archivingAccount}
            actionPendingText={Resources.RestoringAccount}
            actionComplete={networkStore.archivedAccount}
            actionCompleteText={Resources.AccountRestored}
            actionFailed={networkStore.failedArchiveAccount}
            actionFailedText={Resources.FailedRestoreAccount}
            {...this.props.modal.notificationProps}
          />
        );
      case "archiveContactNotification":
        return (
          <ActionNotification
            actionPending={networkStore.archivingContact}
            actionPendingText={Resources.ArchivingContact}
            actionComplete={networkStore.archivedContact}
            actionCompleteText={Resources.ArchivedContact}
            actionFailed={networkStore.failedArchiveContact}
            actionFailedText={Resources.FailedToArchiveContact}
            {...this.props.modal.notificationProps}
          />
        );
      case "unArchiveContactNotification":
        return (
          <ActionNotification
            actionPending={networkStore.archivingContact}
            actionPendingText={Resources.RestoringContact}
            actionComplete={networkStore.archivedContact}
            actionCompleteText={Resources.RestoredContact}
            actionFailed={networkStore.failedArchiveContact}
            actionFailedText={Resources.FailedToRestoreContact}
            {...this.props.modal.notificationProps}
          />
        );
      case "updatedContactStatusNotification":
        return (
          <ActionNotification
            actionPending={networkStore.archivingContact}
            actionPendingText={Resources.UpdatingContactStatus}
            actionComplete={networkStore.archivedContact}
            actionCompleteText={Resources.ContactStatusUpdated}
            actionFailed={networkStore.failedArchiveContact}
            actionFailedText={Resources.FailedToUpdateContactStatus}
            {...this.props.modal.notificationProps}
          />
        );
      case "addingPaymentMethodNotification":
        return (
          <ActionNotification
            actionPending={statementsStore.isFetchingPaymentInfo}
            actionPendingText={Resources.AddingPaymentMethod}
            actionComplete={statementsStore.fetchedPaymentInfo}
            actionCompleteText={Resources.AddedPaymentMethod}
            actionFailed={statementsStore.fetchingPaymentInfoFailed}
            actionFailedText={Resources.AddingPaymentMethodFailed}
            {...this.props.modal.notificationProps}
          />
        );
      case "sendingMessageNotification":
        return (
          <ActionNotification
            actionPending={
              ledgerStore.creatingDraft ||
              ledgerStore.isAddingAttachment ||
              ledgerStore.committingDraft ||
              networkStore.postingShareFlyout
            }
            actionPendingText={Resources.SendingMessage}
            actionComplete={(ledgerStore.createdDraft && ledgerStore.committedDraft) || networkStore.postedShareFlyout}
            actionCompleteText={Resources.SentMessage}
            actionFailed={
              ledgerStore.createDraftFailed || ledgerStore.commitDraftFailed || networkStore.failedPostShareFlyout
            }
            actionFailedText={Resources.SendMessageFailed}
            {...this.props.modal.notificationProps}
          />
        );
      case "forwardingMessageNotification":
        return (
          <ActionNotification
            actionPending={
              ledgerStore.forwardingDraft ||
              ledgerStore.isAddingAttachment ||
              ledgerStore.committingDraft ||
              networkStore.postingShareFlyout
            }
            actionPendingText={Resources.ForwardingMessage}
            actionComplete={ledgerStore.forwardedDraft}
            actionCompleteText={Resources.ForwardedMessage}
            actionFailed={ledgerStore.forwardDraftFailed || ledgerStore.commitDraftFailed}
            actionFailedText={Resources.FailedForwardMessage}
            {...this.props.modal.notificationProps}
          />
        );
      case "deleteContactNotification":
        return (
          <ActionNotification
            actionPending={manageStore.deletingCompanyAddressBookContact}
            actionPendingText={Resources.ContactDeleting}
            actionComplete={manageStore.deletedCompanyAddressBookContact}
            actionCompleteText={Resources.ContactDeleted}
            actionFailed={manageStore.failedDeletingCompanyAddressBookContact}
            actionFailedText={Resources.ContactDeleteFailed}
            {...this.props.modal.notificationProps}
          />
        );
      case "sendProfileUpdateRequestNotifiation":
        return (
          <ActionNotification
            actionPending={
              networkStore.postingAccountProfileUpdateRequest || networkStore.postingContactProfileUpdateRequest
            }
            actionPendingText={Resources.SendingProfileUpdateRequest}
            actionComplete={
              networkStore.postedAccountProfileUpdateRequest || networkStore.postedContactProfileUpdateRequest
            }
            actionCompleteText={Resources.ProfileUpdateRequestSent}
            actionFailed={
              networkStore.failedPostAccountProfileUpdateRequest || networkStore.failedPostContactProfileUpdateRequest
            }
            actionFailedText={Resources.FailedToSendProfileUpdateRequest}
            {...this.props.modal.notificationProps}
          />
        );
      case "assigningConversation":
        return (
          <ActionNotification
            actionPending={conversationsStore.assigningConversations}
            actionPendingText={Resources.AssigningConversations}
            actionComplete={conversationsStore.assigningConversations === false}
            actionCompleteText={Resources.AssignedConversations}
            actionFailed={false}
            actionFailedText={Resources.FailedToAssignConversations}
            {...this.props.modal.notificationProps}
          />
        );
      case "markingConversationRead":
        return (
          <ActionNotification
            actionPending={conversationsStore.isUpdatingConversationReadStatuses}
            actionPendingText={Resources.MarkingConversationsRead}
            actionComplete={conversationsStore.updatedConversationReadStatuses}
            actionCompleteText={Resources.MarkedConversationsRead}
            actionFailed={conversationsStore.failedUpdatingConversationReadStatuses}
            actionFailedText={Resources.FailedToMarkConversationsRead}
            {...this.props.modal.notificationProps}
          />
        );
      case "markingConversationUnread":
        return (
          <ActionNotification
            actionPending={conversationsStore.isUpdatingConversationReadStatuses}
            actionPendingText={Resources.MarkingConversationsUnread}
            actionComplete={conversationsStore.updatedConversationReadStatuses}
            actionCompleteText={Resources.MarkedConversationsUnread}
            actionFailed={conversationsStore.failedUpdatingConversationReadStatuses}
            actionFailedText={Resources.FailedToMarkConversationsUnread}
            {...this.props.modal.notificationProps}
          />
        );
      case "closingConversations":
        return (
          <ActionNotification
            actionPending={conversationsStore.settingConversationStatuses}
            actionPendingText={Resources.ClosingConversations}
            actionComplete={conversationsStore.setConversationStatuses}
            actionCompleteText={Resources.ClosedConversations}
            actionFailed={conversationsStore.failedSetConversationStatuses}
            actionFailedText={Resources.FailedToCloseConversations}
            {...this.props.modal.notificationProps}
          />
        );
      case "activatingConversations":
        return (
          <ActionNotification
            actionPending={conversationsStore.settingConversationStatuses}
            actionPendingText={Resources.ActivatingConversaions}
            actionComplete={conversationsStore.setConversationStatuses}
            actionCompleteText={Resources.ActivatedConversations}
            actionFailed={conversationsStore.failedSetConversationStatuses}
            actionFailedText={Resources.FailedToActivateConversations}
            {...this.props.modal.notificationProps}
          />
        );
      case "markingConversationAsSpam":
        return (
          <ActionNotification
            actionPending={conversationsStore.settingConversationStatuses}
            actionPendingText={Resources.MarkingConversationAsSpam}
            actionComplete={conversationsStore.setConversationStatuses}
            actionCompleteText={Resources.MarkedConversationAsSpam}
            actionFailed={conversationsStore.failedSetConversationStatuses}
            actionFailedText={Resources.FailedToMarkConversationAsSpam}
            {...this.props.modal.notificationProps}
          />
        );
      case "snoozingActivity":
        return (
          <ActionNotification
            actionPending={ledgerStore.creatingEntry}
            actionPendingText={Resources.SnoozingActivity}
            actionComplete={ledgerStore.createdEntry}
            actionCompleteText={Resources.SnoozedActivity}
            actionFailed={ledgerStore.createEntryFailed}
            actionFailedText={Resources.FailedToSnoozeActivity}
            {...this.props.modal.notificationProps}
          />
        );
      default:
        return null;
    }
  }
}

const storeToProps = store => {
  return {
    modal: store.modal,
    manageStore: store.manage,
    ledgerStore: store.ledger,
    statementsStore: store.statements,
    networkStore: store.network,
    conversationsStore: store.conversations
  };
};

export default connect(storeToProps, dispatchToProps)(NotificationManager);
