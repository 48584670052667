import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { dispatchToProps as moDP } from "../../store/modal-actions";
import { dispatchToProps as saDP } from "../../store/statements-actions";
import { handlePromiseError } from "../../store/error-actions";

import Resources from "../../lib/resources";
import IconClose from "../library/icons/iconClose";
import IconAngleDown from "../library/icons/iconAngleDown";
import TableData from "../library/tableData";
import TextInput from "../library/textInput";
import "react-datepicker/dist/react-datepicker.css";

import Modal from "react-modal";
import {
  includes,
  updateSelectedRows,
  formatCurrency,
  onBlurCheckFocusable,
  formatDate,
  isEmpty,
  find,
  replaceAt
} from "../../lib/utils";

import { isValidDollarAmount } from "../../lib/validation";
import MainLoader from "../mainLoader";
import HtmlEditor from "../htmlEditor";
import IconPlusCircle from "../library/icons/iconPlusCircle";
import IconAngleRight from "../library/icons/iconAngleRight";
import IconAngleLeft from "../library/icons/iconAngleLeft";
import IconStar from "../library/icons/iconStar";
import IconTrash from "../library/icons/iconTrash";
import IconEdit from "../library/icons/iconEdit";

import { PaymentConfirmationMessage } from "./paymentConfirmationMessage";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...saDP(dispatch)
});

class MakePaymentFlyout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      htmlBody: "",
      textBody: "",
      selectedRows: [],
      selectedPaymentMethod: {},
      paymentView: "main",
      totalAmount: "",
      invoices: [],
      paymentDate: new Date(),

      showFlyout: true
    };

    this.toggleSelectRow = this.toggleSelectRow.bind(this);
    this.makePayment = this.makePayment.bind(this);
    this.addNewPaymentMethod = this.addNewPaymentMethod.bind(this);
    this.hideFlyout = this.hideFlyout.bind(this);
  }

  componentDidMount() {
    const { companyId, withCompanyId, statementsStore } = this.props;

    let stateUpdate = {};

    const paymentInfo = statementsStore.paymentInfo[withCompanyId];
    if (isEmpty(paymentInfo)) {
      this.props.fetchPaymentInfo(companyId, withCompanyId);
    } else {
      const defaultPaymentMethod =
        find(paymentInfo.paymentMethods, pMethod => pMethod.isDefault === true) ||
        paymentInfo.paymentMethods[paymentInfo.paymentMethods.length - 1] ||
        {};

      stateUpdate.selectedPaymentMethod = defaultPaymentMethod;
    }

    if (this.props.selectedInvoices.length > 0) {
      const selectedRows = [];
      let invoices = [];
      for (let i = 0; i < this.props.selectedInvoices.length; i++) {
        const inv = { ...this.props.selectedInvoices[i] };
        inv.paymentAmount = Number.parseFloat(inv.amount).toFixed(2);
        invoices.push(inv);
        selectedRows.push(i);
      }
      stateUpdate.invoices = invoices;
      stateUpdate.selectedRows = selectedRows;
    }

    this.setState(stateUpdate);
  }

  componentDidUpdate(prevProps, prevState) {
    const paymentMethods = this.props.statementsStore.paymentInfo[this.props.withCompanyId].paymentMethods || [];
    if (!isEmpty(paymentMethods) && includes(paymentMethods, this.state.selectedPaymentMethod) !== true) {
      const defaultPaymentMethod =
        find(paymentMethods, pMethod => pMethod.isDefault === true) || paymentMethods[paymentMethods.length - 1] || {};
      this.setState({ selectedPaymentMethod: defaultPaymentMethod });
    }
  }

  hideFlyout() {
    this.setState({ showFlyout: false });
  }

  addNewPaymentMethod() {
    const { companyId, withCompanyId } = this.props;
    this.setState({ paymentView: "addPaymentMethod" });

    this.props
      .addOrUpdatePaymentMethod(companyId, withCompanyId, { name: "", isDefault: false })
      .then(res => {
        this.setState({ iframeUrl: res.redirectUrl });
      })
      .catch(err => null);
  }

  makePayment() {
    const { companyId, withCompanyId } = this.props;
    let invoices = this.state.invoices
      .filter((invoice, index) => includes(this.state.selectedRows, index))
      .map(inv => {
        return { invoiceId: inv.id, amount: Number.parseFloat(inv.paymentAmount) };
      });

    if (invoices.length === 0) {
      invoices = [{ amount: Number.parseFloat(this.state.totalAmount) }];
    }
    this.setState({ paymentView: "makingPayment" });

    const paymentMethodId = this.state.selectedPaymentMethod.paymentMethodId;

    const payload = { paymentMethodId, invoices, paymentDate: new Date(this.state.paymentDate).toUTCString() };
    if (!isEmpty(this.state.textBody)) {
      payload.note = this.state.textBody;
    }

    this.props.makePayment(companyId, withCompanyId, payload).then(res => {
      this.setState({ iframeUrl: res.redirectUrl });
    });
  }

  toggleSelectRow(i) {
    let newSelectedRows = updateSelectedRows(i, this.state.selectedRows, this.props.selectedInvoices.length);

    this.setState({ selectedRows: newSelectedRows });
  }

  renderMainContent() {
    let { withCompanyId, selectedInvoices, columns, statementsStore } = this.props;

    let totalAmount = this.state.invoices.reduce((accum, curr, index) => {
      if (!includes(this.state.selectedRows, index)) {
        return accum;
      }
      return accum + (Number.parseFloat(curr.paymentAmount) || 0);
    }, 0);

    //Ensures no weird JS decimal stuff
    totalAmount = Math.round(totalAmount * 1e12) / 1e12;

    const paymentInfo = statementsStore.paymentInfo[withCompanyId] || {};

    const { allowPartialPayments } = paymentInfo;
    columns = [...columns];

    if (allowPartialPayments === true) {
      columns.splice(5, 0, {
        header: Resources.PaymentAmount.toLocaleUpperCase(),
        content: (row, rowIndex) => (
          <TextInput
            className="m-0"
            dollarAmountInput
            textValue={this.state.invoices[rowIndex].paymentAmount}
            inputOnChange={e => {
              if (isValidDollarAmount(e.target.value)) {
                this.setState({
                  invoices: replaceAt(this.state.invoices, rowIndex, {
                    ...this.state.invoices[rowIndex],
                    paymentAmount: e.target.value
                  })
                });
              }
            }}
            onBlur={() => {
              const rowPaymentAmount = this.state.invoices[rowIndex].paymentAmount;
              if (Number.parseInt(rowPaymentAmount) === 0) {
                this.setState({
                  invoices: replaceAt(this.state.invoices, rowIndex, {
                    ...this.state.invoices[rowIndex],
                    paymentAmount: ""
                  })
                });
              } else if (rowPaymentAmount !== "") {
                this.setState({
                  invoices: replaceAt(this.state.invoices, rowIndex, {
                    ...this.state.invoices[rowIndex],
                    paymentAmount: Number.parseFloat(rowPaymentAmount).toFixed(2)
                  })
                });
              }
            }}
          />
        )
      });
      columns.splice(6, 0, { content: row => null });
    }

    const partialPaymentsWidths = ["3%", "13%", "15%", "7rem", "4%", "8rem", "3%", "14%", "10%"];

    columns = columns.map((col, i) => {
      const _col = { ...col };

      if (allowPartialPayments === true) {
        _col.width = partialPaymentsWidths[i];
      }

      return _col;
    });

    return (
      <React.Fragment>
        <div className="flex">
          <div className="mr-4" style={{ height: "fit-content" }}>
            <div className="portal-input-label">{Resources.PayFrom}</div>
            <div className="dropdown">
              <button
                data-test-id="make-payment__dropdown"
                className="payment-method-dropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {!isEmpty(this.state.selectedPaymentMethod) ? (
                  <span>
                    {isEmpty(this.state.selectedPaymentMethod.name)
                      ? `${
                          this.state.selectedPaymentMethod.paymentType === "ACH"
                            ? Resources.BankAccount
                            : Resources.CreditCard
                        } ...${this.state.selectedPaymentMethod.lastFourDigits}`
                      : this.state.selectedPaymentMethod.name}
                  </span>
                ) : (
                  <span></span>
                )}
                <IconAngleDown height={8} />
              </button>
              <span className="dropdown-menu payment-method-dropdown-menu">
                {(paymentInfo.paymentMethods || []).map((pMethod, i) => (
                  <button
                    data-test-id="make-payment__dropdown-item"
                    key={i}
                    className="dropdown-item"
                    style={{ cursor: "default" }}
                    onClick={e => {
                      this.setState({ selectedPaymentMethod: pMethod });
                    }}
                  >
                    {isEmpty(pMethod.name)
                      ? `${pMethod.paymentType === "ACH" ? Resources.BankAccount : Resources.CreditCard} ...${
                          pMethod.lastFourDigits
                        }`
                      : pMethod.name}
                  </button>
                ))}
                <button
                  data-test-id="make-payment__add-new"
                  className="dropdown-item dropdown-call-to-action"
                  style={{ cursor: "default" }}
                  onClick={this.addNewPaymentMethod}
                >
                  {Resources.AddNewPaymentMethod}
                </button>
              </span>
            </div>
          </div>

          {selectedInvoices.length === 0 && (
            <div className="mx-4" style={{ height: "fit-content" }}>
              <div className={`portal-input-label ${this.state.focusedField === "amount" ? " focused" : ""}`}>
                {Resources.PaymentAmount}
              </div>
              <TextInput
                className="mb-0"
                dollarAmountInput
                textValue={this.state.totalAmount}
                inputOnChange={e => {
                  if (isValidDollarAmount(e.target.value)) {
                    this.setState({
                      totalAmount: e.target.value
                    });
                  }
                }}
                onBlur={() => {
                  onBlurCheckFocusable(() => this.setState({ focusedField: null }));
                  if (Number.parseInt(this.state.totalAmount) === 0) {
                    this.setState({ totalAmount: "" });
                  } else if (this.state.totalAmount !== "") {
                    this.setState({
                      totalAmount: Number.parseFloat(this.state.totalAmount)
                        .toFixed(2)
                        .toString()
                    });
                  }
                }}
                onFocus={() => this.setState({ focusedField: "amount" })}
              />
            </div>
          )}
          {/* Date picker for future payments */}
          {/* <div className="ml-4">
            <div className={`portal-input-label ${this.state.focusedField === "expectedDate" ? " focused" : ""}`}>
              {Resources.PaymentDate}
            </div>
            <DatePicker
              className="date-picker-input"
              calendarClassName="date-picker-calendar"
              dayClassName={d => "date-picker-day"}
              dateFormat="MMM d, yyyy"
              shouldCloseOnSelect
              minDate={new Date()}
              selected={new Date(this.state.paymentDate)}
              onChange={d =>
                this.setState({
                  paymentDate: moment(d)
                    .startOf("day")
                    .format("YYYY-MM-DD HH:mm:ss")
                })
              }
              onFocus={() => this.setState({ focusedField: "expectedDate" })}
              onBlur={e => onBlurCheckFocusable(() => this.setState({ focusedField: null }))}
            />
          </div> */}
        </div>
        <button
          data-test-id="make-payment__manage"
          className="button-secondary"
          onClick={() => this.setState({ paymentView: "managePaymentMethods" })}
          style={{ padding: "0.27rem", marginBottom: "2.66rem" }}
        >
          {Resources.ManagePaymentMethods}
          <IconAngleRight height={12} strokeWidth="3" style={{ marginRight: "0.65rem" }} />
        </button>
        {selectedInvoices.length > 0 ? (
          <div className="flyout-table-container">
            <div className="fw-500" style={{ marginBottom: "0.9rem" }}>
              {Resources.InvoicesSelectedForPayment(this.state.selectedRows.length)}
            </div>
            {this.props.filteredOutCreditMemo && (
              <p style={{ marginBottom: "1rem", fontSize: "0.933rem" }}>
                {Resources.MakePaymentFlyoutFilteredOutCreditMemo}
              </p>
            )}
            <TableData
              noSort
              noGrow
              pagination
              data={this.state.invoices}
              columns={columns}
              rowHeight="4rem"
              rowClassName="statements-view-row no-hover"
              selectedRows={this.state.selectedRows}
              onRowSelectToggle={i => this.toggleSelectRow(i)}
            />
            <div className="fw-500" style={{ fontSize: "1.33rem", marginBottom: "5.3rem", marginTop: "2rem" }}>
              {Resources.TotalPaymentAmount(formatCurrency(totalAmount))}
            </div>
          </div>
        ) : (
          <div className="payment-editor-container">
            <div className={`portal-input-label ${this.state.focusedField === "message" ? " focused" : ""}`}>
              {Resources.Message}
            </div>
            <HtmlEditor
              onFocus={() => this.setState({ focusedField: "message" })}
              onBlur={e => onBlurCheckFocusable(() => this.setState({ focusedField: null }))}
              hideToolbar={this.state.focusedField !== "message"}
              htmlContent={this.state.htmlBody}
              updateHtmlContent={htmlBody => this.setState({ htmlBody })}
              updateTextContent={textBody => this.setState({ textBody })}
            />
          </div>
        )}
        <button
          data-test-id="make-payment__pay"
          className="portal-button-green"
          onClick={this.makePayment}
          disabled={
            (this.state.selectedRows.length < 1 || totalAmount === 0) &&
            (isEmpty(this.state.totalAmount) || isEmpty(this.state.textBody))
          }
        >
          {Resources.Pay}
        </button>
      </React.Fragment>
    );
  }
  renderAddOrEditMethodContent() {
    if (isEmpty(this.state.iframeUrl)) {
      return (
        <div className="flex-center flex-align-center payment-method-iframe">
          <MainLoader fullscreen></MainLoader>
        </div>
      );
    }

    const hostname = window.location.hostname;

    return (
      <iframe
        id="newPaymentIframe"
        src={this.state.iframeUrl}
        title="New Payment method"
        className="payment-method-iframe"
        onLoad={() => {
          const iframe = document.getElementById("newPaymentIframe");
          try {
            if (includes(iframe.contentWindow.location.href, hostname)) {
              this.props.displayNotification("addingPaymentMethodNotification");
              this.setState({ paymentView: "main", iframeUrl: null });
              this.props.fetchPaymentInfo(this.props.companyId, this.props.withCompanyId).then(res => {
                const paymentInfo = this.props.statementsStore.paymentInfo[this.props.withCompanyId].paymentMethods;
                if (paymentInfo.length > 0) {
                  this.setState({
                    selectedPaymentMethod: paymentInfo[paymentInfo.length - 1]
                  });
                }
              });
            }
          } catch {
            return;
          }
        }}
      ></iframe>
    );
  }

  renderManagePaymentMethodsContent() {
    const paymentMethods = (this.props.statementsStore.paymentInfo[this.props.withCompanyId] || {}).paymentMethods;
    const columns = [
      {
        header: Resources.PaymentMethod,
        content: row => (
          <span>
            <span className="fw-500">{`${row.paymentType === "ACH" ? Resources.BankAccount : Resources.CreditCard} ...${
              row.lastFourDigits
            }${row.name ? ` (${row.name})` : ""}`}</span>
            {moment(row.expirationDate, "MM/YY").isBefore() && (
              <span className="expired-label">{Resources.Expired.toLocaleUpperCase()}</span>
            )}
          </span>
        ),
        width: "35%"
      },
      {
        header: Resources.DateAdded,
        content: row => formatDate(new Date(), false, false),
        width: "18%"
      },
      {
        header: Resources.Type,
        content: row => (row.paymentType === "ACH" ? Resources.ACH : Resources.CreditCard),
        width: "18%"
      },
      {
        width: "30%",
        content: row => (
          <div className="flex-end flex-align-center">
            <button
              data-test-id="make-payment__default"
              className="button-action-icon"
              onClick={() =>
                this.props
                  .addOrUpdatePaymentMethod(this.props.companyId, this.props.withCompanyId, {
                    paymentMethodId: row.paymentMethodId,
                    isDefault: true
                  })
                  .then(res => {
                    this.props.fetchPaymentInfo(this.props.companyId, this.props.withCompanyId);
                  })
                  .catch(err => {
                    console.log("Making default payment method error");
                  })
              }
            >
              <IconStar height="21" isFilled={row.isDefault} />
            </button>
            <button
              data-test-id="make-payment__edit"
              className="button-action-icon"
              onClick={() => {
                this.setState({ paymentView: "editPaymentMethod" });
                this.props
                  .addOrUpdatePaymentMethod(this.props.companyId, this.props.withCompanyId, {
                    paymentMethodId: row.paymentMethodId
                  })
                  .then(res => {
                    this.setState({ iframeUrl: res.redirectUrl });
                  })
                  .catch(err => null);
              }}
            >
              <IconEdit height="21" />
            </button>
            <button
              data-test-id="make-payment__trash"
              className="button-action-icon"
              onClick={() =>
                this.props.deletePaymentMethod(this.props.companyId, this.props.withCompanyId, row.paymentMethodId)
              }
            >
              <IconTrash height="21" />
            </button>
          </div>
        )
      }
    ];

    const tableHeight = paymentMethods.length > 6 ? "28rem" : `${4 * paymentMethods.length + 2}rem`;
    return (
      <div className="flyout-table-container">
        <button
          data-test-id="make-payment__add-new-pmt"
          className="button-primary mb-5"
          onClick={this.addNewPaymentMethod}
        >
          <IconPlusCircle height="20" className="button-primary-icon" />
          {Resources.AddNewPaymentMethod}
        </button>

        {!isEmpty(paymentMethods) && (
          <TableData
            noGrow
            data={paymentMethods}
            columns={columns}
            rowHeight="4rem"
            maxHeight={tableHeight}
            rowClassName="statements-view-row no-hover"
          />
        )}
        <button
          data-test-id="make-payment__done"
          className="portal-button-green"
          style={{ marginTop: "5.3rem" }}
          onClick={() => this.setState({ paymentView: "main" })}
        >
          {Resources.Done}
        </button>
      </div>
    );
  }

  renderMakingPaymentContent() {
    const { companyId, withCompanyId, perspectiveId, pageRowCount } = this.props;
    if (isEmpty(this.state.iframeUrl)) {
      return (
        <div className="flex-center flex-align-center payment-method-iframe">
          <MainLoader fullscreen></MainLoader>
        </div>
      );
    }

    return (
      <iframe
        id="makingPaymentIframe"
        src={this.state.iframeUrl}
        title="Making payment"
        className="payment-method-iframe"
        onLoad={() => {
          const iframe = document.getElementById("makingPaymentIframe");

          try {
            const href = iframe.contentWindow.location.href;

            if (includes(href, "/payment/failure")) {
              const errorMessage = href.slice(href.lastIndexOf("/") + 1);
              this.setState({ paymentView: "main", iframeUrl: null });
              handlePromiseError(null, errorMessage);
            } else if (includes(href, "/payment/success")) {
              this.props.fetchUnappliedPayments(companyId, perspectiveId, withCompanyId, null, null, pageRowCount);

              const transactionId = href.slice(href.lastIndexOf("/") + 1);
              this.setState({ paymentView: "paymentConfirmation", transactionId });
            }
          } catch {
            return;
          }
        }}
      ></iframe>
    );
  }

  renderPaymentConfirmationContent() {
    const columns = this.props.columns.slice(1);
    let totalAmount = this.state.invoices.reduce((accum, curr, index) => {
      if (!includes(this.state.selectedRows, index)) {
        return accum;
      }
      return accum + (Number.parseFloat(curr.paymentAmount) || 0);
    }, 0);

    if (isEmpty(this.state.invoices)) {
      totalAmount = Number.parseFloat(this.state.totalAmount);
    }

    //Ensures no weird JS decimal stuff
    totalAmount = Math.round(totalAmount * 1e12) / 1e12;

    const paidInvoices = this.state.invoices.filter((inv, i) => includes(this.state.selectedRows, i));

    return (
      <Modal
        isOpen={this.state.showFlyout}
        onRequestClose={this.hideFlyout}
        onAfterClose={this.props.hideFlyout}
        className="flyout make-payment-flyout"
        overlayClassName="flyout-overlay"
        closeTimeoutMS={400}
      >
        <div className="flex-end">
          <div
            onClick={() => {
              this.hideFlyout();
              this.props.fetchOpenInvoices(
                this.props.companyId,
                this.props.perspectiveId,
                this.props.withCompanyId,
                null,
                null,
                this.props.pageRowCount
              );
              this.props.clearSelectedRows();
            }}
            className="flyout-heading-close"
          >
            <IconClose />
          </div>
        </div>
        <PaymentConfirmationMessage transactionId={this.state.transactionId || "342"} />
        <div className="confirmation-payment-divider"></div>

        <div className="flyout-content">
          <div style={{ marginBottom: "2.8rem" }}>
            <div className="d-inline-block" style={{ marginRight: "8.06rem" }}>
              <div className="portal-input-label">{Resources.PaidFrom}</div>
              <div className="payment-field">{`${
                this.state.selectedPaymentMethod.paymentType === "ACH" ? Resources.BankAccount : Resources.CreditCard
              } ...${this.state.selectedPaymentMethod.lastFourDigits}`}</div>
            </div>
            <div className="d-inline-block">
              <div className="portal-input-label">{Resources.PaymentDate}</div>
              <div className="payment-field">{formatDate(new Date(), false, false)}</div>
            </div>
            {paidInvoices.length === 0 && (
              <div className="d-inline-block" style={{ marginLeft: "8.06rem" }}>
                <div className="portal-input-label">{Resources.PaymentAmount}</div>
                <div className="payment-field">{formatCurrency(totalAmount)}</div>
              </div>
            )}
          </div>
          {paidInvoices.length > 0 ? (
            <React.Fragment>
              <div className="fw-500" style={{ marginBottom: "1.46rem" }}>
                {Resources.InvoicesPaid(this.state.selectedRows.length)}
              </div>
              <TableData
                noSort
                noGrow
                pagination
                data={paidInvoices}
                columns={columns}
                rowHeight="4rem"
                rowClassName="statements-view-row no-hover"
              />
              <div className="fw-500" style={{ fontSize: "1.33rem", marginBottom: "5.3rem", marginTop: "2rem" }}>
                {Resources.TotalPaymentAmount(formatCurrency(totalAmount))}
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="portal-input-label">{Resources.Message}</div>
              <div className="payment-field">{this.state.textBody}</div>
            </React.Fragment>
          )}
        </div>
      </Modal>
    );
  }

  render() {
    let header = "";
    let content = null;
    const goBackButton = (
      <span style={{ display: "inline-flex", marginRight: "2.15rem" }}>
        <IconAngleLeft />
      </span>
    );

    switch (this.state.paymentView) {
      case "addPaymentMethod":
        header = (
          <div className="d-flex align-items-center">
            {goBackButton}
            {Resources.AddNewPaymentMethod}
          </div>
        );
        content = this.renderAddOrEditMethodContent();
        break;
      case "editPaymentMethod":
        header = (
          <div className="d-flex align-items-center">
            {goBackButton}
            {Resources.EditPaymentMethod}
          </div>
        );
        content = this.renderAddOrEditMethodContent();
        break;
      case "managePaymentMethods":
        header = (
          <div className="d-flex align-items-center">
            {goBackButton}
            {Resources.ManagePaymentMethods}
          </div>
        );
        content = this.renderManagePaymentMethodsContent();
        break;

      case "makingPayment":
        header = Resources.MakeAPayment;
        content = this.renderMakingPaymentContent();
        break;
      case "paymentConfirmation":
        return this.renderPaymentConfirmationContent();
      case "main":
      default:
        header = Resources.MakeAPayment;
        content = this.renderMainContent();
        break;
    }

    return (
      <Modal
        isOpen={this.state.showFlyout}
        onRequestClose={this.hideFlyout}
        onAfterClose={this.props.hideFlyout}
        className="flyout make-payment-flyout"
        overlayClassName="flyout-overlay"
        closeTimeoutMS={400}
      >
        <div className="flyout-heading">
          <div>{header}</div>
          <div onClick={this.hideFlyout} className="flyout-heading-close">
            <IconClose />
          </div>
        </div>
        <div className="flyout-content">{content}</div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal,
    statementsStore: store.statements,
    pageRowCount: store.general.pageRowCount
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(MakePaymentFlyout));
