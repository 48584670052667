import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";
import { isEmpty, getConfig } from "../lib/utils";

import DepartmentView from "./departmentView";
import SignUpContinued from "./signUpContinued";
import SignUpFailed from "./signUpFailed";
import CompanySelect from "./company_select/companySelect";
import Login from "./login";
import Registration from "./registration";
import SideNav from "./sideNav";
import SetUpLandingPage from "./setupLandingPage";
import ReportsPage from "./reportsPage";
import ResetPassword from "./resetPassword";
import InvitedSetPassword from "./invitedSetPassword";
import { dispatchToProps as regDP } from "../store/registration-actions";
import { dispatchToProps as uaDP } from "../store/user-actions";
import { dispatchToProps as locDP } from "../store/localization-actions";
import { dispatchToProps as aaDP } from "../store/accounts-actions";
import { dispatchToProps as gaDP } from "../store/general-actions";
import { FullStoryAPI } from "react-fullstory";
import { isLocalHost, isProduction } from "../lib/utils";
import ManageConnectors from "./manageConnectors";
import ERPRegistration from "./ERPRegistration";
import CompanyEmailSettings from "./companyEmailSettings";
import MyCompanyProfile from "./myCompanyProfile";
import ManageCompanyUsers from "./manageCompanyUsers";
import ManageApprovers from "./manageApprovers";
import ManageTemplates from "./manageTemplates";
import ManageCommunicationTemplates from "./manage/manageCommunicationTemplates";
import ManageSequences from "./manage/manageSequences";
import ManageCustomerGroups from "./manage/customer-groups/manageCustomerGroups";
import SharedDocuments from "./sharedDocuments";
import ERPConfiguration from "./ERPConfiguration";
import EditAccountingPackage from "./editAccountingPackage";
import Toast from "./library/toast";
import ModalManager from "./modalManager";
import FlyoutManager from "./flyoutManager";
import NotificationManager from "./notificationManager";
import SearchViewModal from "./searchViewModal";
import ManageWorkflows from "./manageWorkflows";
import ManageContacts from "./manageContacts";
import ApprovalRequest from "./approvalRequest";
import SiteDown from "./siteDown";

import MyProfile from "./myProfile";
import CustomerPortal from "./customer_portal/customerPortal";
import NotificationSettings from "./notificationSettings";
import ManageSignature from "./manageSignature";
import PaymentConfirmationMessage from "./customer_portal/paymentConfirmationMessage";
import SignupView from "./lockstep/views/signup/signupView";

import Lockstep from "./lockstep/lockstep";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.syncToStorage = this.syncToStorage.bind(this);
  }

  syncToStorage(e) {
    if (e.key === "logged_in" && e.newValue !== "true") {
      this.props.logout();
    } else if (e.key === "id_token" && !isEmpty(e.newValue) && e.newValue !== this.props.userStore.token) {
      const prevUserId = (this.props.userStore.decoded || {}).sub;
      this.props.loggedIn(e.newValue);
      if (jwt_decode(e.newValue).sub !== prevUserId) {
        window.location.hash = "/";
        window.location.reload();
      }
    }
  }

  componentDidMount() {
    this.tryUpdate();
    this.props.isAPILive();
    window.addEventListener("storage", this.syncToStorage);
    this.props.setBrowserLanguage();
    if (this.props.isLoggedIn) {
      this.props.getAllLanguages();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.syncToStorage);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoggedIn === true) {
      return;
    }
    this.tryUpdate();
  }

  tryUpdate() {
    // This only hits on mount, before user is logged in, and once right after user is logged in
    if (this.props.isLoggedIn && this.props.APILive === true) {
      this.props.getAllLanguages();

      let userId = this.props.getMyUserIdFromToken();
      let metaData = {
        displayName: this.props.getMyDisplayNameFromToken(),
        userName: this.props.getMyUserNameFromToken()
      };
      if (this.props.isUserImpersonated()) {
        userId = `ADMIN_AS_${userId}`;
        metaData = { ...metaData, displayName: "Lockstep Administrator" };
      }

      if (window.Intercom) {
        window.Intercom("shutdown");
        window.Intercom("boot", {
          appId: getConfig().intercomId,
          userId: userId,
          name: metaData.displayName,
          email: metaData.userName
        });
        window.Intercom("update", { hide_default_launcher: false });
      }
    }

    try {
      if (
        this.props.isLoggedIn !== true &&
        window.localStorage.getItem("logged_in") &&
        !window.localStorage.getItem("is_registering")
      ) {
        const idToken = window.localStorage.getItem("id_token");
        let dateNow = new Date();
        if (idToken) {
          const jwt = jwt_decode(idToken);
          if (jwt.exp >= dateNow.getTime() / 1000) {
            this.props.loggedIn(idToken);
            this.props.resetRegistrationState();
          } else {
            this.props.logout();
          }
        }
      }
    } catch {} // DOMExceptions are thrown in cross-origin contexts under newer versions of Chrome, so we'll use the default for now
  }

  render() {
    const isAdmin = this.props.isCompanyAdmin();

    // Set the default state for these in the event that localStorage is blocked by Chrome for iframe embedding
    let isRegistering = true;
    let isLoggedIn = false;

    try {
      isRegistering = window.localStorage.getItem("is_registering") === "true";
      isLoggedIn = this.props.isLoggedIn === true || window.localStorage.getItem("logged_in");
    } catch {} // DOMExceptions are thrown in cross-origin contexts under newer versions of Chrome, so we'll use the default for now

    let content = () => {
      if (this.props.APILive === false) {
        return <SiteDown />;
      } else if (
        window.location.hash.startsWith("#/approvalrequest") ||
        window.location.hash.startsWith("#/reset-password") ||
        window.location.hash.startsWith("#/login") ||
        window.location.hash.startsWith("#/invited") ||
        window.location.hash.startsWith("#/code") ||
        window.location.hash.startsWith("#/provider") ||
        window.location.hash.startsWith("#/register") ||
        window.location.hash.startsWith("#/signup") ||
        window.location.hash.startsWith("#/payment") ||
        (isRegistering && window.location.hash === "#/")
      ) {
        return (
          <div>
            <ModalManager />
            <FlyoutManager />
            <NotificationManager />
            <Route exact path="/register" component={Registration} />
            <Route
              exact
              path={["/signup/:type/:view", "/signup/:type/:view/:mode/:accessId", "/signup", "/signup/:type"]}
              component={SignupView}
            />
            <Route exact path="/invited/:inviteCode?" component={InvitedSetPassword} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/reset-password/:resetCode?" component={ResetPassword} />
            <Route exact path="/approvalrequest/:conversationId/:signature" component={ApprovalRequest} />
            <Route exact path="/payment/method/success" component={PaymentConfirmationMessage} />
            <Route exact path="/payment/success/:transactionId" component={PaymentConfirmationMessage} />
            <Route exact path="/payment/failure/:message" component={PaymentConfirmationMessage} />
          </div>
        );
      } else if (isLoggedIn) {
        return (
          <div className="p-0 m-0 h-100">
            <ModalManager />
            <FlyoutManager />
            <NotificationManager />
            <Route path="/:section/:companyId?/:perspectiveId?/:view?" component={SideNav} />
            <Route path="/company/:companyId/:perspectiveId/:rest*/search" component={SearchViewModal} />
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/" component={CompanySelect} />
              {/* Customer Portal */}
              <Route path="/customer-portal/:portalView?" component={CustomerPortal} />
              <Route path="/v2/:companyId?/:perspectiveId?/:view?" render={props => <Lockstep {...props} />} />
              <Route
                path="*"
                render={props => (
                  <div id="with-side-nav-content">
                    <Toast />
                    {/* <Route exact path="/" component={CompanySelect} /> */}
                    <Route exact path="/disconnect" component={CompanySelect} />
                    <Route exact path="/code=(.*)" component={CompanySelect} />

                    <Switch>
                      {/* User settings */}
                      <Route exact path="/company/:companyId/manage/user-profile" component={MyProfile} />
                      <Route exact path="/company/:companyId/manage/notifications" component={NotificationSettings} />
                      <Route exact path="/company/:companyId/manage/signature" component={ManageSignature} />
                      {/* Manage Section */}
                      <Redirect exact from="/company/:companyId/manage" to="/company/:companyId/manage/workspaces" />
                      <Redirect
                        exact
                        from="/company/:companyId/manage/setup"
                        to="/company/:companyId/manage/workspaces"
                      />
                      <Route exact path="/company/:companyId/manage/profile" component={MyCompanyProfile} />
                      <Route
                        exact
                        path="/company/:companyId/manage/workspaces"
                        render={props => {
                          return isAdmin === false ? (
                            <Redirect to={`/company/${props.match.params.companyId}/manage/profile`} />
                          ) : (
                            <CompanyEmailSettings {...props} />
                          );
                        }}
                      />
                      <Route
                        exact
                        path="/company/:companyId/manage/packages"
                        render={({ match }) => {
                          return isAdmin === false ? (
                            <Redirect to={`/company/${match.params.companyId}/manage/profile`} />
                          ) : (
                            <ManageConnectors companyId={match.params.companyId} />
                          );
                        }}
                      />
                      <Route
                        exact
                        path="/company/:companyId/manage/packages/registering"
                        render={props => {
                          return isAdmin === false ? (
                            <Redirect to={`/company/${props.match.params.companyId}/manage/profile`} />
                          ) : (
                            <ERPRegistration {...props} />
                          );
                        }}
                      />
                      <Route
                        exact
                        path="/company/:companyId/manage/packages/configure"
                        render={props => {
                          return isAdmin === false ? (
                            <Redirect to={`/company/${props.match.params.companyId}/manage/profile`} />
                          ) : (
                            <ERPConfiguration {...props} />
                          );
                        }}
                      />
                      <Route
                        path="/company/:companyId/manage/packages/edit"
                        render={props => {
                          return isAdmin === false ? (
                            <Redirect to={`/company/${props.match.params.companyId}/manage/profile`} />
                          ) : (
                            <EditAccountingPackage {...props} />
                          );
                        }}
                      />
                      <Route exact path="/company/:companyId/manage/users" component={ManageCompanyUsers} />
                      <Route exact path="/company/:companyId/manage/approvers" component={ManageApprovers} />
                      <Route exact path="/company/:companyId/manage/workflows" component={ManageWorkflows} />
                      <Route exact path="/company/:companyId/manage/contacts" component={ManageContacts} />
                      <Route exact path="/company/:companyId/manage/templates" component={ManageTemplates} />
                      <Route
                        exact
                        path="/company/:companyId/manage/communication-templates"
                        component={ManageCommunicationTemplates}
                      />
                      <Route exact path="/company/:companyId/manage/account-groups" component={ManageCustomerGroups} />
                      <Route exact path="/company/:companyId/manage/shared-documents" component={SharedDocuments} />

                      <Route
                        exact
                        path="/company/:companyId/manage/sequences/:sequenceView?/:sequenceId?"
                        component={ManageSequences}
                      />

                      <Redirect exact from="/company/:companyId/reports" to="/company/:companyId/reports/tasks" />
                      <Route
                        exact
                        path="/company/:companyId/reports/:view?"
                        render={props => {
                          return isAdmin === false ? <Redirect to={`/`} /> : <ReportsPage {...props} />;
                        }}
                        component={ReportsPage}
                      />

                      {/* Workspace Section */}
                      <Redirect from="/company" to="/v2" />
                      <Route
                        path="/company/:companyId/:perspectiveId/:view?/:selectedContextGroupId?/:selectedItemId?/:activityType?"
                        component={DepartmentView}
                      />
                    </Switch>

                    <Route exact path="/finish-signup" component={SignUpContinued} />
                    <Route exact path="/signup-failed" component={SignUpFailed} />
                    <Route exact path="/company/:companyId/setup" component={SetUpLandingPage} />
                  </div>
                )}
              />
            </Switch>
          </div>
        );
      } else {
        return <Redirect to={`/login?next=${this.props.location.pathname}`} />;
      }
    };

    return content();
  }
}

const storeToProps = store => {
  return {
    isLoggedIn: store.user.isLoggedIn,
    fetchedCompanyRoles: store.accounts.fetchedCompanyRoles,
    userStore: store.user,
    APILive: store.general.isAPILive,
    languageId: store.localization.languageId
  };
};

const dispatchToProps = dispatch => {
  return {
    ...regDP(dispatch),
    ...uaDP(dispatch),
    ...locDP(dispatch),
    ...aaDP(dispatch),
    ...gaDP(dispatch)
  };
};

export default connect(storeToProps, dispatchToProps)(Layout);
