import { Component } from "react";
import React from "react";
import Resources from "../../../lib/resources";
import { connect } from "react-redux";
import { dispatchToProps as netDP } from "../../../store/network-actions";
import { generatePath, withRouter } from "react-router-dom";
import MainLoader from "../../mainLoader";
import CelebrateSVG from "../../library/svgComponents/celebrateSVG";
import DoneSVG from "../../library/svgComponents/doneSVG";

const dispatchToProps = dispatch => ({
  ...netDP(dispatch)
});

class SuccessPage extends Component {
  componentDidMount() {
    this.props.getSelfServiceStatus();
  }

  renderSuccesPage(lockstepverified) {
    if (lockstepverified) {
      return (
        <div className="profile-update-success-container-verified">
          <h2 className="first-time-title-requests">{Resources.LockstepSucess}</h2>
          {/* <div className="success-page-marketing-video-placeholder">marketingvideo.exe not found</div> */}
          {this.props.networkStore.selfServiceStatus.claim ? (
            <button
              disabled={!this.props.networkStore.selfServiceStatus.claim}
              onClick={() => {
                this.props.fetchCustAccount(this.props.match.params.accessId, this.props.match.params.mode);
                this.props.fetchCustAccountContacts(this.props.match.params.accessId, this.props.match.params.mode);
                this.props.history.push(
                  generatePath("/signup/account/login/:mode/:accessId ", {
                    accessId: this.props.accessId,
                    mode: this.props.mode
                  })
                );
              }}
              className="button-primary-square large"
            >
              {Resources.ClaimYourProfile}
            </button>
          ) : null}
        </div>
      );
    }
  }

  render() {
    if (this.props.networkStore.gettingSelfServiceStatus) {
      return <MainLoader className="activity-loader" fullScreen={true} />;
    } else if (this.props.conversion) {
      return (
        <div className="success-page-celebrate">
          <CelebrateSVG />
          <div className="text-container">
            <h2>{Resources.ConvertSuccessMessage}</h2>
          </div>
          <button className="button-primary-square large">
            <a href="https://lockstephq.com/product">{Resources.ExploreOurFeatures}</a>
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <div className="profile-update-done-icon-container">
            <DoneSVG />
          </div>
          {this.renderSuccesPage(this.props.lockstepverified)}
        </div>
      );
    }
  }
}

const storeToProps = store => {
  return {
    networkStore: store.network
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(SuccessPage));
