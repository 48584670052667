import React, { Component } from "react";
import { withRouter, NavLink, generatePath } from "react-router-dom";
import { connect } from "react-redux";

import Resources from "../../../lib/resources";
import { isEmpty, getATCEnvUrl } from "../../../lib/utils";

import Avatar from "../../library/avatar";
import IconCheckCircle from "../../library/icons/iconCheckCircle";
import IconApps from "../../library/icons/iconApps";
import SearchBar from "../searchBar";
import Dropdown from "../../library/dropdown";

import { dispatchToProps as userDP } from "../../../store/user-actions";
import { dispatchToProps as persDP } from "../../../store/perspectives-actions";
import IconLeftArrow from "../../library/icons/iconLeftArrow";
import FeatureGate from "../../featureGate";

const dispatchToProps = dispatch => ({
  ...userDP(dispatch),
  ...persDP(dispatch)
});
class ViewContentHeader extends Component {
  backButtonClick() {
    let { onClickBackRoute } = this.props;

    if (!!onClickBackRoute) {
      this.props.history.push(onClickBackRoute);
    } else {
      this.props.history.goBack();
    }
  }

  renderAccountViewingContent() {
    let { accountViewing } = this.props;

    return (
      <div className="view-content-header-account">
        <button className="button-action-icon" onClick={() => this.backButtonClick()}>
          <IconLeftArrow />
        </button>
        <Avatar
          height="3.6rem"
          type="account"
          imgURL={accountViewing.logoURL}
          objectName={accountViewing.companyName || accountViewing.custName || accountViewing.vendorName}
        />
        <div className="display-name-header">
          <div className="display-name">
            <h2>
              {accountViewing.companyName ||
                accountViewing.custName ||
                accountViewing.vendorName ||
                accountViewing.displayName}
            </h2>
            {accountViewing.isArchived ? (
              <Dropdown
                buttonContent={
                  <React.Fragment>
                    <div className="badge-archived">{Resources.Archived.toLocaleUpperCase()}</div>
                  </React.Fragment>
                }
              >
                <div className="lockstep-verified-dropdown-content">{Resources.LockstepPrivateInformation}</div>
              </Dropdown>
            ) : accountViewing.lockstepVerified ? (
              <div className="lockstep-verified-checkmark-container">
                <Dropdown
                  buttonContent={
                    <React.Fragment>
                      <IconCheckCircle height={14} className="lockstep-verified-checkmark-icon" />
                    </React.Fragment>
                  }
                >
                  <div className="lockstep-verified-dropdown-content">{Resources.LockstepVerifiedInformation}</div>
                </Dropdown>
              </div>
            ) : null}
          </div>
          <span className="customer-id-display">
            {this.props.perspectivesStore.selectedPerspectiveType === "vendors"
              ? Resources.CustomerID
              : Resources.VendorID}
            : {accountViewing.displayId}
          </span>
        </div>
      </div>
    );
  }

  renderContactViewingContent() {
    let { contactViewing } = this.props;

    return (
      <React.Fragment>
        <div className="view-content-header-account">
          <button className="button-action-icon" onClick={() => this.backButtonClick()}>
            <IconLeftArrow />
          </button>
          <Avatar
            height="3.6rem"
            type="contact"
            imgURL={contactViewing.profilePictureURL}
            objectName={contactViewing.contactName}
          />
          <div className="heading-container">
            <div className="name-badge-container">
              <h2>{contactViewing.contactName}</h2>
              {contactViewing.isArchived ? (
                <div className="archived-badge-container">
                  <Dropdown
                    buttonContent={
                      <React.Fragment>
                        <div className="badge-archived">{Resources.Archived.toLocaleUpperCase()}</div>
                      </React.Fragment>
                    }
                  >
                    <div className="lockstep-verified-dropdown-content">{Resources.LockstepArchivedInfo}</div>
                  </Dropdown>
                </div>
              ) : null}
              {contactViewing.isPrivate ? (
                <div className="private-badge-container">
                  <Dropdown
                    buttonContent={
                      <React.Fragment>
                        <div className="badge-private">{Resources.Private.toLocaleUpperCase()}</div>
                      </React.Fragment>
                    }
                  >
                    <div className="lockstep-verified-dropdown-content">{Resources.LockstepPrivateInformation}</div>
                  </Dropdown>
                </div>
              ) : null}
              {contactViewing.isLockstepVerified && !contactViewing.isPrivate && !contactViewing.isArchived ? (
                <div className="lockstep-verified-checkmark-container">
                  <Dropdown
                    buttonContent={<IconCheckCircle height={14} className="lockstep-verified-checkmark-icon" />}
                  >
                    <div className="lockstep-verified-dropdown-content">{Resources.LockstepVerifiedInformation}</div>
                  </Dropdown>
                </div>
              ) : null}
            </div>
            <div className="contacts-subtext">
              {contactViewing.isPrimaryContact ? (
                <span className="contacts-table-subtext">{Resources.Primary}</span>
              ) : null}
              {contactViewing.isSecondaryContact ? (
                <span className="contacts-table-subtext">{Resources.Secondary}</span>
              ) : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderSequenceViewingContent() {
    let { sequenceViewing } = this.props;

    return (
      <div className="view-content-header-sequence">
        <button className="button-action-icon" onClick={() => this.backButtonClick()}>
          <IconLeftArrow />
        </button>
        <h2>{sequenceViewing.customerSequenceName}</h2>
        {sequenceViewing.isLockstepVerified ? (
          <div className="lockstep-verified-checkmark-container">
            <Dropdown buttonContent={<IconCheckCircle height={14} className="lockstep-verified-checkmark-icon" />}>
              <div className="lockstep-verified-dropdown-content">{Resources.LockstepVerifiedInformation}</div>
            </Dropdown>
          </div>
        ) : null}
      </div>
    );
  }

  renderOpenRequestsContent() {
    return (
      <div className="view-content-header-requests">
        <button className="button-action-icon" onClick={() => this.backButtonClick()}>
          <IconLeftArrow />
        </button>
        <h2>{Resources.OpenRequests}</h2>
      </div>
    );
  }

  renderMyProfileContent() {
    let { myProfile } = this.props;

    return (
      <div className="view-content-header-myProfile">
        <button className="button-action-icon" onClick={() => this.backButtonClick()}>
          <IconLeftArrow />
        </button>
        <span>
          <Avatar
            height={"3.4rem"}
            imgURL={myProfile.profilePictureURL}
            // type="user"
            objectName={myProfile.ContactName}
          ></Avatar>
        </span>
        <h2>{myProfile.contactName}</h2>
        {myProfile.isLockstepVerified ? (
          <div className="lockstep-verified-checkmark-container">
            <Dropdown buttonContent={<IconCheckCircle height={14} className="lockstep-verified-checkmark-icon" />}>
              <div className="lockstep-verified-dropdown-content">{Resources.LockstepVerifiedInformation}</div>
            </Dropdown>
          </div>
        ) : null}
      </div>
    );
  }

  renderSearchResultsContent() {
    let {
      numberSearchResults,
      searchResultsType,
      numberSearchResultsContacts,
      numberSearchResultsAccounts,
      gettingSearchResults
    } = this.props;

    let headerText;

    switch (searchResultsType) {
      case "accounts":
        headerText = Resources.AccountProfilesNum(numberSearchResultsAccounts);
        break;
      case "contacts":
        headerText = Resources.ContactProfilesNum(numberSearchResultsContacts);
        break;
      default:
        headerText = Resources.SearchResultsNum(numberSearchResults);
        break;
    }

    if (gettingSearchResults) {
      headerText = Resources.Searching;
    }

    return (
      <div className="view-content-header-search">
        <button className="button-action-icon" onClick={() => this.backButtonClick()}>
          <IconLeftArrow />
        </button>
        <h2>{headerText}</h2>
      </div>
    );
  }

  renderConversationViewingContent() {
    const { conversation } = this.props;

    if (isEmpty(conversation)) {
      return null;
    }

    const assignedUserDisplayName = this.props.getUserDisplayName(
      conversation.assignedUserId,
      Resources.You.toLocaleLowerCase()
    );
    const assignedUserText = isEmpty(assignedUserDisplayName)
      ? Resources.AssignmentUnknown
      : Resources.AssignedToName(assignedUserDisplayName);

    return (
      <div className="view-content-header-conversation">
        <button className="button-action-icon" onClick={() => this.backButtonClick()}>
          <IconLeftArrow />
        </button>
        {/* <span>
          <Avatar
            height={"3.4rem"}
            imgURL={myProfile.profilePictureURL}
            // type="user"
            objectName={myProfile.ContactName}
          ></Avatar>
        </span> */}
        <div>
          <h2>{conversation.new ? Resources.NewActivity : conversation.subject}</h2>
          {!conversation.new && (
            <div className="view-content-header-conversation__assigned-to">
              {conversation.assignedUserId === null ? Resources.Unassigned : assignedUserText}
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const {
      links,
      match,
      accountViewing,
      contactViewing,
      sequenceViewing,
      numberOpenRequests,
      numberSearchResults,
      onSearchView,
      myProfile,
      viewParam = "subView",
      viewingConversation
    } = this.props;

    return (
      <div className="view-content-header">
        <div className="view-content-header-main">
          <div>
            <SearchBar hideDropdownMenu={onSearchView} disableDebouncedSearch={onSearchView} />
          </div>

          <div className="view-content-header-right">
            <FeatureGate gatedFeature="allowAppSwitchToATC">
              <a href={getATCEnvUrl()} rel="noopener noreferrer" target="_blank">
                <button className="button-action-icon">
                  <IconApps height={18}></IconApps>
                </button>
              </a>
            </FeatureGate>
            {/* <Dropdown
              buttonContent={<Avatar height="2.66rem" type="contact" isCurrentUser />}
              buttonClassName="dropdown-avatar"
              menuAlign="right"
            >
              <Dropdown.Item
                onClick={() =>
                  this.props.history.push(
                    generatePath("/v2/:companyId/:perspectiveId/management/my-profile", {
                      ...match.params
                    })
                  )
                }
              >
                {Resources.MyProfile}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  this.props.history.push(
                    generatePath("/v2/:companyId/:perspectiveId/management/company-profile/profile", {
                      ...match.params
                    })
                  )
                }
              >
                {Resources.CompanyProfile}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => this.props.history.push(generatePath("/company/:companyId/manage", { ...match.params }))}
              >
                {Resources.Settings}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => this.props.logout()}>{Resources.Logout}</Dropdown.Item>
            </Dropdown> */}
          </div>
        </div>

        {!isEmpty(accountViewing) && this.renderAccountViewingContent()}
        {!isEmpty(contactViewing) && this.renderContactViewingContent()}
        {!isEmpty(sequenceViewing) && this.renderSequenceViewingContent()}
        {!isEmpty(numberOpenRequests) && this.renderOpenRequestsContent()}
        {!isEmpty(myProfile) && this.renderMyProfileContent()}
        {(!isEmpty(numberSearchResults) || numberSearchResults === 0) && this.renderSearchResultsContent()}
        {viewingConversation && this.renderConversationViewingContent()}

        {links && (
          <div className="view-content-header-links">
            {links.map(link => (
              <NavLink
                className="content-header-link"
                to={generatePath(link.path || match.path, { ...match.params, [viewParam]: link.subView })}
                key={link.subView}
                replace
                data-test-id={"subview-link--" + link.subView}
              >
                {link.display}
              </NavLink>
            ))}
          </div>
        )}
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    conversation: store.conversations.conversation,
    perspectivesStore: store.perspectives
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(ViewContentHeader));
