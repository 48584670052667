import React, { useState } from "react";
import icons from "../../../../../lib/icons";
import { getPartnerType } from "../../../../../lib/perspectives";
import Resources from "../../../../../lib/resources";
import { find } from "../../../../../lib/utils";
import AutoCompleteDropdown from "../../../../library/autoCompleteDropdown";
import SPFDKIMEmailSettings from "../../../../SPFDKIMEmailSettings";
import SMTPEmailSettings from "../../../../SMTPEmailSettings";

export const EmailTypes = {
  LOCKSTEP: "Lockstep",
  GMAIL: "Gmail",
  DKIMSPF: "DKIM/SPF",
  SMTP: "SMTP"
};

export const getOutgoingEmailOptionDisplayName = type => {
  switch (type) {
    case EmailTypes.LOCKSTEP:
      return Resources.StandardLockstepDelivery;
    case EmailTypes.GMAIL:
      return Resources.ConnectedGmailAccount;
    case EmailTypes.DKIMSPF:
      return Resources.ImproveDeliveryRatesSPFDKIM;
    case EmailTypes.SMTP:
      return Resources.CustomSMTP;
    default:
      return null;
  }
};

const outgoingMailOptions = [
  { value: EmailTypes.LOCKSTEP, displayName: getOutgoingEmailOptionDisplayName(EmailTypes.LOCKSTEP) },
  { value: EmailTypes.DKIMSPF, displayName: getOutgoingEmailOptionDisplayName(EmailTypes.DKIMSPF) },
  { value: EmailTypes.SMTP, displayName: getOutgoingEmailOptionDisplayName(EmailTypes.SMTP) }
];

const OutgoingEmailSettings = ({
  perspectiveId,
  perspectiveName,
  companyId,
  companyName,
  isCurrentEmailConfigVerified,
  selectedConfig,
  configData,
  onCancel,
  setCompanyEmailConfig,
  allEmailConfigTypes
}) => {
  const workspaceName = getPartnerType(perspectiveName, true);
  const [selectedOption, setSelectedOption] = useState(
    find(outgoingMailOptions, ({ value }) => value === selectedConfig)
  );

  const onCompanyEmailConfigSelected = selectedType => {
    const selectedConfigTypeId =
      find(allEmailConfigTypes, ({ emailConfigurationTypeName }) => emailConfigurationTypeName === selectedType) || {};
    const selectedEmailConfigurationTypeId = selectedConfigTypeId.emailConfigurationTypeId;

    switch (selectedType) {
      case EmailTypes.LOCKSTEP:
        setCompanyEmailConfig(companyId, {
          emailConfigurationTypeId: selectedEmailConfigurationTypeId,
          perspectiveId: perspectiveId,
          configurationData: null
        });
        break;
      case EmailTypes.DKIMSPF:
      case EmailTypes.SMTP:
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <div className="card-attribute-editing border-none">
        <div className="card-attribute-column border-none pt-0">
          <div className="input-label">{Resources.DeliveryPreference}</div>
          <div className="card-attribute-content mb-4">
            <AutoCompleteDropdown
              width="350px"
              selected={selectedOption}
              options={outgoingMailOptions}
              displayKey="displayName"
              placeholder={Resources.LoadingOptions}
              handleSelectOption={option => {
                onCompanyEmailConfigSelected(option.value);
                setSelectedOption(outgoingMailOptions.find(({ value }) => value === option.value));
              }}
              icon={icons.angleDown + " solid"}
              readOnly
            />
            <button
              className="button-primary-square display-outgoing-email-test-modal"
              onClick={() =>
                this.props.displayModal("testEmailConfigModal", {
                  companyId: companyId,
                  accountingGroupId: perspectiveId,
                  accountingGroupName: workspaceName
                })
              }
            >
              {Resources.TestOutgoingEmail}
            </button>
          </div>
          <div className="nav-tabs mb-4 w-100"></div>
        </div>
      </div>

      <SPFDKIMEmailSettings
        isVerified={isCurrentEmailConfigVerified}
        isVisible={selectedOption.value === EmailTypes.DKIMSPF}
        configData={configData}
        companyId={companyId}
        perspectiveId={perspectiveId}
        companyName={companyName}
        onSave={() => {}}
        onCancel={onCancel}
      />

      <SMTPEmailSettings
        isVerified={isCurrentEmailConfigVerified}
        isVisible={selectedOption.value === EmailTypes.SMTP}
        perspectiveId={perspectiveId}
        configData={configData}
        companyId={companyId}
        onSave={() => {}}
        onCancel={onCancel}
      />
    </React.Fragment>
  );
};

export default OutgoingEmailSettings;
