import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { dispatchToProps as moDP } from "../../../store/modal-actions";
import { dispatchToProps as ledgDP } from "../../../store/ledger-actions";
import { dispatchToProps as statDP } from "../../../store/statements-actions";

import Resources from "../../../lib/resources";
import IconClose from "../../library/icons/iconClose";
import TableData from "../../library/tableData";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import HtmlEditor from "../../htmlEditor";

import Modal from "react-modal";
import { onBlurCheckFocusable, isEmpty, updateSelectedRows } from "../../../lib/utils";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...ledgDP(dispatch),
  ...statDP(dispatch)
});

class PromiseToPayFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedReason: Resources.IncorrectInformation,
      htmlBody: "",
      textBody: "",
      selectedRows: [],
      expectedPaymentDate: null,
      showFlyout: true
    };

    this.submitPromiseToPay = this.submitPromiseToPay.bind(this);
    this.hideFlyout = this.hideFlyout.bind(this);
  }

  componentDidMount() {
    let { selectedInvoices = [] } = this.props;

    if (selectedInvoices.length > 0) {
      this.setState({ selectedRows: selectedInvoices.map((invoice, i) => i) });
    }
  }

  hideFlyout() {
    this.setState({ showFlyout: false });
  }

  toggleSelectRow(i) {
    let newSelectedRows = updateSelectedRows(i, this.state.selectedRows, this.props.selectedInvoices.length);

    this.setState({ selectedRows: newSelectedRows });
  }

  submitPromiseToPay() {
    let resourceName = "DebitNote";
    let { companyId, selectedInvoices } = this.props;

    let finalSelectedIndexes = this.state.selectedRows.map(i => selectedInvoices[i]);

    let note = `<Note xmlns="urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2"><![CDATA[${this.state
      .htmlBody || this.state.textBody}]]></Note>`;

    let billingReferences = finalSelectedIndexes.map(invoice => {
      return `<BillingReference xmlns="urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2">
        <InvoiceDocumentReference>
          <ID xmlns="urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2">${invoice.ledgerHash}</ID>
        </InvoiceDocumentReference>
        </BillingReference>`;
    });

    billingReferences = billingReferences.join("");
    let paymentMeans = `<PaymentMeans xmlns="urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2">
                          <PaymentDueDate xmlns="urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2">${moment(
                            this.state.expectedPaymentDate
                          ).format("YYYY-MM-DD")}</PaymentDueDate>
                          <PaymentChannelCode xmlns="urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2">61</PaymentChannelCode>
                        </PaymentMeans>`;

    let totalInvoiceAmount = finalSelectedIndexes.reduce((total, invoice) => total + invoice.amount, 0);
    let requestedMonetaryTotal = `<RequestedMonetaryTotal xmlns="urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2">
                                    <PayableAmount currencyID="USD" xmlns="urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2">${totalInvoiceAmount}</PayableAmount>
                                  </RequestedMonetaryTotal>`;
    let finalLedgerContent = [
      `<DebitNote xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns="urn:oasis:names:specification:ubl:schema:xsd:DebitNote-2">`,
      note,
      `<IssueDate xmlns="urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2">${moment().format(
        "YYYY-MM-DD"
      )}</IssueDate>`,
      billingReferences,
      paymentMeans,
      requestedMonetaryTotal,
      `</DebitNote>`
    ].join("");
    this.props.displayNotification("creatingPromiseToPayNotification");
    this.props
      .createEntryToContextGroup(companyId, this.props.withContextGroupId, resourceName, finalLedgerContent, {
        "Content-Type": "application/xml"
      })
      .then(response => {
        this.props.clearSelectedRows();
        this.hideFlyout();
        this.props.refreshOpenInvoices();
      });
  }

  render() {
    const { selectedInvoices = [], columns = [] } = this.props;

    return (
      <Modal
        isOpen={this.state.showFlyout}
        onRequestClose={this.hideFlyout}
        onAfterClose={this.props.hideFlyout}
        contentLabel="Example Modal"
        className="flyout promise-to-pay-flyout"
        overlayClassName="flyout-overlay"
        closeTimeoutMS={400}
      >
        <div className="flyout-heading">
          <div>{Resources.PromiseToPay}</div>
          <div onClick={this.hideFlyout} className="flyout-heading-close">
            <IconClose />
          </div>
        </div>
        <div className="flyout-content">
          <span className={`portal-input-label ${this.state.focusedField === "expectedDate" ? " focused" : ""}`}>
            {Resources.ExpectedPaymentDate}
          </span>
          <DatePicker
            className="date-picker-input ml-2"
            calendarClassName="date-picker-calendar"
            dayClassName={d => "date-picker-day"}
            dateFormat="MMM d, yyyy"
            shouldCloseOnSelect
            minDate={new Date()}
            selected={this.state.expectedPaymentDate && new Date(this.state.expectedPaymentDate)}
            onChange={d =>
              this.setState({
                expectedPaymentDate: moment(d)
                  .startOf("day")
                  .format("YYYY-MM-DD HH:mm:ss")
              })
            }
            onFocus={() => this.setState({ focusedField: "expectedDate" })}
            onBlur={e => onBlurCheckFocusable(() => this.setState({ focusedField: null }))}
          />
          <div className={`portal-input-label mt-5 ${this.state.focusedField === "message" ? " focused" : ""}`}>
            {Resources.Message}
          </div>
          <HtmlEditor
            onFocus={() => this.setState({ focusedField: "message" })}
            onBlur={e => onBlurCheckFocusable(() => this.setState({ focusedField: null }))}
            hideToolbar={this.state.focusedField !== "message"}
            htmlContent={this.state.htmlBody}
            updateHtmlContent={htmlBody => this.setState({ htmlBody })}
            updateTextContent={textBody => this.setState({ textBody })}
          />
          <div className={`portal-input-label mt-4 ${this.state.focusedField === "invoices" ? " focused" : ""}`}>
            {Resources.InvoicesSelected}
          </div>
          <div className="flyout-table-container">
            <TableData
              noGrow
              pagination
              data={selectedInvoices}
              columns={columns}
              rowHeight="4rem"
              rowClassName="statements-view-row no-hover"
              selectedRows={this.state.selectedRows}
              onRowSelectToggle={i => this.toggleSelectRow(i)}
              noSort
            />
          </div>
          <button
            data-test-id="promise-pay-flyout__submit"
            disabled={
              this.state.selectedRows.length === 0 ||
              (isEmpty(this.state.textBody) && isEmpty(this.state.htmlBody)) ||
              isEmpty(this.state.expectedPaymentDate)
            }
            className="portal-button-green mt-5"
            onClick={this.submitPromiseToPay}
          >
            {Resources.Submit}
          </button>
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal,
    conversationsStore: store.conversations,
    ledgerStore: store.ledger,
    accountsStore: store.accounts
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(PromiseToPayFlyout));
