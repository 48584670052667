import { actions } from "./accounts-actions";
import { replaceAt, find, isEmpty, findIndex } from "../lib/utils";

export const defaultCompanyRolesState = {
  gettingCompanyRoles: false,
  fetchedCompanyRoles: false,
  companyRoles: [],
  settingCompanyUserRole: false,
  deletingCompanyUser: false
};

const defaultSubscriptionState = {
  subscription: {},
  fetchingSubscription: false,
  hasFetchedSubscription: false
};

export const defaultAccountsState = {
  ...{
    assumingAccount: false,
    hasAssumedAccount: false,
    fetchingAccounts: false,
    hasFetchedAccounts: false,
    creatingAccount: false,
    gettingAccount: false,
    updatingAccount: false,
    creatingCompany: false,
    gettingCompany: false,
    updatingCompany: false,
    creatingCompanyContact: false,
    updatingCompanyContact: false,
    deletingCompanyContact: false,
    loggingAccountActivity: false,
    loggedAccountActivity: false,
    failedLogAccountActivity: false,
    accounts: [],
    selectedAccount: {},

    selectedCompany: {},
    ...defaultSubscriptionState
  },
  ...defaultCompanyRolesState
};

function accountsReducer(state = defaultAccountsState, action) {
  if (window.logToConsole) console.log(action);
  switch (action.type) {
    case actions.FETCHING_ACCOUNTS:
      return { ...state, ...{ fetchingAccounts: true } };
    case actions.FETCH_ACCOUNTS_FAILED:
      return {
        ...state,
        ...{ fetchingAccounts: false, hasFetchedAccounts: true }
      };
    case actions.FETCHED_ACCOUNTS:
      if (state.fetchingAccounts === false) return state;
      let fetchedAccounts = action.accounts || [];
      let fetchedState = { ...state, accounts: fetchedAccounts };
      updateSelected(fetchedState);
      return {
        ...fetchedState,
        ...{ fetchingAccounts: false, hasFetchedAccounts: true }
      };

    case actions.ASSUMING_ACCOUNT:
      return { ...state, ...{ assumingAccount: true } };
    case actions.ASSUME_ACCOUNT_FAILED:
      return {
        ...state,
        ...{ assumingAccount: false, hasAssumedAccount: true }
      };
    case actions.ASSUMED_ACCOUNT:
      if (state.assumingAccount === false) return state;
      return {
        ...state,
        ...{
          hasFetchedAccounts: false,
          assumingAccount: false,
          hasAssumedAccount: true
        }
      };

    case actions.GETTING_ACCOUNT:
      return { ...state, ...{ gettingAccount: true } };
    case actions.GET_ACCOUNT_FAILED:
      return { ...state, ...{ gettingAccount: false } };
    case actions.GOT_ACCOUNT:
      if (state.gettingAccount === false) return state;
      return {
        ...updateAccount(state, action.account),
        ...{ gettingAccount: false }
      };

    case actions.CREATING_ACCOUNT:
      return { ...state, ...{ creatingAccount: true } };
    case actions.CREATE_ACCOUNT_FAILED:
      return { ...state, ...{ creatingAccount: false } };
    case actions.CREATED_ACCOUNT:
      if (state.creatingAccount === false) return state;
      let newAccounts = [...state.accounts];
      newAccounts.push(action.account);
      let newAccountState = { ...state, ...{ accounts: newAccounts } };
      return { ...newAccountState, ...{ creatingAccount: false } };

    case actions.UPDATING_ACCOUNT:
      return { ...state, ...{ updatingAccount: true } };
    case actions.UPDATE_ACCOUNT_FAILED:
      return { ...state, ...{ updatingAccount: false } };
    case actions.UPDATED_ACCOUNT:
      if (state.updatingAccount === false) return state;
      return {
        ...updateAccount(state, action.account),
        ...{ updatingAccount: false }
      };

    case actions.SELECT_ACCOUNT:
      if (action.accountId === state.selectedAccountId) return { ...state };
      let selectAccountState = {
        ...state,
        ...{ selectedAccountId: action.accountId }
      };
      updateSelected(selectAccountState);
      return selectAccountState;

    case actions.FETCHING_SUBSCRIPTION:
      return { ...state, fetchingSubscription: true };
    case actions.FETCH_SUBSCRIPTION_FAILED:
      return {
        ...state,
        fetchingSubscription: false,
        hasFetchedSubscription: true
      };
    case actions.FETCHED_SUBSCRIPTION:
      return {
        ...state,
        fetchingSubscription: false,
        hasFetchedSubscription: true,
        subscription: action.subscription
      };

    case actions.GETTING_COMPANY:
      return { ...state, ...{ gettingCompany: true } };
    case actions.GET_COMPANY_FAILED:
      return { ...state, ...{ gettingCompany: false } };
    case actions.GOT_COMPANY:
      if (state.gettingCompany === false) return state;
      return {
        ...updateCompany(state, action.company),
        ...{ gettingCompany: false }
      };

    case actions.CREATING_COMPANY:
      return { ...state, ...{ creatingCompany: true } };
    case actions.CREATE_COMPANY_FAILED:
      return { ...state, ...{ creatingCompany: false } };
    case actions.CREATED_COMPANY:
      if (state.creatingCompany === false) return state;
      let newCompaniesState = {
        ...state,
        ...{
          creatingCompany: false,
          selectedCompanyId: action.company.companyId
        }
      };
      let newCompanyAccount = find(newCompaniesState.accounts || [], a => {
        return a.accountId === action.accountId;
      });
      let newCompanies = newCompanyAccount.companies || [];
      newCompanies.push(action.company);
      newCompanyAccount = {
        ...newCompanyAccount,
        ...{ companies: newCompanies }
      };
      return updateAccount(newCompaniesState, newCompanyAccount);

    case actions.UPDATING_COMPANY:
      return { ...state, ...{ updatingCompany: true } };
    case actions.UPDATE_COMPANY_FAILED:
      return { ...state, ...{ updatingCompany: false } };
    case actions.UPDATED_COMPANY:
      if (state.updatingCompany === false) return state;
      return {
        ...updateCompany(state, action.company),
        ...{ updatingCompany: false }
      };

    case actions.GETTING_COMPANY_ROLES:
      return {
        ...state,
        ...{ gettingCompanyRoles: true, fetchedCompanyRoles: false }
      };
    case actions.GET_COMPANY_ROLES_FAILED:
      return {
        ...state,
        ...{ gettingCompanyRoles: false, fetchedCompanyRoles: true }
      };
    case actions.GOT_COMPANY_ROLES:
      if (state.gettingCompanyRoles === false || action.companyId !== state.selectedCompanyId) return state;
      return {
        ...state,
        ...{
          gettingCompanyRoles: false,
          fetchedCompanyRoles: true,
          companyRoles: action.companyRoles
        }
      };

    case actions.SETTING_COMPANY_USER_ROLE:
      return { ...state, settingCompanyUserRole: true };
    case actions.SET_COMPANY_USER_ROLE_FAILED:
      return { ...state, settingCompanyUserRole: false };
    case actions.SET_COMPANY_USER_ROLE:
      if (action.companyId !== state.selectedCompanyId) return state;
      return { ...state, settingCompanyUserRole: false };

    case actions.DELETING_COMPANY_USER:
      return { ...state, deletingCompanyUser: true };
    case actions.DELETE_COMPANY_USER_FAILED:
      return { ...state, deletingCompanyUser: false };
    case actions.DELETED_COMPANY_USER:
      if (action.companyId !== state.selectedCompanyId) return state;
      return { ...state, deletingCompanyUser: false };

    case actions.SELECT_COMPANY:
      if (action.companyId === state.selectedCompanyId && isEmpty(state.selectedCompany) === false) return { ...state };
      let selectCompanyState = {
        ...state,
        selectedCompanyId: action.companyId
      };

      const selectedAccount = find(state.accounts, a => {
        return !isEmpty(
          find(a.companies, c => {
            return c.companyId === action.companyId;
          })
        );
      });

      updateSelected(selectCompanyState);
      return {
        ...selectCompanyState,
        ...defaultCompanyRolesState,
        ...defaultSubscriptionState,
        selectedAccount
      };

    case actions.CREATING_COMPANY_CONTACT:
      return { ...state, creatingCompanyContact: true };
    case actions.CREATED_COMPANY_CONTACT:
      return { ...state, creatingCompanyContact: false };
    case actions.CREATE_COMPANY_CONTACT_FAILED:
      return { ...state, creatingCompanyContact: false };

    case actions.UPDATING_COMPANY_CONTACT:
      return { ...state, updatingCompanyContact: true };
    case actions.UPDATED_COMPANY_CONTACT:
      return { ...state, updatingCompanyContact: false };
    case actions.UPDATE_COMPANY_CONTACT_FAILED:
      return { ...state, updatingCompanyContact: false };

    case actions.DELETING_COMPANY_CONTACT:
      return { ...state, deletingCompanyContact: true };
    case actions.DELETED_COMPANY_CONTACT:
      return { ...state, deletingCompanyContact: false };
    case actions.DELETE_COMPANY_CONTACT_FAILED:
      return { ...state, deletingCompanyContact: false };

    case actions.CLEAR_DATA:
      return defaultAccountsState;
    default:
      return state;
  }
}

function updateAccount(state, account) {
  let updatedAccounts = state.accounts || [];
  updatedAccounts = replaceAt(
    updatedAccounts,
    findIndex(updatedAccounts, u => {
      return u.accountId === account.accountId;
    }),
    account
  );
  let updatedState = { ...state, accounts: updatedAccounts };
  updateSelected(updatedState);
  return updatedState;
}

function updateCompany(state, company) {
  let foundAccount = find(state.accounts || [], a => {
    return (
      findIndex(a.companies || [], c => {
        return c.companyId === company.companyId;
      }) >= 0
    );
  });
  if (!foundAccount) return state;
  let updatedCompanies = foundAccount.companies || [];
  updatedCompanies = replaceAt(
    updatedCompanies,
    findIndex(updatedCompanies, u => {
      return u.companyId === company.companyId;
    }),
    company
  );
  return updateAccount(state, {
    ...foundAccount,
    ...{ companies: updatedCompanies }
  });
}

function updateSelected(state) {
  let accounts = state.accounts || [];
  const selectedAccountId = state.selectedAccountId || (accounts[0] || {}).accountId;

  let foundAccount = find(accounts, a => {
    return selectedAccountId === a.accountId;
  });
  state.selectedAccountId = (foundAccount || {}).accountId;
  state.selectedAccount = foundAccount || {};

  let companies = [];
  accounts.forEach(account => {
    companies = [...companies, ...account.companies];
  });
  let foundCompany = find(companies, c => {
    return state.selectedCompanyId && state.selectedCompanyId === c.companyId;
  });
  //state.selectedCompanyId = (foundCompany || {}).companyId
  state.selectedCompany = foundCompany || {};
}

export default accountsReducer;
